<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex flex-column flex-sm-row justify-space-around ma-4">
        <v-btn
          @click="dialog = true"
          color="success"
          v-bind="attrs"
          v-on="on"
          class="ma-2"
          disabled
        >
          <span v-if="!getUser.linkedin_profile">Hämta </span>
          <span v-if="getUser.linkedin_profile">Synca </span
          ><v-icon>mdi-linkedin</v-icon> (kommer snart)
        </v-btn>
        <v-btn
          class="ma-2"
          @click="unlinkLinkedIn"
          color=""
          v-if="getUser.linkedin_profile"
          text
          outlined
        >
          Ta bort <v-icon>mdi-linkedin</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card class="pa-4">
      <v-card-title class="text-h5">
        Koppla min LinkedIn till Zwop
      </v-card-title>
      <v-card-text>
        Här anger du dina inloggningsuppgifter till LinkedIn
        <v-col cols="12 ">
          <v-text-field
            v-model="username"
            :label="'Email eller telefon'"
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="password"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_password ? 'text' : 'password'"
            name="input-10-1"
            :label="$t('password')"
            outlined
            @click:append="show_password = !show_password"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          Namn, rubrik, bransch ,sammanfattning och anställningar kommer
          hämtas.</v-col
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="dialog = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="success" :loading="loading" @click="getLinkedinData">
          Hämta min profil
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "LinkedinDialog",
  props: [],
  computed: {
    ...mapGetters(["getUser"]),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },

  methods: {
    ...mapActions(["responseStatus"]),
    getLinkedinData() {
      if (this.username === "" || this.password === "") return;
      const payload = {
        username: this.username,
        password: this.password,
      };
      this.loading = true;
      axiosInstance
        .post(`${API_URL}api/users/sync_linkedin_profile/`, payload)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            message: response.data?.message,
          });
          this.dialog = false;
          this.password = "";
          this.loading = false;
          return response;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },

    unlinkLinkedIn() {
      this.loading = true;
      axiosInstance
        .post(`${API_URL}api/users/detach_linkedin_profile/`)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            message: response.data?.message,
          });

          this.loading = false;
          return response;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
  },

  data() {
    return {
      dialog: false,
      username: "",
      password: "",
      loading: false,
      show_password: false,
    };
  },
};
</script>

<style></style>
