<template>
  <v-card class="mb-2 align-center justify-space-between">
    <v-card-title>
      <h3>{{ $t("account.transactions") }}</h3>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="getTransactions"
      :items-per-page="10"
      :search="search"
      :sort-desc="[false, true]"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="reference_no"
      show-expand
      multi-sort
    >
      <template v-slot:[`item.amount`]="{ item }">
        {{ amountInOrOut(item) }} kr
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ displayValue(item) }}
      </template>
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-1">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template> -->
      <template v-slot:[`item.timestamp`]="{ item }">
        <span> {{ new Date(item.timestamp) | date("yyyy-MM-dd HH:mm") }}</span>
      </template>
      <template v-slot:no-data>
        {{ $t("account.noTransactions") }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-2" :colspan="headers.length">
          <Receipt :item="item" />
        </td>
      </template>
    </v-data-table>

    <div class="pa-8"><TaxDeclarationReport /></div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateFilter, format } from "vue-date-fns";
import { jsPDF } from "jspdf";
import TaxDeclarationReport from "./TaxDeclarationReport.vue";
import Receipt from "./Receipt.vue";
export default {
  name: "Transactions",
  components: { Receipt, TaxDeclarationReport },
  filters: {
    date: dateFilter,
  },
  methods: {
    ...mapActions([]),
    amountInOrOut(transaction) {
      let amount = 0.0;
      if (transaction.account_sender === this.getUserAccount.id) {
        const fee = transaction.fee_sender
          ? parseFloat(transaction.fee_sender.amount)
          : 0.0;
        amount = (parseFloat(transaction.amount) + fee) * -1.0;
      } else {
        const fee = transaction.fee_receiver
          ? parseFloat(transaction.fee_receiver.amount)
          : 0.0;

        amount = (parseFloat(transaction.amount) - fee) * +1.0;
      }

      return parseFloat(amount).toFixed(2);
    },

    displayValue(transaction) {
      if (transaction.transaction_type === "withdrawal") {
        return transaction.handled ? "Uttag" : "Uttag hanteras";
      } else if (transaction.transaction_type === "deposit") {
        return "Insättning";
      } else if (transaction.transaction_type === "reward") {
        return "Reward";
      } else if (transaction.transaction_type === "transfer") {
        if (this.amountInOrOut(transaction) > 0) {
          return "Försäljning";
        } else if (this.amountInOrOut(transaction) < 0) {
          return "Köp";
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getTransactions", "getUserAccount", "getUser"]),
  },

  created() {},
  mounted() {},

  data: () => ({
    search: "",
    expanded: [],
    singleExpand: true,
    headers: [
      {
        text: "Datum",
        align: "start",
        sortable: true,
        value: "timestamp",
      },
      {
        text: "Referens",
        align: "start",
        sortable: true,
        value: "reference_no",
      },
      {
        text: "Belopp",
        align: "start",
        sortable: false,
        value: "amount",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "status",
      },
    ],
  }),
};
</script>
