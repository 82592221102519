<template>
  <div class="export-options mb-4">
    <v-btn class="mr-2 mb-2" @click="exportCsv" :disabled="!getUser.is_premium">
      <v-icon>mdi-export</v-icon>
      <span>CSV-export</span>
      <v-icon color="warning"> mdi-license </v-icon>
    </v-btn>
    <!-- <v-btn class="mr-2 mb-2" disabled>
      <v-icon>mdi-sync</v-icon>
      <span>HubSpot Sync</span>
    </v-btn>
    <v-btn class="mr-2 mb-2" disabled>
      <v-icon>mdi-sync</v-icon>
      <span>Microsoft Dynamics 365 Sync</span>
    </v-btn> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosInstance from "@/services/axiosAPI";
import { format } from "vue-date-fns";
export default {
  name: "ExportOptions",
  props: [],
  methods: {
    download(filename, text) {
      var element = document.createElement("a");
      var universalBOM = "\uFEFF";
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(universalBOM + text)
      );

      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    exportCsv() {
      axiosInstance
        .get(`api/leads/export_csv/`)
        .then((response) => {
          const data = response.data;
          this.download(`zwop_export_${new Date().toString()}.csv`, data);
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapGetters(["isLoading", "getUser"]),
    userHasSubscription() {
      if (this.getUser.subscription === null) return false;
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },
  },
};
</script>

<style>
.menubar {
  margin-bottom: 16px;
  /* margin-top: 16px; */
  /* position: sticky;
  top: 76px;
  z-index: 1; */
}
</style>
