<template></template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logout"]),
  },
  created: function () {
    this.logout();
 
  },
};
</script>