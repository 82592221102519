<template>
  <div class="d-flex justify-center">
    <v-tooltip
      bottom
      open-delay="500"
      :disabled="mobile"
      v-if="!insufficientBalance"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          color="success"
          @click="handleInstantBuy"
          :disabled="insufficientBalance"
          v-show="purchasePossible"
        >
          {{ $t("buy") }}
        </v-btn>
      </template>
      <span> {{ $t("tooltip.instantBuy") }}</span>
    </v-tooltip>
    <div v-if="insufficientBalance" class="d-flex pt-4">
      <div v-if="!item.owner && item.created_by.id != getUser.id">
        <v-alert dense text color="error">
          <span class="text-caption">
            {{ $t("lowBalance") }}
            <DepositDialog xSmall />
          </span>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DepositDialog from "@/components/account/DepositDialog";
export default {
  name: "BuyLead",
  components: { DepositDialog },
  props: ["item"],

  methods: {
    ...mapActions([
      "responseStatus",
      "fetchUserAccount",
      "leadInstantBuy",
      "responseInfo",
    ]),
    handleInstantBuy: function () {
      var payload = {
        id: this.item.id,
      };
      this.leadInstantBuy(payload).then(() => {
        this.fetchUserAccount();
        this.$emit("completedPurchase");
      });
    },
  },
  computed: {
    ...mapGetters(["getUser", "getUserAccount"]),
    purchasePossible() {
      if (this.item.owner === null) {
        return true;
      } else {
        return false;
      }
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    totalPrice() {
      return Math.ceil(this.item.price * (1.0 + this.getUser.courtage));
    },
    insufficientBalance() {
      this.$redrawVueMasonry();
      return (
        this.getUserAccount.balance <
        this.item.price * (1.0 + this.getUser.courtage)
      );
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
