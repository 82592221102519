<template>
  <div class="text-center">
    <!-- <v-card>
      <v-skeleton-loader
        class="mx-auto pa-4 mt-4"
        type="article, list-item-avatar, actions"
      ></v-skeleton-loader
    ></v-card> -->

    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>
<script>
export default {
  components: {},
  name: "InfinityLoader",
  props: [],
  methods: {},
  computed: {},
  created() {},

  data() {
    return {};
  },
};
</script>
