<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text small color="" v-bind="attrs" v-on="on" v-if="!campain">
        <v-icon>mdi-account-multiple-plus-outline</v-icon>
        <span>{{ $t("invite") }}</span>
      </v-btn>
      <v-btn text small color="" v-bind="attrs" v-on="on" v-if="campain">
        <v-icon>mdi-account-multiple-plus-outline</v-icon>
        <span>{{ $t("inviteAndGetRewarded") }}</span>
      </v-btn>
    </template>

    <v-card class="">
      <v-card-title> Bjud in </v-card-title><v-divider></v-divider>
      <v-card-text class="d-flex flex-md-row flex-column justify-center">
        <div class="mb-12">
          <v-card-title> {{ $t("inviteWithLink") }} </v-card-title>
          <p>{{ $t("inviteWithLinkText") }}</p>
          <v-btn
            :color="inviteCopyColor"
            v-clipboard:copy="urlBase + 'signup/' + user.profile_uuid"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="mb-4"
          >
            <v-icon left> mdi-content-copy </v-icon>{{ inviteCopyLabel }}</v-btn
          >
          <div style="">
            <vue-qrcode
              :color="QRColor"
              :errorCorrectionLevel="QRLevel"
              :value="urlBase + 'signup/' + user.profile_uuid"
            />
          </div>
        </div>
        <div>
          <v-card-title>{{ $t("inviteWithMail") }} </v-card-title>
          <p>{{ $t("inviteWithMailText") }}</p>
          <v-combobox
            class="mb-1"
            v-model="emails"
            deletable-chips
            outlined
            multiple
            chips
            @keyup="extractEmails"
            :hint="'Fyll i de mailadresser du vill bjuda in'"
            :delimiters="[',', ' ', ';', '\n', '\r\n']"
          ></v-combobox>
          <v-btn @click="sendInvites" color="success">
            <v-icon left> mdi-email-fast-outline </v-icon
            >{{ $t("sendInvites") }}</v-btn
          >
          <InvitedMembers />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text color="primary">{{
          $t("close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
import { mapActions } from "vuex";
import VueQrcode from "vue-qrcode";
import InvitedMembers from "./InvitedMembers";
export default {
  name: "QrCodeDialog",
  components: { VueQrcode, InvitedMembers },
  props: ["user", "campain"],
  data: () => ({
    dialog: false,
    urlBase: process.env.VUE_APP_BASE_URL,
    QRColor: { dark: "#000000ff", light: "#ffffffff" },
    QRsize: 300,
    QRLevel: "H",
    inviteCopyLabel: "Kopiera inbjudningslänk",
    inviteCopyColor: "primary",
    emails: [],
  }),

  methods: {
    ...mapActions(["responseStatus"]),
    onCopy: function (e) {
      this.inviteCopyLabel = "Länk kopierad";
      this.inviteCopyColor = "success";
      setTimeout(() => {
        this.inviteCopyColor = "primary";
        this.inviteCopyLabel = "Kopiera inbjudningslänk";
      }, 500);
    },
    onError: function (e) {
      console.log("Failed to copy texts");
    },
    extractEmails() {
      let cleanedArray = [];
      this.emails.map((item) => {
        let emailsInString = item.match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );
        if (emailsInString) {
          emailsInString.forEach((element) => {
            cleanedArray.push(element);
          });
        }
      });

      // this.emails = cleanedArray;
      this.emails = cleanedArray.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
    },

    sendInvites() {
      if (this.emails.length > 0) {
        const payload = {
          send_to: this.emails,
        };
        axiosInstance
          .post(`api/users/invite_members/`, payload)
          .then((response) => {
            this.responseStatus({
              code: response.status,
              message: response.data?.message,
            });

            return response;
          })
          .catch((error) => {
            console.error(error);
          });
        this.dialog = false;
      } else {
        console.log("Enter some emails");
      }
    },
  },
  computed: {},
  created() {},
};
</script>
