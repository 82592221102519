<template>
  <div class="text-center ">
    <!-- <v-skeleton-loader
      class="mx-auto mb-4"
      type="article, list-item-avatar, actions"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto mb-4"
      type="article, list-item-avatar, actions"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      type="article, list-item-avatar, actions"
    ></v-skeleton-loader> -->
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  components: {},
  name: "LargeLoader",
  props: [],
  methods: {},
  computed: {},
  created() {},

  data() {
    return {};
  },
};
</script>
