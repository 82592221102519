import { VListGroup } from "vuetify/lib";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;

const state = {
  myLeads: [],
  myOwnedLeads: [],
  myDrafts: [],
  myLikes: [],
  followerRequests: [],
  marketLeads: [],
  // lead: {},
  profile: {},
  filter: {
    filterTags: [],
    filterSnis: [],
    searchOnText: "",
    priceMin: "",
    priceMax: "",
    ratingMin: "",
    ratingMax: "",
    dateRange: [],
  },
  inviteTree: {},
  tags: [],
  snis: [],
  search: null,
};

const getters = {
  getSearch: (state) => state.search,
  myLeads: (state) => state.myLeads,
  myOwnedLeads: (state) => state.myOwnedLeads,
  myLikes: (state) => state.myLikes,
  getLead: (state) => state.lead,

  allTags: (state) => state.tags,
  allSnis: (state) => state.snis,
  getFollowerRequests: (state) => state.followerRequests,
  getProfile: (state) => state.profile,
  getFilter: (state) => state.filter,
  getUserInviteTree: (state) => state.inviteTree,
};

const actions = {
  fetchOwnedLeads({ commit }) {
    commit("setLoading");
    axiosInstance
      .get(API_URL + "api/leads/owned/")
      .then((response) => {
        commit("setOwnedLeads", response.data);
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när dina köpta leadkort skulle hämtas",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  fetchMyLeads({ commit }) {
    commit("setLoading");
    axiosInstance
      .get(API_URL + "api/leads/my-leads/")
      .then((response) => {
        commit("setMyLeads", response.data.data);
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när dina leadkort skulle hämtas",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  addLead({ commit }, payload) {
    commit("setLoading");
    axiosInstance
      .post(API_URL + "api/leads/", payload)
      .then((response) => {
        commit("newLead", response.data.data);
        commit("unsetLoading");
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
          link: "/uploaded",
          linkText: "Gå till mina skapade leads",
        });
      })
      .catch((error) => {
        // commit("setError", error);

        const errorHuman = {
          message: "Något gick fel när ditt leadkort skulle skapas",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  fetchTags({ commit }) {
    axiosInstance
      .get(API_URL + "api/leads/tags/")
      .then((response) => {
        commit("setTags", response.data);
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när taggarna skulle hämtas",
        };
        commit("setError", errorHuman);
      });
  },
  fetchSnis({ commit }) {
    axiosInstance
      .get(API_URL + "api/leads/snis/")
      .then((response) => {
        commit("setSnis", response.data);
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när SNI-koderna skulle hämtas",
        };
        commit("setError", errorHuman);
      });
  },
  fetchMyLikes({ commit }) {
    axiosInstance
      .get(API_URL + "api/leads/my-likes/")
      .then((response) => {
        commit("setMyLikes", response.data);
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när dina sparade leadkort skulle hämtas",
        };
        commit("setError", errorHuman);
      });
  },
  toggleLike({ commit }, payload) {
    commit("toggleLike", payload.lead);
    axiosInstance
      .post(API_URL + "api/leads/like/", payload)
      .then((response) => {
        if (response.data.like_active) {
          commit("setStatus", {
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
            link: "/saved",
            linkText: "Gå till alla sparade kort",
          });
        }
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när du skulle spara leadkortet",
        };
        commit("setError", errorHuman);
      });
  },
  fetchUserInviteTree({ commit }, id) {
    axiosInstance
      .get(`${API_URL}api/users/user_invite_tree/${id}`)
      .then((response) => {
        commit("setUserInviteTree", response.data);
      })
      .catch((error) => {
        // commit("setError", error);
      });
  },
  leadInstantBuy({ commit }, payload) {
    commit("setLoading");
    axiosInstance
      .post(API_URL + "api/leads/buy/", payload)
      .then((response) => {
        console.log(response.data);
        commit("makeInstantBuy", response.data);
        commit("unsetLoading");
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
          link: "/wallet",
          linkText: "Gå till mina köpta leads",
        });
      })
      .catch((error) => {
        console.log(error);
        // commit("setError", error);
        commit("setInfo", {
          text: "Lågt saldo",
          message: "Du behöver sätta in pengar för att kunna genomföra köpet",
          link: "/user/" + this.getUser.id,
          linkText: "Gå vidare till insättning",
        });
        commit("unsetLoading");
      });
  },
  leadRate({ commit }, payload) {
    axiosInstance
      .post(API_URL + "api/leads/rate/", payload)
      .then((response) => {
        commit("makeLeadRate", response.data.rating);
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
        });
      })
      .catch((error) => {
        // commit("setError", error);

        const errorHuman = {
          message: "Något gick fel när du skulle betygsätta leadkortet",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  leadUpdate({ commit }, payload) {
    commit("setLoading");
    axiosInstance
      .put(API_URL + "api/leads/update/", payload)
      .then((response) => {
        commit("makeLeadUpdate", response.data.data);
        commit("unsetLoading");
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
        });
      })
      .catch((error) => {
        // commit("setError", error);

        const errorHuman = {
          message: "Något gick fel när du skulle spara din uppdatering",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  leadInActivate({ commit }, payload) {
    commit("setLoading");
    axiosInstance
      .put(API_URL + "api/leads/inactivate/", payload)
      .then((response) => {
        commit("makeLeadInactive", response.data.data.id);
        commit("unsetLoading");
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
        });
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när du radera ditt leadkort",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  leadArchive({ commit }, payload) {
    commit("setLoading");
    axiosInstance
      .put(API_URL + "api/leads/archive/", payload)
      .then((response) => {
        commit("makeLeadArchive", response.data.data.id);
        commit("unsetLoading");
        if (response.data.message === "Lead archived") {
          commit("setStatus", {
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
            link: "/archived",
            linkText: "Gå till alla arkiverade leads",
          });
        } else if (response.data.message === "Lead retrieved") {
          commit("setStatus", {
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
            link: "/wallet",
            linkText: "Gå till alla köpta leads",
          });
        }
      })
      .catch((error) => {
        // commit("setError", error);

        const errorHuman = {
          message:
            "Något gick fel när du hantera arkiveringen av ditt leadkort",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  // fetchFollowerRequests({ commit }) {
  //   commit("setLoading");
  //   axiosInstance
  //     .get(API_URL + "api/users/get_requests/")
  //     .then((response) => {
  //       commit("setRequests", response.data);
  //       commit("unsetLoading");
  //     })
  //     .catch((error) => {
  //       // commit("setError", error);

  //       commit("unsetLoading");
  //     });
  // },

  followProfileRequest({ commit }, id) {
    axiosInstance
      .post(API_URL + "api/users/send_follow_request/" + id + "/")
      .then((response) => {
        commit("followProfileRequest");
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
        });
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  handleFollowingRequest({ commit }, payload) {
    axiosInstance
      .post(`${API_URL}api/users/handle_follow_request/`, payload)
      .then((response) => commit("handleFollowingRequest", response))
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  globalSearch({ commit }, query) {
    commit("setLoading");
    axiosInstance
      .get(`${API_URL}api/leads/search/${query}/`)
      .then((response) => {
        commit("setSearch", response);

        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },

  getFilterSetting({ commit }) {
    console.log("getFilterSetting, runned");
    axiosInstance
      .get(API_URL + "api/users/get_filter_setting/")
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data !== null || response.data.data !== undefined) {
          commit("setFilter", JSON.parse(response.data.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },

  globalFilter({ commit }, filter) {
    // localStorage.setItem("filterSettings", JSON.stringify(filter));
    const payload = {
      filter_setting: JSON.stringify(filter),
    };
    commit("setFilter", filter);
    axiosInstance
      .post(API_URL + "api/users/save_filter/", payload)
      .then((response) => {
        // console.log(response.data.filter_setting);
        // commit("setFilter", filter);
      })
      .catch((error) => {
        console.error(error);
      });

    // commit("setFilter", filter);
  },
  clearFilter({ commit }) {
    // localStorage.removeItem("filterSettings");
    commit("unsetFilter");
    const payload = {
      filter_setting: "",
    };
    axiosInstance
      .post(API_URL + "api/users/save_filter/", payload)
      .then((response) => {
        // commit("unsetFilter");
      })
      .catch((error) => {
        console.error(error);
      });
    // commit("unsetFilter");
  },
  removeTag({ commit }, tag) {
    commit("filterRemoveTag", tag);
  },
  removeSni({ commit }, sni) {
    commit("filterRemoveSni", sni);
  },
};

const mutations = {
  setMyLeads: (state, leads) => (state.myLeads = leads),
  setOwnedLeads: (state, leads) => (state.myOwnedLeads = leads),

  setMyLikes: (state, likes) => (state.myLikes = likes),
  toggleLike: (state, like) => {
    if (state.myLikes.some((item) => item.id == like.id)) {
      state.myLikes = state.myLikes.filter((item) => item.id != like.id);
    } else {
      state.myLikes.push(like);
    }
  },
  setTags: (state, tags) => (state.tags = tags),
  setSnis: (state, snis) => (state.snis = snis),
  setSearch: (state, payload) => (state.search = payload),
  setFilter: (state, payload) => (state.filter = payload),
  unsetFilter: (state) => {
    state.filter = {
      filterTags: [],
      filterSni: [],
      searchOnText: "",
      priceMin: "",
      priceMax: "",
      ratingMin: "",
      ratingMax: "",
      dateRange: [],
    };
  },
  filterRemoveTag: (state, tag) => {
    state.filter.filterTags.splice(state.filter.filterTags.indexOf(tag), 1);
    return (state.filter.filterTags = [...state.filter.filterTags]);
  },
  filterRemoveSni: (state, sni) => {
    state.filter.filterSnis.splice(state.filter.filterSnis.indexOf(sni), 1);
    return (state.filter.filterSnis = [...state.filter.filterSnis]);
  },
  makeLeadUpdate: (state, lead) => (state.leads = leads),
  makeLeadInactive: (state, id) => {
    let i = state.myLeads.map((item) => item.id).indexOf(id); // find index of your object
    return state.myLeads.splice(i, 1); // remove it from array
  },
  makeLeadArchive: (state, id) => {
    let i = state.myOwnedLeads.map((item) => item.id).indexOf(id); // find index of your object
    return state.myOwnedLeads.splice(i, 1); // remove it from array
  },

  setRequests: (state, requests) => (state.followerRequests = requests),
  followProfileRequest: (state) => state,
  handleFollowingRequest: (state, payload) => {
    // TODO Logic not working
    let i = state.requests.map((item) => item.id).indexOf(payload.data.id); // find index of your object
    return state.requests.splice(i, 1); // remove it from array
  },
  makeLeadLike: (state, like) => {
    if (state.lead.likes.includes(like.data.user)) {
      const index = state.lead.likes.indexOf(like.data.user);
      if (index > -1) {
        state.lead.likes.splice(index, 1);
      }
    } else {
      state.lead.likes.push(like.data.user);
    }
  },
  makeLeadRate: (state, rating) => state,
  newLead: (state, lead) => state.myLeads.unshift(lead),
  setUserInviteTree: (state, payload) => (state.inviteTree = payload),
  setLoadingProgress: (state, precent) => (state.loadingProgress = precent),
  makeInstantBuy: (state) => state,
  unsetLoading: (state) => (state.isLoading = false),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
