<template>
  <v-card class="mb-2 align-center justify-space-between">
    <v-card-title>
      <h3>{{ $t("zwops") }}</h3>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="getExchanges"
      :items-per-page="10"
      :search="search"
      :sort-desc="[false, true]"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      multi-sort
    >
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-1">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template> -->
      <template v-slot:[`item.timestamp`]="{ item }">
        <span>{{ new Date(item.timestamp).toLocaleString() }}</span>
      </template>
      <template v-slot:no-data>
        {{ $t("noZwops") }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-2" :colspan="headers.length">
          <!-- TODO Fix the details of a zwop. How should it be presented to the user? -->
          <v-layout :id="item.reference_no" d-flex flex-column>
            <v-row class="ma-2">
              <v-col md="6" sm="12">
                <LeadCard :lead="item.offered_lead" />
              </v-col>

              <v-col md="6" sm="12">
                <LeadCard :lead="item.wanted_lead" />
              </v-col>
            </v-row>
          </v-layout>
          <v-layout d-flex justify-end>
            <v-btn class="ma-1" @click="print(item.reference_no)"> Print</v-btn>
          </v-layout>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LeadCard from "@/components/leadcard/LeadCard";
export default {
  name: "Transactions",
  components: { LeadCard },
  methods: {
    ...mapActions(["fetchCompletedExchanges"]),

    // TODO Implementera för utskrift av kvitton
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(id);
    },
  },
  computed: {
    ...mapGetters(["getExchanges"]),
  },

  created() {},
  mounted() {
    this.fetchCompletedExchanges();
  },

  data: () => ({
    search: "",
    expanded: [],
    singleExpand: false,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "timestamp",
      },
      {
        text: "Reference",
        align: "start",
        sortable: true,
        value: "id",
      },

      {
        text: "",
        align: "start",
        sortable: true,
        value: "offered_lead.title",
      },
      {
        text: "",
        align: "start",
        sortable: true,
        value: "wanted_lead.title",
      },
    ],
  }),
};
</script>
