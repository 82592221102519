import axios from "axios";
import router from "../router";

import jwt_decode from "jwt-decode";

const API_URL = process.env.VUE_APP_API_URL;

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("access_token");
  return accessToken;
}

function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("refresh_token");
  return refreshToken;
}

function refreshToken() {
  return axiosInstance.post(`${API_URL}api/users/token/refresh/`, {
    refresh: getLocalRefreshToken(),
  });
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
});

// ToDO Remove old logic?
// before a request is made start the nprogress
// axiosInstance.interceptors.request.use(
//   (config) => {
//     NProgress.start();
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

axiosInstance.interceptors.request.use(
  (config) => {
    NProgress.start();
    const token = getLocalAccessToken();
    if (token) {
      config.headers.Authorization = "JWT " + token;
    }
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  async (err) => {
    NProgress.done();
    const originalConfig = err.config;

    // TODO Behövs denna????? Hanteras nedan med logout commit
    // if (
    //   err.response.status === 401 &&
    //   originalConfig.url === `${API_URL}api/users/token/refresh/`
    // ) {
    //   // router.push("/login");

    //   return Promise.reject(error);
    // }

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        try {
          // Force logout if refresh token is expired
          const currentRefreshToken = getLocalRefreshToken();
          const decoded_token = jwt_decode(currentRefreshToken);

          if (decoded_token.exp * 1000 < Date.now()) {
            console.log("Forced logout EXP R TOKEN");
            router.push("/logout");
          } else {
            // Get new access token with refreshtoken.
            const rs = await refreshToken();
            // console.log("rs", rs);

            const { access } = rs.data;
            window.localStorage.setItem("access_token", access);
            originalConfig.headers.Authorization = "JWT " + access;

            return axiosInstance(originalConfig);
          }
        } catch (_error) {
          console.log("Forced logout");
          // window.localStorage.removeItem("refresh_token");

          // TODO Is this goning to work. Maybe it has to be here
          router.push("/logout");

          console.log("2 _error", _error);
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403) {
        console.log("403");
        return Promise.reject(err.response.data);
      }
      if (err.response.status === 429) {
        console.log("429");
        return Promise.reject(err.response.data);
      }
    }

    if (err.response === undefined) {
      const serverError = {
        code: 503,
        message: "Kunde inte ansluta till servern",
      };

      console.log("503");

      return Promise.reject(serverError);
    }
  }
);

// TODO Remove old logic?
// axiosInstance.interceptors.response.use(
//   (response) => {
//     NProgress.done();
//     return response;
//   },
//   (error) => {
//     NProgress.done();
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refresh_token = getLocalRefreshToken();
//       console.log("1");

//       return axiosInstance
//         .post(`${API_URL}api/users/token/refresh/`, { refresh: refresh_token })
//         .then((response) => {
//           console.log("2");
//           window.localStorage.setItem("access_token", response.data.access);
//           window.localStorage.setItem("refresh_token", response.data.refresh);

//           axiosInstance.defaults.headers.Authorization =
//             "JWT " + response.data.access;
//           originalRequest.headers.Authorization = "JWT " + response.data.access;

//           return axiosInstance(originalRequest);
//         })
//         .catch((err) => {
//           console.log("3");
//           console.error(err);
//         });
//     }
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (res) => {
//     NProgress.done();
//     return res;
//   },
//   async (err) => {
//     NProgress.done();
//     const originalConfig = err.config;

//     if (err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;

//         try {
//           const rs = await refreshToken();
//           const { access } = rs.data;
//           console.log("Accesstoken", access);

//           window.localStorage.setItem("access_token", access);
//           axiosInstance.defaults.headers.common["Authorization"] =
//             "JWT " + access;

//           return axiosInstance(originalConfig);
//         } catch (_error) {
//           if (_error.response && _error.response.data) {
//             return Promise.reject(_error.response.data);
//           }

//           return Promise.reject(_error);
//         }
//       }

//       if (err.response.status === 403 && err.response.data) {
//         return Promise.reject(err.response.data);
//       }
//     }

//     return Promise.reject(err);
//   }
// );

export default axiosInstance;
