<template>
  <div class="exchange">
    <MenuBar :routes="routes" :refresh="() => {}"></MenuBar>
    <v-row>
      <div
        v-masonry
        transition-duration="0.1s"
        item-selector=".item"
        fit-width="true"
        class="masonry-container"
      >
        <div
          v-masonry-tile
          class="item"
          v-bind:key="index"
          v-for="(item, index) in filteredList"
        >
          <LeadCard :lead="item" />
        </div>
      </div>
      <v-col cols="12">
        <p class="ma-4 text-center" v-if="filteredList.length === 0">
          <v-icon x-large>mdi-swap-horizontal</v-icon><br />
          {{ $t("noZwopOffersYet") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import LeadCard from "@/components/leadcard/LeadCard";

export default {
  name: "MyLeads",
  components: {
    MenuBar,
    LeadCard,
  },
  methods: {
    ...mapActions([
      "leadExchange",
      "fetchOfferings",
      "fetchOwnedLeads",
      "fetchMyLikes",
    ]),

    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },
  },
  computed: {
    ...mapGetters(["getOfferings", "getUser", "myOwnedLeads", "myLikes"]),

    filteredList: function () {
      const listItems = [...this.myOwnedLeads, ...this.myLikes];
      console.log(listItems);
      return listItems
        .filter((item) => {
          return item.is_archived === false;
        })
        .filter((item) => {
          return item.title.toLowerCase().includes(this.search.toLowerCase());
        })
        .filter((item) => {
          return item.body.toLowerCase().includes(this.search.toLowerCase());
        });
    },
  },

  created() {
    this.fetchOfferings();
    this.fetchOwnedLeads();
    this.fetchMyLikes();
  },
  mounted() {},

  data: () => ({
    search: "",

    routes: [
      {
        route: "/wallet",
        icon: "mdi-wallet-outline",
        text: "nav.boughtCards",
      },
      {
        route: "/saved",
        icon: "mdi-bookmark-outline",
        text: "nav.savedCards",
      },

      {
        route: "/uploaded",
        icon: "mdi-storefront-outline",
        text: "nav.myCards",
      },
      {
        route: "/archived",
        icon: "mdi-archive-outline",
        text: "nav.archivedCards",
      },
    ],
  }),
};
</script>
<style scoped>
.item {
  width: 350px;
  margin: 4px;
}
.masonry-container {
  width: 100%;
  margin: 0 auto;
}
</style>
