import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;

const state = {
  navDrawer: null,
  isLoading: false,
  error: null,
  status: null,
  info: null,
  webSocketNotification: null,
};

const getters = {
  isLoading: (state) => state.isLoading,
  getError: (state) => state.error,
  getStatus: (state) => state.status,
  getInfo: (state) => state.info,
  getWebSocketNotification: (state) => state.webSocketNotification,
  getNavDrawer: (state) => state.navDrawer,
};

const actions = {
  responseStatus({ commit }, status) {
    commit("setStatus", status);
  },
  responseError({ commit }, error) {
    commit("setError", error);
  },
  responseInfo({ commit }, info) {
    commit("setInfo", info);
  },
  responseWebSocketNotification({ commit }, notification) {
    commit("setWebSocketNotification", notification);
  },
  toggleNavDrawer({ commit }) {
    commit("setToggleNavDrawer");
  },
};

const mutations = {
  setLoading: (state) => (state.isLoading = true),
  unsetLoading: (state) => (state.isLoading = false),
  setStatus: (state, status) => (state.status = status),
  setError: (state, error) => (state.error = error),
  setInfo: (state, info) => (state.info = info),
  setWebSocketNotification: (state, notification) =>
    (state.webSocketNotification = notification),
  setToggleNavDrawer: (state) => (state.navDrawer = !state.navDrawer),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
