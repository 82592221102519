<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <div class="ma-4">
        <v-alert
          text
          prominent
          type="error"
          icon="mdi-shield-account-outline"
          v-if="!mobile"
        >
          <v-row align="center">
            <v-col class="grow" sm="12">
              {{ $t("verifyIdentityText") }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                :loading="loading"
                color="success"
                @click="handleBankIDLogin"
              >
                <v-img
                  class="mr-2"
                  max-height="20px"
                  max-width="20px"
                  src="@/assets/logo-bank-id.svg"
                ></v-img>
                {{ $t("verifyWithBankID") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert
          text
          dense
          type="error"
          icon="mdi-shield-account-outline"
          v-if="mobile"
        >
          {{ $t("verifyIdentityText") }}
          <v-btn
            small
            :loading="loading"
            color="success"
            class="mt-2"
            @click="handleBankIDLogin"
          >
            {{ $t("verifyWithBankID") }}</v-btn
          ></v-alert
        >
      </div>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BankIDVerifyDialog",
  props: ["user"],
  data: () => ({ dialog: false, loading: false }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  ...mapGetters(["getUser"]),
  methods: {
    ...mapActions(["loginBankID"]),
    handleBankIDLogin() {
      this.loading = true;
      this.loginBankID(this.user.personal_identity_number).then(
        () => (this.loading = false)
      );
    },
  },
};
</script>

<style></style>
