<template>
  <div class="exchange-pending" v-if="getPending">
    <MenuBar :routes="routes" :refresh="fetchPending"></MenuBar>
    <Alert
      v-if="!getUser.subscription"
      icon="mdi-thumb-up-outline"
      type="error"
      :text="$t('toMakeMoreZwopOffers')"
      :actionLabel="$t('getPremium')"
      :action="getPremiumLink"
    />
    <v-row class="mb-4"
      ><v-col cols="12">
        <v-card class="">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
        </v-card> </v-col
    ></v-row>
    <v-card class="mb-2 align-center justify-space-between">
      <v-data-table
        :headers="headers"
        :items="getPending"
        :items-per-page="10"
        :search="search"
        :sort-desc="[false, true]"
        multi-sort
      >
        <template v-slot:[`item.actions`]="{ item }">
          <ExchangeCompare
            :offeredLead="item.offered_lead"
            :wantedLead="item.wanted_lead"
          />
          <v-btn class="ma-1" @click="cancelOffer(item)">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.timestamp`]="{ item }">
          <span>{{ new Date(item.timestamp).toLocaleString() }}</span>
        </template>
        <template v-slot:no-data>
          {{ $t("noPendingZwopOffers") }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import Alert from "@/components/Alert";
import ExchangeCompare from "./ExchangeCompare";

export default {
  name: "ExchangePending",
  components: {
    MenuBar,
    ExchangeCompare,
    Alert,
  },
  methods: {
    ...mapActions(["leadExchange", "fetchPending"]),

    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },

    // TODO Fix this to open settings and show the alternative subscriptions
    getPremiumLink() {
      console.log("Get premium subscr link clicked");
    },
    cancelOffer(item) {
      var payload = {
        id: item.id,
        action: "cancel",
        offering: null,
      };
      this.leadExchange(payload);
    },
  },
  computed: {
    ...mapGetters(["getPending", "getUser"]),
  },

  created() {
    this.fetchPending();
  },
  mounted() {},

  data: () => ({
    search: "",
    headers: [
      {
        text: "My offered lead",
        align: "start",
        sortable: true,
        value: "offered_lead.title",
      },
      {
        text: "Wanted lead",
        align: "start",
        sortable: true,
        value: "wanted_lead.title",
      },
      {
        text: "",
        align: "start",
        sortable: true,
        value: "timestamp",
      },
      { text: "", value: "actions", sortable: false },
    ],
    routes: [
      {
        route: "/exchange",
        icon: "mdi-swap-horizontal",
      },
      {
        route: "/pending",
        icon: "mdi-timer-sand",
      },
      {
        route: "/liked",
        icon: "mdi-bookmark-outline",
      },
    ],
  }),
};
</script>
