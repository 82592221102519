<template>
  <v-card class="mb-2 align-center justify-space-between">
    <v-card-title>
      <h3>{{ $t("account.invoices") }}</h3>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="getInvoices"
      :items-per-page="10"
      :search="search"
      :sort-desc="[false, true]"
      item-key="timestamp"
      multi-sort
    >
      <template v-slot:[`item.amount`]="{ item }">
        {{ parseAmount(item.amount) }} kr
      </template>
      <template v-slot:[`item.paid`]="{ item }">
        <v-checkbox v-model="item.paid" disabled></v-checkbox>
      </template>
      <template v-slot:[`item.hosted_invoice_url`]="{ item }">
        <v-btn
          x-small
          :disabled="!item.hosted_invoice_url"
          :href="item.hosted_invoice_url"
          target="_blank"
          >Faktura</v-btn
        >
      </template>
      <template v-slot:[`item.receipt_url`]="{ item }">
        <v-btn
          x-small
          :disabled="!item.receipt_url"
          :href="item.receipt_url"
          target="_blank"
          >Kvitto</v-btn
        >
      </template>
      <template v-slot:[`item.timestamp`]="{ item }">
        <span> {{ new Date(item.timestamp) | date("yyyy-MM-dd HH:mm") }}</span>
      </template>
      <template v-slot:no-data>
        {{ $t("account.noInvoices") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateFilter, format } from "vue-date-fns";

export default {
  name: "Invoices",
  components: {},
  filters: {
    date: dateFilter,
  },
  methods: {
    ...mapActions([]),
    parseAmount(invoiceAmount) {
      return Math.round(invoiceAmount * 100) / 10000;
    },
  },
  computed: {
    ...mapGetters(["getInvoices"]),
  },

  created() {},
  mounted() {},

  data: () => ({
    search: "",
    expanded: [],
    singleExpand: true,
    headers: [
      {
        text: "Datum",
        align: "start",
        sortable: true,
        value: "timestamp",
      },

      {
        text: "Belopp",
        align: "start",
        sortable: false,
        value: "amount",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "hosted_invoice_url",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "receipt_url",
      },
      {
        text: "Betald",
        align: "start",
        sortable: false,
        value: "paid",
      },
    ],
  }),
};
</script>
