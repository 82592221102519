<template>
  <div class="messages">
    <v-card>
      <div class="chatbox">
        <ul class="chatbox__contacts">
          <li
            class="chatbox__contact"
            v-for="(contact, index) in filteredUsers"
            :key="index"
            :style="{ 'background-image': 'url(' + contact.avatar + ')' }"
            :class="
              contact.email === getCurrentRecipient.email
                ? 'chatbox__contact--selected'
                : ''
            "
            @click="setCurrentChatUser(contact.email, contact.id)"
          >
            <!-- <div class="chatbox__onlineIndicator" v-if="index < 3"></div> -->
          </li>
        </ul>
        <div class="chatbox__container">
          <div class="d-flex justify-center chatbox__info">
            <v-btn class="t" plain :to="'/user/' + getCurrentRecipient.id">
              <p>{{ getCurrentRecipient.email }}</p></v-btn
            >
          </div>

          <div class="chatbox__chat">
            <div class="chatbox__messages">
              <template v-for="(message, index) in getConversation">
                <div
                  class="chatbox__date"
                  v-if="
                    index === 0 ||
                      (index > 0 &&
                        convertTimestamp(
                          getConversation[index - 1].timestamp
                        ) !== convertTimestamp(message.timestamp))
                  "
                  :key="index"
                >
                  {{ convertTimestamp(message.timestamp) }}
                </div>
                <div
                  class="chatbox__messageContainer"
                  :class="
                    message.recipient === getUser.email
                      ? 'chatbox__messageContainer--right'
                      : ''
                  "
                  :style="{
                    'margin-top':
                      index > 0 &&
                      getConversation[index - 1].user !== message.user
                        ? '12px'
                        : '4px',
                    'margin-bottom':
                      index < getConversation.length - 1 &&
                      getConversation[index + 1].user !== message.user
                        ? '12px'
                        : '4px',
                  }"
                  :key="index"
                >
                  <div
                    class="chatbox__message"
                    aria-label="message.content"
                    :style="{
                      'background-color':
                        message.user === getUser.email ? 'lightgrey' : 'grey',
                      'border-top-right-radius':
                        index > 0 &&
                        getConversation[index - 1].user === getUser.email &&
                        message.user === getUser.email
                          ? '5px'
                          : '20px',
                      'border-bottom-right-radius':
                        index < getConversation.length - 1 &&
                        getConversation[index + 1].user === getUser.email &&
                        message.user === getUser.email
                          ? '5px'
                          : '20px',
                      'border-top-left-radius':
                        index > 0 &&
                        getConversation[index - 1].user === message.user &&
                        message.user !== getUser.email
                          ? '5px'
                          : '20px',
                      'border-bottom-left-radius':
                        index < getConversation.length - 1 &&
                        getConversation[index + 1].user === message.user &&
                        message.user !== getUser.email
                          ? '5px'
                          : '20px',
                    }"
                  >
                    {{ message.body }}
                    <div
                      class="chatbox__time"
                      :class="
                        message.user === getUser.email
                          ? 'chatbox__time--left'
                          : ''
                      "
                    >
                      {{ convertTimestampToDateTime(message.timestamp) }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="d-flex justify-center"></div>
          </div>
        </div>
      </div>
      <v-card-action>
        <v-text-field
          class="pa-4"
          v-model="msgBody"
          ref="newMessageInput"
          placeholder="New message..."
          @keyup.enter="msgToRecipient"
          @click:append-outer="msgToRecipient"
          append-outer-icon="mdi-send"
          v-if="getCurrentRecipient.email"
          outlined
          hide-details
        ></v-text-field>
      </v-card-action>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
var imgPrefix = process.env.VUE_APP_IMG_PREFIX;
import store from "../store";
import axiosInstance from "../services/axiosAPI";
export default {
  name: "Messages",
  components: {},
  methods: {
    ...mapActions([
      "updateUserList",
      "fetchConversation",
      "sendMessage",
      "setCurrentRecipient",
    ]),

    setCurrentChatUser(email, id) {
      let payload = {
        email: email,
        id: id,
      };

      this.setCurrentRecipient(payload);
      this.fetchConversation(this.getCurrentRecipient.email);

      this.msgBody = "";
    },
    handleEnter(e) {
      if (e.ctrlKey) return console.log("New line", e);

      console.log("Send", e);
    },
    msgToRecipient() {
      console.log(this.msgBody);
      let payload = {
        recipient: this.getCurrentRecipient,
        body: this.msgBody,
      };
      this.sendMessage(payload);
      this.msgBody = "";
    },

    convertTimestamp(timestamp) {
      const milliseconds = timestamp * 1000;

      const dateObject = new Date(timestamp);

      return dateObject.toDateString();
    },

    convertTimestampToDateTime(timestamp) {
      const milliseconds = timestamp * 1000;

      const dateObject = new Date(timestamp);

      return dateObject.toLocaleString();
    },

    getDate() {
      let date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    getTime() {
      let date = new Date();
      return (
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes()
      );
    },
  },
  computed: {
    ...mapGetters([
      "allUsers",
      "getCurrentRecipient",
      "getConversation",
      "getUser",
    ]),
    filteredUsers() {
      return this.allUsers.filter((usr) => {
        //   return usr.first_name
        //     .toLowerCase()
        //     .includes(this.userFilter.toLowerCase());
        // }).filter((usr) => {
        //   return usr.last_name
        //     .toLowerCase()
        //     .includes(this.userFilter.toLowerCase());
        // }).filter((usr) => {
        return usr.email.toLowerCase().includes(this.userFilter.toLowerCase());
      });
    },
  },
  created() {},

  mounted() {
    this.updateUserList();
    // Retrieve your token on client side
    let token = localStorage.getItem("access_token");
    let endpoint = "ws://localhost:8000/";

    // Create new WebSocket
    let socket = new WebSocket(endpoint + "?token=" + token);

    console.log("FRONTEND SOCKET MESSAGES.vue", socket);

    function getMessageById(message) {
      let id = JSON.parse(message).message;
      axiosInstance
        .get("http://localhost:8000/" + `api/chat/v1/message/${id}/`)
        .then((response) => {
          console.log("setMessage", response.data);
          store.commit("setMessage", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    socket.onmessage = function(e) {
      getMessageById(e.data);
    };
  },
  data: () => ({
    activeChat: 1,
    imgPrefix: imgPrefix,
    userFilter: "",
    msgBody: "",
  }),
};
</script>

<style lang="sass">
.chatbox
  display: flex
  align-items: center
  justify-content: flex-start
  width: 100%
  height: 80vh
.chatbox__contacts
  display: inline-block
  height: 100%
  width: 175px
  padding: 10px
  border-right: 1px solid #f2f2f2
  overflow-y: auto
.chatbox__contact
  display: inline-flex
  align-items: center
  justify-content: center
  position: relative
  margin: 5px
  height: 55px
  width: 55px
  border-radius: 40px
  cursor: pointer
  border: 2px solid #fff
  background-size: cover
  background-position: center
  background-color: #e6e6e6
  transition-duration: 0.1s
  &:hover
    box-shadow: 0 0 0 2px #eee
.chatbox__contact--selected
  box-shadow: 0 0 0 2px #09f !important
.chatbox__onlineIndicator
  position: absolute
  width: 16px
  height: 16px
  right: 0
  bottom: 0
  background-color: #2de58d
  border-radius: 50px
  border: 2px solid #fff
.chatbox__container
  position: relative
  width: calc(100% - 175px)
  height: 100%
  width: calc(100% - 85px)
.chatbox__info,
.chatbox__navigation
  align-self: flex-start
  width: 100%
  border-bottom: 1px solid #f2f2f2
.chatbox__info
  padding: 10px 10px
  display: flex
  align-items: center
  text-transform: nonne
  justify-content: space-between
  img
    cursor: pointer
.chatbox__navigation
  display: flex
  height: auto
  align-items: center
  text-align: left
  padding: 8px 10px
.chatbox__channelSwitchButton,
.chatbox__channelNewButton
  display: inline-flex
  align-items: center
  justify-content: center
  width: auto
  font-weight: 400
  white-space: nowrap
  border-radius: 40px
  margin: 0 9px 0 0
  font-size: 11px
  height: 25px
  padding: 4px 12px
  background: #fff
  border: 1px solid #09f
  color: #09f
  cursor: pointer
.chatbox__channelSwitchButton input,
.chatbox__channelNewButton input
  margin: 0
  padding: 0 4px
  border: none
  height: 100%
  width: 96px
  text-align: left
  font: inherit
.chatbox__channelSwitchButton input::placeholder,
.chatbox__channelNewButton input::placeholder
  color: #bbb
.chatbox__channelSwitchButton--selected,
.chatbox__channelNewButton--selected
  background: #09f
  color: #fff !important
.chatbox__channelSwitchButton--input,
.chatbox__channelNewButton--input
  padding: 0 8px
  cursor: default
  display: flex
.chatbox__channelNewButton
  border-color: #e4e4e4
  display: flex
  align-items: center
  justify-content: center
  padding: 0
  height: 25px
  width: 25px
  color: #555
  font-weight: 100
  font-size: 22px
  transition-duration: 0.1s
  &:hover
    border-color: #aaa
.chatbox__chat
  position: relative
  height: calc(100% - 78px)
.chatbox__messages
  margin: 0 auto
  height: 100%
  padding: 20px
  padding-bottom: 75px
  overflow-y: auto
.chatbox__messageInput
  position: absolute
  display: block
  font: inherit
  font-size: 13px
  text-align: left
  position: absolute
  margin: 0 auto
  right: 0
  left: 0
  height: 37px
  padding: 2px 19px
  width: 450px

  border: 1px solid #e4e4e4
  bottom: 26px
  z-index: 2
  &::placeholder
    color: #bbb
.chatbox__messageContainer
  width: 100%
  display: flex
  align-items: center
  margin: 6px 0
  justify-content: flex-start
.chatbox__messageContainer--right
  justify-content: flex-end
  .chatbox__message
    color: #fff
    border: none
.chatbox__message
  position: relative
  max-width: 400px
  padding: 6px 15px
  text-align: left
  border-radius: 20px
  font-size: 12px
  color: #000
  word-break: break-word
  background-color: #f4f4f4
  &:hover
    .chatbox__time
      display: block
.chatbox__time
  display: none
  position: absolute
  color: #ccc
  right: -38px
  top: calc(50% - 9px)
.chatbox__time--left
  left: -38px
  right: auto
.chatbox__date
  width: 100%
  height: 20px
  text-align: center
  margin: 13px 0 10px
  font-size: 12px
  color: #ccc
  &:nth-child(1)
    margin-top: 0
.chatbox__popupMenu,
.chatbox__colorPalette,
.chatbox__userMenu
  position: absolute
  right: 10px
  top: 40px
  background: #fff
  width: 200px
  border-radius: 5px
  box-shadow: 0 0 3px rgba(0,0,0,0.2)
  z-index: 2
.chatbox__popupMenu button,
.chatbox__colorPalette button,
.chatbox__userMenu button
  width: 100%
  padding: 8px 10px
  cursor: pointer
  background-color: #fff
  border: none
.chatbox__popupMenu button:hover,
.chatbox__colorPalette button:hover,
.chatbox__userMenu button:hover
  background: #f4f4f4
.chatbox__popupMenu button:last-child,
.chatbox__colorPalette button:last-child,
.chatbox__userMenu button:last-child
  color: #f00
.chatbox__popupMenu button:disabled,
.chatbox__colorPalette button:disabled,
.chatbox__userMenu button:disabled,
.chatbox__popupMenu button button[disabled],
.chatbox__colorPalette button button[disabled],
.chatbox__userMenu button button[disabled]
  cursor: default
  color: #bbb
.chatbox__popupMenu button:disabled:hover,
.chatbox__colorPalette button:disabled:hover,
.chatbox__userMenu button:disabled:hover,
.chatbox__popupMenu button button[disabled]:hover,
.chatbox__colorPalette button button[disabled]:hover,
.chatbox__userMenu button button[disabled]:hover
  background: #fff
.chatbox__colorPalette
  width: auto
  padding: 9px
  display: grid
  grid-template: 1fr 1fr 1fr/1fr 1fr 1fr 1fr
  grid-gap: 5px
.chatbox__userMenu
  right: auto
  left: 10px
.chatbox__color
  width: 25px
  height: 25px
  border-radius: 30px
  cursor: pointer
  &:hover
    opacity: 0.9
  &:nth-child(1)
    background-color: #ff7ca8
  &:nth-child(2)
    background-color: #247ba0
  &:nth-child(3)
    background-color: #70c1b3
  &:nth-child(4)
    background-color: #b2dbbf
  &:nth-child(5)
    background-color: #ff1654
  &:nth-child(6)
    background-color: #ffba08
  &:nth-child(7)
    background-color: #3f88c5
  &:nth-child(8)
    background-color: #23bf73
  &:nth-child(9)
    background-color: #ff0f80
  &:nth-child(10)
    background-color: #fe4e00
  &:nth-child(11)
    background-color: #f19a3e
  &:nth-child(12)
    background-color: #09f
.darkMode
  color: #fff
  background: #222
  .chatbox__message
    background: #444
    color: #fff
  .chatbox__messageInput
    background: #444
    color: #fff
.darkMode footer,
.darkMode .chatbox__info,
.darkMode .chatbox__navigation,
.darkMode .chatbox__channelSwitchButton,
.darkMode .chatbox__channelNewButton,
.darkMode .chatbox__colorPalette,
.darkMode .chatbox__userMenu
  color: #fff
  background: #222
.darkMode .chatbox__contacts,
.darkMode .chatbox__contact,
.darkMode .chatbox__info,
.darkMode .chatbox__navigation
  border-color: #444
.darkMode .chatbox__channelNewButton img,
.darkMode .chatbox__info img,
.darkMode .chatbox__navigation img
  filter: invert(100%)
</style>
