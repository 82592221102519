<template>
  <div>
    <h1>ADMIN</h1>
    <h2>Ge Reward</h2>

    <v-text-field
      v-model="ProfileUUID"
      label="ProfileUUID"
      required
      outlined
    ></v-text-field>
    <v-text-field
      v-model="rewardAmount"
      label="Reward amount"
      required
      outlined
    ></v-text-field>
    <v-text-field
      v-model="internalComment"
      label="Reason"
      required
      outlined
    ></v-text-field>
    <v-btn
      :loading="loading"
      :disabled="
        loading ||
        !this.ProfileUUID ||
        this.internalComment === '' ||
        this.rewardAmount <= 0
      "
      @click="sendReward"
      color="success"
      >Give reward</v-btn
    >

    <div>
      <v-text-field
        v-model="linkedin_page"
        label="LinkedIn page URL"
        required
        outlined
      ></v-text-field>
      <v-text-field
        v-model="regno"
        label="Org nummer"
        required
        outlined
      ></v-text-field>
      <v-btn
        :loading="loading"
        :disabled="loading"
        @click="getLinkedInInfo"
        color="success"
        >Get LinkedIn page for {{ regno }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axiosInstance from "../../services/axiosAPI";
export default {
  name: "Administration",

  methods: {
    ...mapActions(["responseStatus", "responseError"]),
    sendReward() {
      if (
        this.ProfileUUID !== null &&
        this.internalComment !== "" &&
        this.rewardAmount > 0
      ) {
        var payload = {
          reward_amount: this.rewardAmount,
          internal_comment: this.internalComment,
          profileuuid: this.ProfileUUID,
        };
        this.loading = true;
        axiosInstance
          .post(`api/payments/give_reward/`, payload)
          .then((response) => {
            this.loading = false;
            this.responseStatus({
              code: response.status,
              text: response.statusText,
              message: response.data?.message,
            });
            return response;
          })
          .catch((error) => {
            this.responseError(error);
            this.loading = false;
          });
      }
    },
    getLinkedInInfo() {
      var payload = {
        page_link: this.linkedin_page,
        regno: this.regno,
      };
      this.loading = true;
      axiosInstance
        .post(`api/leads/test_linkedin/`, payload)
        .then((response) => {
          this.loading = false;
          this.responseStatus({
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
          });
          return response;
        })
        .catch((error) => {
          this.responseError(error);
          this.loading = false;
        });
    },
  },
  data() {
    return {
      loading: false,
      rewardAmount: 0,
      ProfileUUID: null,
      internalComment: "",
    };
  },
};
</script>

<style></style>
