<template>
  <div>
    <v-row>
      <v-col md="6" cols="12">
        <div class="d-flex-column">
          <div>
            <LeadContact :lead="lead" />
          </div>
          <div>
            <LeadAllabolag :lead="lead" />
          </div>
          <div>
            <LeadLinkedin :lead="lead" />
          </div>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="d-flex-column">
          <div>
            <LeadChart :lead="lead" />
          </div>
          <div>
            <LeadMap :lead="lead" />
          </div>
          <div>
            <LeadNews :lead="lead" />
          </div>
        </div>
      </v-col>
      <v-col md="6" cols="12"> </v-col>
    </v-row>
  </div>
</template>

<script>
import LeadChart from "./LeadChart.vue";
import LeadMap from "./LeadMap.vue";
import LeadContact from "./LeadContact.vue";
import LeadAllabolag from "./LeadAllabolag.vue";
import LeadLinkedin from "./LeadLinkedin.vue";
import LeadNews from "./LeadNews.vue";
import axios from "axios";

export default {
  name: "LeadCritical",
  components: {
    LeadChart,
    LeadMap,
    LeadAllabolag,
    LeadContact,
    LeadNews,
    LeadLinkedin,
  },
  props: ["lead"],
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}
/* @media (max-width: 600px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 600px) {
  .wrapper {
    grid-template-columns: 2fr 1fr 1fr;
    width: 100%;
  }
}
@media (min-width: 900px) {
  .cards {
    grid-template-columns: 2fr 1fr 1fr;
  }
} */

.box {
}
</style>
