<template>
  <div>
    <v-snackbar
      v-if="getError"
      :value="getError"
      :color="colorError"
      :timeout="getError.timeout ? getStatus.timeout : timeout"
      bottom
      left
      text
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" large>mdi-alert-outline</v-icon>
        <v-layout column>
          <div>
            <strong>Error</strong>
          </div>
          <div>{{ getError.code }}</div>
          <div>{{ getError.message }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <SnackbarProgress
      v-if="getStatus"
      :value="getStatus"
      :color="colorSuccess"
      :timeout="getStatus.timeout ? getStatus.timeout : timeout"
      bottom
      left
      text
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" large>mdi-check</v-icon>
        <v-layout column>
          <!-- <div>{{ getStatus.text }}</div> -->
          <div>{{ getStatus.message }}</div>
          <div>
            <a :href="getStatus.link">{{ getStatus.linkText }}</a>
          </div>
        </v-layout>
      </v-layout>
    </SnackbarProgress>

    <v-snackbar
      v-if="getInfo"
      :value="getInfo"
      :color="colorInfo"
      :timeout="getInfo.timeout ? getInfo.timeout : timeout"
      center
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ getInfo.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>Info</strong>
          </div>
          <div>{{ getInfo.text }}</div>
          <div>{{ getInfo.message }}</div>
          <div>
            <a class="white-link-text" :href="getInfo.link">{{
              getInfo.linkText
            }}</a>
          </div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-snackbar
      v-if="getWebSocketNotification"
      :value="getWebSocketNotification"
      :color="colorSuccess"
      timeout="4000"
      bottom
      left
      elevation="24"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-bell-ring-outline</v-icon>
        <v-layout column>
          <div>
            <strong>{{ getWebSocketNotification.text }}</strong>
          </div>

          <div>{{ getWebSocketNotification.message }}</div>
          <!-- <div class="mt-2">
            <v-btn small outlined @click="reloadBalance"
              ><v-icon>mdi-reload</v-icon>Uppdatera saldo och
              transaktionslista</v-btn
            >
          </div> -->
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarProgress from "@/components/SnackbarProgress";
export default {
  components: { SnackbarProgress },

  methods: {
    ...mapActions(["fetchUserAccount", "fetchTransactions"]),
    reloadBalance() {
      this.fetchUserAccount();
      this.fetchTransactions();
    },
  },
  data() {
    return {
      alert: true,
      snackbar: false,
      timeout: 3000,
      colorError: "error",
      colorSuccess: "success",
      colorInfo: "info",
    };
  },

  computed: {
    ...mapGetters([
      "getError",
      "getStatus",
      "getInfo",
      "getWebSocketNotification",
    ]),
  },
};
</script>

<style scoped>
.white-link-text {
  color: lightgray;
  font-weight: bold;
}
</style>
