var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"messages"},[_c('v-card',[_c('div',{staticClass:"chatbox"},[_c('ul',{staticClass:"chatbox__contacts"},_vm._l((_vm.filteredUsers),function(contact,index){return _c('li',{key:index,staticClass:"chatbox__contact",class:contact.email === _vm.getCurrentRecipient.email
              ? 'chatbox__contact--selected'
              : '',style:({ 'background-image': 'url(' + contact.avatar + ')' }),on:{"click":function($event){return _vm.setCurrentChatUser(contact.email, contact.id)}}})}),0),_c('div',{staticClass:"chatbox__container"},[_c('div',{staticClass:"d-flex justify-center chatbox__info"},[_c('v-btn',{staticClass:"t",attrs:{"plain":"","to":'/user/' + _vm.getCurrentRecipient.id}},[_c('p',[_vm._v(_vm._s(_vm.getCurrentRecipient.email))])])],1),_c('div',{staticClass:"chatbox__chat"},[_c('div',{staticClass:"chatbox__messages"},[_vm._l((_vm.getConversation),function(message,index){return [(
                  index === 0 ||
                    (index > 0 &&
                      _vm.convertTimestamp(
                        _vm.getConversation[index - 1].timestamp
                      ) !== _vm.convertTimestamp(message.timestamp))
                )?_c('div',{key:index,staticClass:"chatbox__date"},[_vm._v(" "+_vm._s(_vm.convertTimestamp(message.timestamp))+" ")]):_vm._e(),_c('div',{key:index,staticClass:"chatbox__messageContainer",class:message.recipient === _vm.getUser.email
                    ? 'chatbox__messageContainer--right'
                    : '',style:({
                  'margin-top':
                    index > 0 &&
                    _vm.getConversation[index - 1].user !== message.user
                      ? '12px'
                      : '4px',
                  'margin-bottom':
                    index < _vm.getConversation.length - 1 &&
                    _vm.getConversation[index + 1].user !== message.user
                      ? '12px'
                      : '4px',
                })},[_c('div',{staticClass:"chatbox__message",style:({
                    'background-color':
                      message.user === _vm.getUser.email ? 'lightgrey' : 'grey',
                    'border-top-right-radius':
                      index > 0 &&
                      _vm.getConversation[index - 1].user === _vm.getUser.email &&
                      message.user === _vm.getUser.email
                        ? '5px'
                        : '20px',
                    'border-bottom-right-radius':
                      index < _vm.getConversation.length - 1 &&
                      _vm.getConversation[index + 1].user === _vm.getUser.email &&
                      message.user === _vm.getUser.email
                        ? '5px'
                        : '20px',
                    'border-top-left-radius':
                      index > 0 &&
                      _vm.getConversation[index - 1].user === message.user &&
                      message.user !== _vm.getUser.email
                        ? '5px'
                        : '20px',
                    'border-bottom-left-radius':
                      index < _vm.getConversation.length - 1 &&
                      _vm.getConversation[index + 1].user === message.user &&
                      message.user !== _vm.getUser.email
                        ? '5px'
                        : '20px',
                  }),attrs:{"aria-label":"message.content"}},[_vm._v(" "+_vm._s(message.body)+" "),_c('div',{staticClass:"chatbox__time",class:message.user === _vm.getUser.email
                        ? 'chatbox__time--left'
                        : ''},[_vm._v(" "+_vm._s(_vm.convertTimestampToDateTime(message.timestamp))+" ")])])])]})],2),_c('div',{staticClass:"d-flex justify-center"})])])]),_c('v-card-action',[(_vm.getCurrentRecipient.email)?_c('v-text-field',{ref:"newMessageInput",staticClass:"pa-4",attrs:{"placeholder":"New message...","append-outer-icon":"mdi-send","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.msgToRecipient.apply(null, arguments)},"click:append-outer":_vm.msgToRecipient},model:{value:(_vm.msgBody),callback:function ($$v) {_vm.msgBody=$$v},expression:"msgBody"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }