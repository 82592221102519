<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-1"
        :block="block"
        :large="large"
        :x-small="xSmall"
        color="success"
        @click="dialog = !dialog"
        >{{ $t("account.deposit") }}</v-btn
      >
    </template>

    <v-card>
      <v-card-title> {{ $t("account.selectAmountToDeposit") }}</v-card-title>
      <v-divider></v-divider
      ><v-card-text>
        <div class="d-flex justify-center flex-wrap">
          <v-card
            v-for="(item, index) in availableMethods"
            :key="index"
            class="pa-4 ma-4"
            outlined
            width="400px"
            @click="selectDepositMethod(item)"
            :disabled="
              (item.method === 'Banköverföring (kommer snart)' &&
                !getUser.is_superuser) ||
              item.disabled
            "
            :elevation="item.method === depositMethod.method ? 0 : 5"
          >
            <v-card-title> {{ item.method }}</v-card-title>
            <v-card-subtitle>
              {{ $t(item.info) }}
            </v-card-subtitle>
          </v-card>
        </div>

        <v-col cols="12">
          {{ $t("account.yourCurrentBalance") }}
          <strong> {{ getUserAccount.balance }}</strong> kr
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="depositAmount"
            :label="$t('amount')"
            :hint="'Minsta insättningsbelopp är 100 kr'"
            persistent-hint
            outlined
            type="number"
            suffix="kr"
          ></v-text-field> </v-col
      ></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClose" class="mr-2"> {{ $t("cancel") }} </v-btn
        ><v-btn
          color="success"
          :disabled="depositAmount < 100"
          @click="deposit"
        >
          Gå vidare
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axiosInstance from "../../services/axiosAPI";
import stripeKlarnaLogo from "@/assets/stripe_klarna_logo.png";
export default {
  name: "DepositDialog",
  props: ["block", "large", "xSmall"],
  components: {},

  watch: {},

  methods: {
    ...mapActions([]),

    selectDepositMethod(item) {
      this.depositMethod = {
        method: item.method,
        info: item.info,
      };
    },

    cardDeposit() {
      if (this.depositAmount === null) return;
      const payload = {
        deposit_amount: this.depositAmount,
        checkout_mode: "payment",
      };

      axiosInstance
        .post("/api/payments/v2/stripe/create-checkout-session/", payload)
        .then((response) => {
          console.log(response);
          window.location = response.data.session.url;
        });
    },
    coinbaseDeposit() {
      if (this.depositAmount === null) return;
      const payload = {
        deposit_amount: this.depositAmount,
      };

      axiosInstance
        .post("/api/payments/v2/coinbase/create-coinbase-charge/", payload)
        .then((response) => {
          console.log(response);
          window.location = response.data.hosted_url;
        });
    },
    zimplerDeposit() {
      if (this.depositAmount === null) return;
      const payload = {
        amount: parseFloat(this.depositAmount).toFixed(2),
        transaction_type: "deposit",
      };

      axiosInstance
        .post(
          "/api/payments/v2/zimpler/create-zimpler-bank-transaction/",
          payload
        )
        .then((response) => {
          console.log(response);
          window.location = response.data.user_form_url;
          return response;
        });
    },

    deposit() {
      if (this.depositMethod.method === "Kort eller Klarna") {
        this.cardDeposit();
      } else if (
        this.depositMethod.method === "Banköverföring (kommer snart)"
      ) {
        this.zimplerDeposit();
      } else if (this.depositMethod.method === "Krypto") {
        this.coinbaseDeposit();
      }
    },

    onClose() {
      this.dialog = false;
      this.e6 = 1;
    },
  },
  computed: { ...mapGetters(["getUser", "getUserAccount"]) },

  mounted() {},

  data: () => ({
    dialog: false,
    e6: 1,
    depositMethod: {
      method: "Kort eller Klarna",
      logotype: stripeKlarnaLogo,
      info: "depositMethodCardInfo",
    },
    availableMethods: [
      {
        method: "Banköverföring (kommer snart)",
        logotype: "",
        info: "Gör en insättning direkt från din bank",
        disabled: false,
      },
      {
        method: "Kort eller Klarna",
        logotype: stripeKlarnaLogo,
        info: "depositMethodCardInfo",
        disabled: false,
      },

      {
        method: "Krypto",
        logotype: "",
        info: "Gör en insättning med Bitcoin eller Ethereum via Coinbase",
        disabled: false,
      },
      {
        method: "Swish (kommer snart)",
        logotype: "",
        info: "Gör en insättning med Swish direkt från telefonen",
        disabled: true,
      },
      // {
      //   method: "Swish",
      //   logotype: "@/assets/Swish_Logo_Primary_RGB.png",
      //   info: "",
      //   disabled: true,
      // },
    ],
    depositAmount: null,
  }),
};
</script>

<style scoped></style>
