import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import auth from "./modules/auth";
import general from "./modules/general";
import leads from "./modules/leads";
import wallet from "./modules/wallet";
import chat from "./modules/chat";
// import websocket from "./modules/websocket";

Vue.use(Vuex);

const state = {};

const getters = {};

const actions = {};

const mutations = {};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    auth,
    general,
    leads,
    wallet,
    chat,
  },
  plugins: [],
});
