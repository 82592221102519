<template>
  <v-app class="background-img">
    <div class="d-flex justify-center align-center">
      <v-card max-width="600px">
        <v-tabs
          v-model="tab"
          show-arrows
          background-color="primary"
          icons-and-text
          dark
          grow
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="i in tabs" :key="i.id">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1" v-text="$t(i.name)"></div>
          </v-tab>

          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" v-model="valid" lazy-validation>
                  <v-row class="mt-2">
                    <v-col cols="12" sm="12" md="12">
                      <span>
                        {{ $t("alreadyHaveAnAccount") }}
                        <a href="/login"> {{ $t("login") }}!</a>
                      </span></v-col
                    >
                    <v-col cols="12" v-if="this.invitedByFirstName">
                      <v-alert text>
                        Du har blivit inbjuden av

                        <strong>{{ this.invitedByFirstName }}</strong
                        >!
                      </v-alert>
                    </v-col>

                    <div></div>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="firstName"
                        :rules="[rules.required]"
                        :label="$t('firstName')"
                        placeholder="Ex. John"
                        maxlength="100"
                        required
                        outlined
                        autocomplete="given-name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="lastName"
                        :rules="[rules.required]"
                        :label="$t('lastName')"
                        placeholder="Ex. Svensson"
                        maxlength="100"
                        required
                        outlined
                        autocomplete="family-name"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        
                        v-model="role"
                        :rules="[rules.required]"
                        :label="$t('role')"
                        :hint="$t('settingsDialog.roleHint')"
                        persistent-hint
                        placeholder="Ex. Säljare"
                        maxlength="100"
                        required
                        outlined
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        
                        v-model="company"
                        :rules="[rules.required]"
                        :label="$t('company')"
                        :hint="$t('settingsDialog.companyHint')"
                        persistent-hint
                        maxlength="100"
                        placeholder="Ex. Företaget AB"
                        required
                        outlined
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        autocomplete="email"
                        :rules="emailRules"
                        :label="
                          $t('email') +
                          ' (' +
                          $t('settingsDialog.onlyForYou') +
                          ')'
                        "
                        required
                        placeholder="minmail@exempel.com"
                        :hint="$t('settingsDialog.emailHint')"
                        persistent-hint
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="phone"
                        :rules="phoneRules"
                        :label="
                          $t('phone') +
                          ' (' +
                          $t('settingsDialog.onlyForYou') +
                          ')'
                        "
                        :hint="$t('settingsDialog.phoneHint')"
                        persistent-hint
                        placeholder="07312312312"
                        maxlength="20"
                        required
                        outlined
                        autocomplete="tel-national"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="personalNumber"
                        :rules="personalNumberRules"
                        :label="
                          $t('personal_identity_number') +
                          ' (' +
                          $t('settingsDialog.onlyForYou') +
                          ')'
                        "
                        :hint="
                          $t('settingsDialog.personal_identity_numberHint')
                        "
                        placeholder="ÅÅÅÅMMDD-NNNN"
                        persistent-hint
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min, rules.password]"
                        :type="show_password ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('password')"
                        hint="At least 8 characters"
                        counter
                        outlined
                        @click:append="show_password = !show_password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        block
                        v-model="verify"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, passwordMatch]"
                        :type="show_password ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('confirmPassword')"
                        counter
                        outlined
                        @click:append="show_password = !show_password"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col cols="12" sm="12" md="12">
                    <v-checkbox
                      v-model="email_newsletter"
                      label="Jag vill gärna få tips och nyhetsbrev"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <TermsDialog :linkOnly="false" @clicked="userTerms" />
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                    <v-btn
                      block
                      :disabled="!valid || !acceptTerms"
                      color="success"
                      @click="handleRegister"
                      type="submit"
                      :loading="isLoading"
                      id="app-register-button"
                      >{{ $t("register") }}</v-btn
                    >
                  </v-col>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import User from "../models/user";
import PasswordResetDialog from "@/components/PasswordResetDialog";
import TermsDialog from "@/components/registration/TermsDialog";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "Signup",
  components: { TermsDialog },

  computed: {
    ...mapGetters(["isLoading", "getError"]),
    passwordMatch() {
      return () =>
        this.password === this.verify || "Lösenorden måste vara lika";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/").catch(() => {});
    }
    this.invitedBy();
  },
  mounted() {
    document.title = "Zwop - Skapa konto";
  },
  methods: {
    ...mapActions([
      "login",
      "register",
      "logout",
      "responseInfo",
      "responseError",
    ]),
    invitedBy() {
      axios
        .get(`${API_URL}api/users/invited_by/${this.inviteCode}`)
        .then((response) => {
          this.invitedByFirstName = response.data.first_name;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    userTerms(value) {
      this.acceptTerms = value === "acceptedTerms" ? true : false;
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    capitalizeStr(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    calculateAge(birthday) {
      // birthday is a date
      var ageDifMs = Date.now() - birthday;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    handleRegister(e) {
      e.preventDefault();

      if (this.personalNumber) {
        const dateISO = this.personalNumber
          .substring(0, 8)
          .replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");

        const birthDate = new Date(dateISO);
        // console.log(birthDate, this.calculateAge(birthDate), "date");

        if (this.calculateAge(birthDate) < 18) {
          const error = {
            message:
              "I enlighet med våra villkor måste du vara minst 18 år för att bli medlem",
          };

          this.responseError(error);
          return;
        }
      }

      if (
        this.email &&
        this.password &&
        this.personalNumber &&
        this.phone &&
        this.firstName &&
        this.lastName
      ) {
        let payload = {
          email: this.email.toLowerCase(),
          password: this.password,
          firstName: this.capitalizeStr(this.firstName),
          lastName: this.capitalizeStr(this.lastName),
          phone: this.phone,
          personalNumber: this.personalNumber,
          inviteCode: this.inviteCode,
          email_newsletter: this.email_newsletter,
        };

        this.register(payload);
      }
    },
  },

  data: () => ({
    user: new User(localStorage.getItem("login_email") || "", ""),
    acceptTerms: false,
    loading: false,
    message: "",
    invitedByFirstName: "",
    dialog: true,
    tab: localStorage.getItem("last_login_selection") === "email" ? 1 : 0,
    tabs: [{ id: 1, name: "signup", icon: "mdi-account-plus-outline" }],
    valid: true,

    firstName: "",
    lastName: "",
    role: "",
    company: "",
    phone: "",
    email: "",
    personalNumber: "",
    city: "",
    inviteCode: localStorage.getItem("zwop_inviteCode"),
    email_newsletter: true,
    password: "",
    verify: "",

    emailRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
    ],

    show_password: false,
    show_personalnumber: true,
    rules: {
      required: (value) => !!value || "Obligatorisk",
      min: (v) => (v && v.length >= 8) || "Minst 8 tecken",
      password: (v) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/.test(
          v
        ) ||
        "Lösenordet måste innehålla minst en liten bokstav, en stor bokstav, en siffra och ett specialtecken",
    },
    phoneRules: [
      (v) => !!v || "Obligatorisk",
      (v) =>
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(v) ||
        "Telefonnumret måste vara korrekt",
    ],
    personalNumberRules: [
      (v) =>
        /^19[0-9]{6}-?[0-9]{4}$|20[0-9]{6}-?[0-9]{4}$/.test(v) ||
        "Personnumret måste anges i formatet YYYYMMDD-NNNN (12 siffror)",
    ],
  }),
  watch: {},
};
</script>

<style scoped>
.background-img {
  background-image: url("~@/assets/teamwork_bg.jpg") !important;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background-size: cover;
}
</style>
