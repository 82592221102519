var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-2 align-center justify-space-between"},[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.$t("account.transactions")))])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getTransactions,"items-per-page":10,"search":_vm.search,"sort-desc":[false, true],"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"reference_no","show-expand":"","multi-sort":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.amountInOrOut(item))+" kr ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayValue(item))+" ")]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.timestamp),"yyyy-MM-dd HH:mm")))])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("account.noTransactions"))+" ")]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('Receipt',{attrs:{"item":item}})],1)]}}],null,true)}),_c('div',{staticClass:"pa-8"},[_c('TaxDeclarationReport')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }