<template>
  <v-card
    :hover="true"
    class="pa-3 d-flex flex-column"
    :color="purchaseIndicator"
  >
    <div class="caption grey-text d-flex justify-end">
      {{ new Date(lead.created_at) | date("dd MMMM yyyy") }}
    </div>
    <div class="d-flex justify-space-between pl-4">
      <div>
        <v-tooltip bottom open-delay="500" :disabled="mobile">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mr-1 mb-1"
              v-for="tag in lead.tags"
              :key="tag.slug"
              color="primary"
              small
              @click.stop="clickOnTag(tag.name)"
            >
              <v-icon x-small left> mdi-tag-outline </v-icon
              >{{ tag.name }}</v-chip
            >
          </template>
          <span> {{ $t("tooltip.tags") }}</span>
        </v-tooltip>
      </div>

      <div
        v-if="lead.price && !lead.owner"
        class="d-flex flex-column justify-content-space lead-price"
      >
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" color="success" label>
              <v-icon small>mdi-check-circle-outline</v-icon>
              {{ leadTotalPrice }} kr
            </v-chip>
          </template>
          <div class="text-center">
            <v-chip dark color="" label outlined>
              <v-icon small>mdi-content-cut</v-icon>
              {{ leadFee }} kr
            </v-chip>
            <br />
            <span> Inklusive betald förmedlingsavgift</span> <br />
            <span v-if="!getUser.is_premium"
              >Skaffa
              <v-icon small color="warning"> mdi-license </v-icon>Premium för
              att sänka avgiften</span
            >
          </div>
        </v-tooltip>
      </div>
      <div v-if="!lead.price" class="lead-price">
        <v-chip color="warning" label> {{ $t("zwopOnly") }}</v-chip>
      </div>
    </div>
    <div :style="{ cursor: 'pointer' }" @click="openDetail(lead)">
      <v-card-title
        style="word-break: break-word"
        class="d-flex justify-space-between pb-1 pt-1"
      >
        <h3>{{ lead.title }}</h3>
      </v-card-title>

      <v-card-text class="pb-1">
        <div class="lead-card-text-area mb-1" v-html="lead.body"></div>
        <div
          v-for="sni in lead.contact_person.company.sni.slice(0, 3)"
          :key="sni.code"
        >
          <v-tooltip bottom open-delay="500" :disabled="mobile">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                class="mr-1 mb-1"
                color=""
                outlined
                small
              >
                <v-icon x-small left> mdi-information-outline </v-icon>
                {{ sni.description }}
              </v-chip>
            </template>
            <span>{{ sni.description }}</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </div>
    <v-spacer></v-spacer>
    <v-card-actions v-if="!lead.hidden_creator" class="pl-4 pb-0">
      <v-menu
        :close-on-content-click="false"
        open-on-hover
        rounded
        top
        offset-y
        max-width="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-badge
              overlap
              :value="lead.created_by.is_premium"
              offset-x="25"
              offset-y="15"
              icon="mdi-license"
              color="warning"
            >
              <v-avatar
                color="secondary"
                v-if="lead.created_by.avatar"
                class="mr-2"
              >
                <v-img
                  v-if="lead.created_by"
                  class="elevation-6"
                  alt=""
                  :src="imgPrefix + lead.created_by.avatar"
                ></v-img>
              </v-avatar>
              <v-avatar
                color="secondary"
                v-if="!lead.created_by.avatar"
                class="mr-2"
              >
                <span class="text-h5">{{ lead.created_by.initials }}</span>
              </v-avatar>
            </v-badge>
            <div v-if="lead.created_by">
              <v-icon class="mr-1" v-if="lead.user_reference"
                >mdi-bullhorn-outline</v-icon
              >{{ lead.created_by.first_name }}
              {{ lead.created_by.last_name }}
            </div>
          </v-btn>
        </template>

        <ProfileCard v-if="!lead.hidden_creator" :profile="lead.created_by" />
      </v-menu>
    </v-card-actions>
    <v-card-actions v-if="lead.hidden_creator" class="pl-4 pb-0">
      <v-menu
        :close-on-content-click="false"
        open-on-hover
        rounded
        top
        offset-y
        max-width="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-badge
              overlap
              :value="lead.created_by.is_premium"
              offset-x="25"
              offset-y="15"
              icon="mdi-license"
              color="warning"
            >
              <v-avatar color="grey" class="mr-2">
                <span class="text-h5">{{ lead.created_by.initials }}</span>
              </v-avatar>
            </v-badge>
            <div v-if="lead.created_by">Dold profil</div>
          </v-btn>
        </template>

        <HiddenProfileCard
          v-if="lead.hidden_creator"
          :profile="lead.created_by"
        />
      </v-menu>
    </v-card-actions>
    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>
      <div class="mt-2 d-flex flex-wrap justify-end">
        <LikeLead :item="lead" v-if="showLikeButton(lead)" />
        <ShareLead :item="lead" />
        <edit-dialog :item="lead" v-if="showEditButton(lead)"></edit-dialog>

        <delete-dialog
          :item="lead"
          v-if="showDeleteButton(lead)"
        ></delete-dialog>
        <ArchiveLead :item="lead" v-if="showArchiveButton(lead)" />

        <HandleLead
          :item="extractLeadOffering(lead)"
          v-if="showHandleLeadButtons(lead)"
        />
        <BuyLead
          v-if="showLikeButton(lead)"
          :item="lead"
          @completedPurchase="filterOutCard"
        />
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createDateFilter } from "vue-date-fns";

import DeleteDialog from "@/components/leadcard/DeleteDialog";
import EditDialog from "@/components/leadcard/EditDialog";
import HandleLead from "@/components/leadcard/HandleLead";
import ActionMenu from "@/components/leadcard/ActionMenu";
import ShareLead from "@/components/leadcard/ShareLead";
import LikeLead from "@/components/leadcard/LikeLead";
import ArchiveLead from "@/components/leadcard/ArchiveLead";
import BuyLead from "@/components/leadcard/BuyLead";
import ProfileCard from "@/components/profile/ProfileCard";
import HiddenProfileCard from "@/components/profile/HiddenProfileCard";
import locale from "date-fns/locale/sv";
export default {
  name: "LeadCard",
  components: {
    "delete-dialog": DeleteDialog,
    "edit-dialog": EditDialog,
    HandleLead,
    ActionMenu,
    ShareLead,
    LikeLead,
    BuyLead,
    ArchiveLead,
    ProfileCard,
    HiddenProfileCard,
  },
  filters: {
    date: createDateFilter("dd MMMM yyyy", { locale }),
  },
  props: ["lead"],
  methods: {
    ...mapActions([]),
    openDetail(item) {
      if (item === null) return;
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },
    profileHasSubscription(user) {
      if (user.subscription === null) return false;
      if (
        user.subscription &&
        new Date(user.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },
    clickOnTag(tag) {
      this.$emit("filterTag", [tag]);
    },
    onDelete() {
      var payload = {
        id: this.itemToDelete.id,
      };
      this.leadInActivate(payload);
      this.dialogDelete = false;
      this.itemToDelete = {};
    },
    onEdit() {
      var payload = {
        id: this.itemToEdit.id,
      };
      this.leadUpdate(payload);
      this.dialogEdit = false;
      this.itemToEdit = {};
    },
    filterOutCard() {
      return (this.purchaseIndicator = "light-green lighten-5");
    },
    showDeleteDialog(item) {
      if (item === null) return;
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    extractLeadOffering(lead) {
      return this.getOfferings.filter((e) => e.offered_lead.id === lead.id)[0];
    },
    showHandleLeadButtons(item) {
      if (item === null) return;
      if (this.getOfferings.some((e) => e.offered_lead.id === item.id)) {
        return true;
      }
      return false;
    },

    showDeleteButton(item) {
      if (item === null) return;
      if (item.created_by.id === this.getUser.id) {
        return true;
      }
      return false;
    },
    showArchiveButton(item) {
      if (item === null) return;
      if (item.owner?.id === this.getUser.id) {
        return true;
      }
      return false;
    },
    showEditButton(item) {
      if (item === null) return;
      if (item.created_by.id === this.getUser.id) {
        return true;
      }
      return false;
    },
    showLikeButton(item) {
      if (this.getUser.id === null) return;
      if (item === null) return;
      if (item.created_by.id === null) return;
      if (item.created_by.id == this.getUser.id) {
        return false;
      }
      return true;
    },
    profileHasSubscription(user) {
      if (user.subscription === null) return false;
      if (
        user.subscription &&
        new Date(user.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },
  },
  computed: {
    ...mapGetters(["getUser", "getProfile", "getOfferings", "getUserAccount"]),

    mobile() {
      return this.$vuetify.breakpoint.xs;
    },

    leadFee() {
      const fee = this.lead.price * this.getUser.courtage;

      return parseFloat(fee).toFixed(2);
    },

    leadTotalPrice() {
      if (this.lead.created_by.id === this.getUser.id) {
        const fee = this.lead.price * this.getUser.courtage;

        return parseFloat(this.lead.price - fee).toFixed(2);
      } else {
        const fee = this.lead.price * this.getUser.courtage;

        return parseFloat(this.lead.price + fee).toFixed(2);
      }
    },
  },
  mounted() {},
  data() {
    return {
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
      purchaseIndicator: null,
    };
  },
};
</script>

<style>
.lead-card-text-area p {
  margin-bottom: 5px;
}
</style>
