<template>
  <div class="d-flex flex-wrap justify-end">
    <v-tooltip bottom open-delay="500" :disabled="mobile">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          @click.stop="acceptExchange()"
        >
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("tooltip.acceptZwop") }}</span>
    </v-tooltip>
    <v-tooltip bottom open-delay="500" :disabled="mobile">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          @click.stop="rejectExchange()"
        >
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("tooltip.rejectZwop") }}</span>
    </v-tooltip>
    <v-tooltip bottom open-delay="500" :disabled="mobile">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <ExchangeCompare
            :offeredLead="item.offered_lead"
            :wantedLead="item.wanted_lead"
          />
        </div>
      </template>
      <span> {{ $t("tooltip.compare") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ExchangeCompare from "../../views/exchange/ExchangeCompare.vue";
export default {
  name: "HandleLead",
  props: ["item"],
  components: { ExchangeCompare },
  methods: {
    ...mapActions(["leadExchange"]),

    acceptExchange() {
      const payload = {
        id: this.item.id,
        action: "accept",
        offering: true,
      };
      this.leadExchange(payload);
    },

    rejectExchange() {
      const payload = {
        id: this.item.id,
        action: "reject",
        offering: true,
      };
      this.leadExchange(payload);
    },
  },
  computed: {
    ...mapGetters([]),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },

  created() {},
  mounted() {},

  data: () => ({}),
};
</script>

<style></style>
