<template>
  <v-dialog scrollable v-model="showDialog" persistent max-width="850">
    <v-card elevation="0">
      <v-card-text class="pa-0">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Din profil
            </v-stepper-step>
            <v-stepper-step :complete="e1 > 2" step="2">
              Att lägga upp leadkort
            </v-stepper-step>

            <v-stepper-step :complete="e1 > 3" step="3">
              Att hitta och köpa leadkort
            </v-stepper-step>
          </v-stepper-header>
          <v-divider></v-divider>
          <v-stepper-items>
            <v-stepper-content step="1">
              <p class="text-h5">Din profil</p>
              <p class="text-subtitle-1">
                Zwop bygger på förtroende. För att ytterligare stärka din profil
                och kunna göra transaktioner behöver du fylla i lite mer
                information om din profil. Du har även möljighet att ändra dessa
                uppgifter i dina
                <v-btn small color="">
                  <v-icon left small>mdi-cog-outline</v-icon
                  >Inställningar</v-btn
                >
              </p>
              <v-row>
                <!-- TODO HERE Om Linkedin används här och fälten är tomma då fylls det i automatiskt och sparas -->

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="getUser.role"
                    :rules="[rules.required]"
                    :label="$t('settingsDialog.role')"
                    :hint="$t('settingsDialog.roleHint')"
                    persistent-hint
                    maxlength="100"
                    required
                    outlined
                    @blur="handleSave"
                    autocomplete="on"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="getUser.company"
                    :rules="[rules.required]"
                    :label="$t('settingsDialog.company')"
                    :hint="$t('settingsDialog.companyHint')"
                    persistent-hint
                    maxlength="100"
                    required
                    outlined
                    @blur="handleSave"
                    autocomplete="on"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <LinkedinDialog />
                  <!-- <v-text-field
                    v-model="getUser.linkedin"
                    :label="
                      $t('settingsDialog.linkedin') +
                      ' (' +
                      $t('addLeadDialog.optional') +
                      ')'
                    "
                    maxlength="100"
                    :hint="$t('settingsDialog.linkedinHint')"
                    persistent-hint
                    outlined
                    @blur="handleSave"
                  ></v-text-field> -->
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="getUser.company_url"
                    :label="
                      $t('settingsDialog.companyUrl') +
                      ' (' +
                      $t('addLeadDialog.optional') +
                      ')'
                    "
                    maxlength="100"
                    :hint="$t('settingsDialog.companyUrlHint')"
                    persistent-hint
                    outlined
                    @blur="handleSave"
                    autocomplete="url"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="getUser.summary"
                    :label="$t('settingsDialog.bio')"
                    :hint="$t('settingsDialog.bioHint')"
                    persistent-hint
                    outlined
                    counter
                    maxlength="1000"
                    @blur="handleSave"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <p class="text-h5">Att sälja leadkort</p>
              <p class="text-subtitle-1">
                Klicka på
                <v-btn small color="success">
                  <v-icon left small>mdi-plus-circle</v-icon>Skapa
                  leadkort</v-btn
                >
                i menyn för att lägga upp ett nytt lead. Fyll sedan i
                efterfrågade uppgifter. Tänk på att skriva en så säljande
                beskrivning som möjligt.
              </p>
              <p></p>
              <v-img
                class="ma-4"
                contain
                style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
                src="@/assets/onboarding/ob_create-lead-dialog.png"
              ></v-img>
            </v-stepper-content>

            <v-stepper-content step="3">
              <p class="text-h5">Att köpa leadkort</p>
              <p class="text-subtitle-1">
                Klicka på
                <v-btn small color="success">
                  <v-icon small>mdi-tune-vertical</v-icon></v-btn
                >
                när du beffiner dig på
                <v-icon>mdi-storefront-outline</v-icon> marknadsplatsen för att
                filtrera på fritext, taggar, SNI-koder eller pris.
              </p>
              <p class="text-subtitle-1">
                Du köper leadkortet direkt genom att klicka
                <v-btn small color="success"> Köp</v-btn>. Du kan också spara
                leadkortet
                <v-btn small>
                  <v-icon small>mdi-bookmark-outline</v-icon>
                </v-btn>
                eller dela det via mail
                <v-btn small>
                  <v-icon small>mdi-share-variant-outline</v-icon>
                </v-btn>
              </p>

              <v-img
                class="ma-4"
                style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
                src="@/assets/onboarding/ob_market_feed.png"
              ></v-img>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn class="mb-1" v-if="e1 !== 3" @click="dontShowOnboarding" text>
          {{ $t("skip") }}
        </v-btn>

        <v-btn class="mb-1" v-if="e1 !== 1" text @click="e1--">
          {{ $t("previous") }}
        </v-btn>

        <v-btn class="mb-1" v-if="e1 !== 3" color="primary" @click="e1++">
          {{ $t("next") }}
        </v-btn>

        <v-btn
          class="mb-1"
          v-if="e1 === 3"
          color="primary"
          @click="dontShowOnboarding"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axiosInstance from "../services/axiosAPI";
import LinkedinDialog from "@/components/usersettings/LinkedinDialog";
export default {
  components: { LinkedinDialog },
  methods: {
    ...mapActions(["fetchUser", "editUser", "saveAvatar", "responseStatus"]),
    dontShowOnboarding() {
      localStorage.setItem("showOnboarding", false);
      this.showDialog = false;
    },

    handleSave() {
      var payload = {
        role: this.getUser.role,
        company: this.getUser.company,
        company_url: this.getUser.company_url,
        linkedin: this.getUser.linkedin,
        summary: this.getUser.summary,
      };

      axiosInstance
        .patch(`api/users/onboarding/`, payload)
        .then((response) => {
          console.log(response);
          this.responseStatus({
            code: response.status,
            message: "Sparat",
          });

          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      valid: true,
      rules: {
        notBlank: (value) => value === "" || "Obligatorisk",
        required: (value) => {
          return !!value || "Obligatorisk";
        },
      },
      webpageRules: [
        (v) =>
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v
          ) || "Hemsideadressen måste vara giltig",
      ],

      showDialog: localStorage.getItem("showOnboarding")
        ? JSON.parse(localStorage.getItem("showOnboarding"))
        : true,
      e1: 1,
    };
  },
};
</script>
