<template>
  <v-card outlined class="my-2 pa-3">
    <div class="d-flex flex-column flex-md-row justify-md-space-between">
      <div>
        <v-card-title style="word-break: break-word" class="">
          {{ lead.contact_person.first_name }}
          {{ lead.contact_person.last_name }}
        </v-card-title>
        <v-card-subtitle> {{ lead.contact_person.role }}</v-card-subtitle>
      </div>

      <a
        class="text-decoration-none ma-4"
        :href="
          'https://www.google.com/maps/search/?api=1&query=' +
          lead.contact_person.street +
          '%2C' +
          lead.contact_person.postal_code +
          lead.contact_person.city
        "
        target="_blank"
      >
        <v-chip class="" color="primary">
          <v-icon small left> mdi-map-marker-outline </v-icon>
          {{ lead.contact_person.city }}</v-chip
        >
      </a>
    </div>
    <v-card-text class="">
      <v-card elevation="0" class="mr-4 mb-4 pa-0"
        ><h4>Gömd information</h4>
        <div
          class=""
          v-if="lead.critical_info"
          v-html="lead.critical_info"
        ></div
      ></v-card>

      <v-card elevation="0" class="mr-4 mb-4 pa-0 d-flex flex-wrap">
        <v-btn
          class="mr-4 mb-4"
          color="success"
          :href="'tel:' + lead.contact_person.phone"
        >
          <v-icon>mdi-phone</v-icon> {{ lead.contact_person.phone }}</v-btn
        >
        <v-btn
          class="mr-4 mb-4"
          color="success"
          :href="'mailto:' + lead.contact_person.email"
        >
          <v-icon>mdi-email</v-icon> {{ lead.contact_person.email }}</v-btn
        >

        <v-btn
          class="mr-4 mb-4"
          color="success"
          :href="lead.contact_person.linkedin"
          target="_blank"
        >
          <v-icon>mdi-linkedin</v-icon>Gå till profil på LinkedIn</v-btn
        >
        <v-btn
          class="mr-4 mb-4"
          color="success"
          :href="`https://www.google.com/search?q=${lead.contact_person.first_name}+${lead.contact_person.last_name}+${lead.contact_person.role}+${lead.contact_person.company.name}`"
          target="_blank"
        >
          <v-icon>mdi-google</v-icon>Snabbsökning på Google</v-btn
        >
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import { dateFilter } from "vue-date-fns";
export default {
  name: "LeadContact",
  components: {},
  filters: {
    date: dateFilter,
  },
  props: ["lead"],
  computed: {
    regnoFormated() {
      const regno = this.lead.contact_person.company.regno;
      const firstPart = regno.slice(0, 6);
      const lastPart = regno.slice(6, 10);
      const formated = `${firstPart}-${lastPart}`;
      return formated;
    },
  },
};
</script>
<style>
.lead-detail-text-area p {
  margin-bottom: 5px;
}
</style>
