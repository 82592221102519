import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
var themeOpt = localStorage.getItem("theme") === "dark";

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#364B49',
        // secondary: '#6A8F81',

        primary: "#005486",
        secondary: "#3dbfba",

        success: "#5cb95e",
        info: "#364b49",
        error: "#f44336",
        warning: "#d9a926",
        backgroud: "#E3EDF2",
      },

      dark: {
        // primary: "#44a9df",
        primary: "#005486",
        secondary: "#3dbfba",
      },
    },
    dark: themeOpt,
  },
});

// const vuetify = new Vuetify({
//   theme: {
//     themes: {
//       light: {
//         primary: '#364556',
//         secondary: '#f9bb0e',
//         secondary2: '#364556',
//         success: '#8dcf3f',
//         info:'#11a0f3',
//         error:'#cd6c40',
//         backgroud:'#e2e8e9'

//       },
//     },
//   },
// })

export default vuetify;
