<template>
  <v-dialog v-model="advancedDialog" persistent scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center">
        <v-text-field
          id="global-search"
          solo
          hide-details
          :placeholder="$t('search')"
          prepend-inner-icon="mdi-magnify"
          class=""
          width="500px"
          v-bind="attrs"
          v-on="on"
          v-model="getFilter.searchOnText"
          @keyup.enter="saveFilter"
          v-show="!mobile"
        ></v-text-field>
        <v-btn
          @click="advancedDialog = true"
          :loading="false"
          color="success"
          elevation="2"
          :class="mobile ? '' : 'ml-2'"
          :block="mobile"
          ><v-icon>mdi-tune-vertical</v-icon></v-btn
        >
        <!-- <v-btn
          @click="saveFilter = true"
          :loading="false"
          color=""
          elevation="2"
          class="ml-2"
          outlined
          v-show="!mobile"
          >{{ $t("findLeads") }}</v-btn
        > -->
      </div>
      <div>
        <v-alert dense outlined text class="mt-2">
          <v-layout align-center>
            <v-icon class="pr-1">mdi-tune-vertical</v-icon>
            <v-layout column>
              <span v-if="activeFilters"><strong>Aktivt filter</strong></span>
              <span v-else><strong>Ingen filtrering</strong></span>
            </v-layout>
            <!-- <v-btn
              @click="saveFilter"
              v-show="getFilterSetting"
              :loading="false"
              color="success"
              elevation="2"
              class="ml-2"
              text
            >
              Reload with old filter
            </v-btn> -->
            <v-btn
              @click="clearFilters"
              :loading="false"
              color="error"
              elevation="2"
              class="ml-2"
              text
              v-show="!mobile"
              :disabled="!activeFilters"
            >
              {{ $t("feed.clearFilters") }}</v-btn
            >
          </v-layout>

          <v-chip
            class="mr-1 mb-1 mt-1"
            v-for="tag in getFilter.filterTags"
            :key="tag"
            color="primary"
            small
          >
            <v-icon x-small left> mdi-tag-outline </v-icon>{{ tag }}</v-chip
          >
          <v-chip
            class="mr-1 mb-1 mt-1"
            v-for="sni in getFilter.filterSnis"
            :key="sni"
            color=""
            outlined
            small
          >
            <v-icon x-small left> mdi-information-outline </v-icon>
            {{ sni }}</v-chip
          >
        </v-alert>
      </div>
      <!-- <v-btn dark icon @click="focusSearch"><v-icon>mdi-magnify</v-icon></v-btn> -->
    </template>
    <v-card class="pa-4" outlined>
      <v-row dense>
        <v-card-title v-if="!mobile" style="word-break: break-word">
          {{ $t("feed.filterTitle") }}</v-card-title
        >
        <v-col cols="12">
          <v-text-field
            v-model="getFilter.searchOnText"
            :label="$t('search')"
            placeholder="Ex. Byggbolag letar lokaler"
            hint="Fritextsök som matchar på leadkortens rubriker och beskrivningar"
            persistent-hint
            single-line
            outlined
            clearable
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="getFilter.filterTags"
            :items="availableTags"
            :label="$t('feed.selectTags')"
            hint="Matchar på leadkortets taggar som valts för att kategorisera leadet."
            persistent-hint
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                small
                @click="select"
                @click:close="removeTag(item)"
              >
                <v-icon x-small left> mdi-tag-outline </v-icon>{{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="getFilter.filterSnis"
            :items="availableSnis"
            :label="$t('feed.filterOnSni')"
            hint="Matchar på leadkortets företagsbeskrivning. Dessa koder kopplas automatiskt till leadkortet."
            persistent-hint
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                small
                @click="select"
                @click:close="removeSni(item)"
              >
                <v-icon x-small left> mdi-information-outline </v-icon
                >{{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>

        <!-- <v-col cols="6">
          <v-select
            v-model="getFilter.priceMin"
            :items="priceList"
            :label="$t('feed.priceFrom')"
            dense
            outlined
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="getFilter.priceMax"
            :items="priceList"
            :label="$t('feed.priceTo')"
            dense
            clearable
            outlined
          ></v-select>
        </v-col> -->
        <!-- <v-col>
          <v-range-slider
            v-model="priceRange"
            :max="maxPrice"
            :min="minPrice"
            hide-details
            class="align-center"
            disabled
          >
            <template v-slot:prepend>
              <v-text-field
                :value="priceRange[0]"
                class="mt-0 pt-0"
                hint="Pris min"
                persistent-hint
                type="number"
                suffix="kr"
                outlined
                dense
                @change="$set(priceRange, 0, $event)"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                :value="priceRange[1]"
                class="mt-0 pt-0"
                outlined
                hint="Pris max"
                persistent-hint
                type="number"
                suffix="kr"
                dense
                @change="$set(priceRange, 1, $event)"
              ></v-text-field>
            </template>
          </v-range-slider>
        </v-col> -->
        <!-- <v-col cols="6">
          <v-select
            v-model="getFilter.ratingMin"
            :items="ratingList"
            :label="$t('feed.ratingFrom')"
            dense
            outlined
            disabled
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="getFilter.ratingMax"
            :items="ratingList"
            :label="$t('feed.ratingTo')"
            dense
            outlined
            disabled
          ></v-select>
        </v-col> -->
      </v-row>
      <!-- <v-row dense>
        <v-col cols="12">
          <v-dialog
            ref="dialog"
            v-model="datePickerModal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                outlined
                dense
                :label="$t('feed.dateRange')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="getFilter.dateRange" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="" @click="datePickerModal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="success" @click="$refs.dialog.save(date)">
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="advancedDialog = false" :loading="false" color="" text>
          {{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="clearFilters"
          :loading="false"
          color="error"
          elevation="2"
          text
        >
          {{ $t("feed.clearFilters") }}</v-btn
        >
        <v-btn
          @click="saveFilter"
          :loading="false"
          color="success"
          elevation="2"
          >{{ $t("findLeads") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FilterRow",
  props: {},
  methods: {
    ...mapActions([
      "fetchTags",
      "fetchSnis",
      "globalFilter",
      "clearFilter",
      "removeTag",
      "removeSni",
      "getFilterSetting",
    ]),
    refreshMarketFetch() {
      console.log("Refresh Fetch Filter Row");
      this.$emit("refreshMarketFetch");
    },

    saveFilter() {
      let filterObj = {
        filterTags: this.getFilter.filterTags,
        filterSnis: this.getFilter.filterSnis,
        searchOnText: this.getFilter.searchOnText,
        priceMin: this.getFilter.priceMin,
        priceMax: this.getFilter.priceMax,
        ratingMin: this.getFilter.ratingMin,
        ratingMax: this.getFilter.ratingMax,
        dateRange: this.getFilter.dateRange,
      };
      this.globalFilter(filterObj);

      this.refreshMarketFetch();
      this.advancedDialog = false;
    },
    clearFilters(event) {
      event.preventDefault();
      this.clearFilter();

      this.leadsInfiniteArray = [];
      this.currentPage = 1;
      this.hasNext = true;
      this.$emit("refreshMarketFetch");
      this.advancedDialog = false;
    },
    getSavedFilter() {
      this.getFilterSetting();
      this.$emit("refreshMarketFetch");
    },
  },
  computed: {
    ...mapGetters(["allTags", "getFilter", "allSnis"]),
    dateRangeText() {
      return this.getFilter.dateRange.join(" -> ");
    },
    activeFilters() {
      if (
        (!this.getFilter.filterTags ||
          this.getFilter.filterTags.length === 0) &&
        (!this.getFilter.filterSnis ||
          this.getFilter.filterSnis.length === 0) &&
        this.getFilter.searchOnText === "" &&
        this.getFilter.priceMin === "" &&
        this.getFilter.priceMax === ""
      ) {
        return false;
      }
      return true;
    },
    availableTags() {
      let tagsList = [];
      this.allTags.forEach((element) => {
        tagsList.push(element.name);
      });
      return tagsList;
    },
    availableSnis() {
      let snisList = [];
      this.allSnis.forEach((element) => {
        snisList.push(element.description);
      });
      return snisList;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  created() {
    this.fetchTags();
    this.fetchSnis();
  },
  mounted() {
    // this.getSavedFilter();
  },
  data: () => ({
    advancedDialog: false,
    date: new Date().toISOString().substr(0, 10),
    datePickerModal: false,
    priceList: [
      50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750,
      800, 850, 900, 950, 1000,
    ],
    minPrice: 0,
    maxPrice: 10000,
    priceRange: [0, 5000],

    ratingList: [1, 2, 3, 4, 5],
  }),
};
</script>
<style scoped>
.sticky {
  position: sticky;
  top: 138px;
}
</style>
