<template>
  <div class="d-flex justify-space-around" outlined v-if="profile">
    <v-list dense class="pa-0 ma-0">
      <v-list-item two-line class="pa-0 ma-0">
        <v-list-item-content class="pa-0 ma-0">
          <v-list-item-title>{{ profile.sold_leads }} </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("profile.soldLeads") }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense class="pa-0 ma-0">
      <v-list-item two-line class="pa-0 ma-0">
        <v-list-item-content class="pa-0 ma-0">
          <v-list-item-title>{{ profile.bought_leads }} </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("profile.boughtLeads") }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
// import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "ProfileStatistics",
  props: ["profile"],
  methods: {
    // getProfileStats() {
    //   axiosInstance
    //     .get(`${API_URL}api/users/user_stats/${this.profile.id}`)
    //     .then((response) => {
    //       this.profileStatistics = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
  mounted() {
    // this.getProfileStats();
  },
  data() {
    return {
      // id: this.$route.params.id,
      // profileStatistics: null,
    };
  },
  watch: {},
};
</script>

<style></style>
