<template>
  <div>
    <v-alert
      v-model="adBanner"
      v-if="!getUser.is_premium"
      dense
      dismissible
      @click="dontShowBanner"
      text
      :icon="false"
      type="success"
    >
      Med <v-icon color="warning"> mdi-license </v-icon>Premium får du en del
      grymma fördelar på Zwop.
      <v-btn link :to="`user/${getUser.id}`" x-small>Gå till din profil</v-btn>
      för att ta reda på mer.
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AdRow",
  methods: {
    dontShowBanner() {
      localStorage.setItem("showMarketBanner", false);
      this.adBanner = false;
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    userHasSubscription() {
      if (this.getUser.subscription === null) return false;
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date()
      ) {
        return false;
      }

      return true;
    },
  },
  data: () => ({
    adBanner: localStorage.getItem("showMarketBanner")
      ? JSON.parse(localStorage.getItem("showMarketBanner"))
      : true,
  }),
};
</script>

<style></style>
