<template>
  <div class="text-center">
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-1 mr-1" icon v-bind="attrs" v-on="on" dark>
          <v-badge
            :color="getNotifications.length !== 0 ? 'success' : '#ffffff00'"
            overlap
            :content="getNotifications.length"
          >
            <v-icon> mdi-bell-ring-outline </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card class="mx-auto" width="400">
        <v-card-title class="">
          {{ $t("notificationMenu.notifications") }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-divider></v-divider>

        <p class="ma-4 text-center" v-if="getNotifications.length === 0">
          <v-icon x-large>mdi-bell-outline</v-icon><br />
          {{ $t("notificationMenu.noNotifications") }}
        </p>

        <v-virtual-scroll
          :items="getNotifications"
          :item-height="70"
          height="300"
          bench="0"
          v-if="getNotifications.length !== 0"
        >
          <template v-slot:default="{ item }">
            <v-list-item-group active-class="" multiple>
              <v-list-item :key="item.id" @click="redirectLink(item)">
                <v-list-item-avatar>
                  <v-img
                    v-if="item.created_by"
                    class=""
                    alt=""
                    :src="imgPrefix + item.created_by.avatar"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-action-text>
                    {{
                      new Date(item.created_at) | date("dd MMMM yyyy HH:mm")
                    }}</v-list-item-action-text
                  >

                  <v-list-item-subtitle class="text--primary"
                    ><span class="font-weight-bold">{{
                      $t(item.notification_type)
                    }}</span>
                    -
                    {{
                      item.created_by.first_name
                        ? item.created_by.first_name +
                          " " +
                          item.created_by.last_name
                        : item.created_by.email
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>
        </v-virtual-scroll>
        <v-btn
          :disabled="getNotifications.length !== 0 ? false : true"
          block
          tile
          color="error"
          @click="clearAll"
        >
          {{ $t("notificationMenu.clearAll") }}
        </v-btn>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { createDateFilter } from "vue-date-fns";
const REDIS_URL = process.env.VUE_APP_REDIS_URL;
import locale from "date-fns/locale/sv";
export default {
  name: "NotificationMenu",
  filters: {
    date: createDateFilter("dd MMMM yyyy", { locale }),
  },
  data: () => ({
    selected: [2],
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,
  }),

  methods: {
    ...mapActions([
      "fetchNotifications",
      "clearNotification",
      "responseWebSocketNotification",
      "fetchUserAccount",
      "fetchTransactions",
    ]),
    clearAll() {
      var payload = {
        notifications: this.getNotifications,
      };
      this.clearNotification(payload);
      this.setBadge(0);
      // const iconNew = "img/icons/favicon-16x16.png";
      // document.getElementById("favicon").href = iconNew;
      // document.title = `Zwop`;
    },
    clearSingle(notification) {
      var payload = {
        notifications: [notification],
      };
      this.clearNotification(payload);
      this.setBadge(this.getNotifications.length);
    },
    addDotFavicon() {
      if (this.getNotifications.length > 0) {
        const iconNew = "img/icons/favicon-16x16-notification.png";
        document.getElementById("favicon").href = iconNew;
        document.title = "🟢 Zwop";
      }
    },

    redirectLink(item) {
      this.clearSingle(item);
      if (/(http(s?)):\/\//i.test(item.route_redirect)) {
        window.open(item.route_redirect);
      } else {
        this.$router.push(item.route_redirect).catch(() => {});
      }
    },

    setBadge(numberToSet) {
      navigator.setAppBadge(numberToSet).catch((error) => {
        //Do something with the error.

        console.error(error);
      });
    },

    reloadBalance() {
      this.fetchUserAccount();
      this.fetchTransactions();
    },

    connectWS() {
      const token = localStorage.getItem("access_token");
      const endpoint = `${REDIS_URL}ws/notifications_routing/`;

      const socket = new WebSocket(endpoint + "?token=" + token);

      console.log("ws: ", socket);

      // Set the badge of app icon

      const that = this;
      socket.onmessage = function (e) {
        console.log("ws onmessage", e.data);

        that.fetchNotifications();
        // that.addDotFavicon();
        that.reloadBalance();
        that.responseWebSocketNotification({
          text: "Avisering",
          message: "Du har en ny avisering",
        });

        that.setBadge(that.getNotifications.length);
      };

      // Socket Connect Functionality
      socket.onopen = function (e) {
        console.log("ws open", e);
      };

      // Socket Error Functionality
      socket.onerror = function (e) {
        console.log("ws error", e);
      };

      // Socket close Functionality
      socket.onclose = function (e) {
        console.log(
          "ws is closed. Reconnect will be attempted in 10 seconds.",
          e.reason
        );

        setTimeout(function () {
          this.connectWS();
        }, 10000);
      };
    },
  },

  computed: mapGetters(["getNotifications", "getWebPageTitle"]),
  created() {
    this.fetchNotifications();
    // TEST WEBSOCKET
    this.connectWS();
    // this.addDotFavicon();
  },
  mounted() {
    console.log(this.getNotifications.length);
    this.setBadge(this.getNotifications.length);
  },
  watch: {
    // $route() {
    //   this.fetchNotifications();
    //   this.addDotFavicon();
    // },
  },
};
</script>
