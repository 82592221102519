<template>
  <v-overlay :value="showUpdateUI">
    <v-snackbar
      content-class="pa-4"
      color="success"
      v-model="showUpdateUI"
      centered
      timeout="-1"
      :multi-line="true"
    >
      <v-layout column>
        <v-icon x-large class="mb-4">mdi-reload</v-icon>
        <span class="text-center text-subtitle-1">
          {{ $t("newVersionMessage") }}</span
        >
        <span class="text-center text-caption">
          Detta är nödvändigt för att applikationen ska fungera</span
        >
        <v-btn class="mt-2" light @click="update"> OK </v-btn>
      </v-layout>
    </v-snackbar>
  </v-overlay>
</template>

<script>
export default {
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
    // TODO Add functionallity to get app version from server and compare to the version in the client. If not matching display the prompt
    // const appVersion = process.env.VUE_APP_VERSION;
    // console.log(appVersion);

    // if (appVersion && appVersion !== "1.0.0") {
    //   this.showUpdateUI = true;
    // }
  },

  methods: {
    async update() {
      this.showUpdateUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
  },
  data() {
    return {
      showUpdateUI: false,
    };
  },
};
</script>

<style></style>
