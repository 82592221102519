import axiosInstance from "./axiosAPI";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

class AuthService {
  login(user) {
    return axiosInstance
      .post(API_URL + "api/users/token/obtain/", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + response.data.access;
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);

        return response.data;
      });
  }

  loadUser(user) {
    return axios
      .post(
        API_URL + "api/users/token/refresh/",
        {
          refresh: localStorage.getItem("refresh_token"),
        },
        config
      )
      .then((response) => {
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);

        return response.data;
      });
  }

  logout() {
    axios
      .post(
        API_URL + "api/users/token/blacklist/",
        {
          refresh_token: localStorage.getItem("refresh_token"),
        },
        config
      )
      .then((response) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      })
      .catch((err) => {
        console.error("LOGOUT ERROR", err);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      });
  }

  loginBankID(personalNumber) {
    const payload = {
      personalNumber,
    };
    return axios
      .post(`${API_URL}api/users/bankid/federated_login/`, payload)
      .then((response) => {
        return response.data;
      });
  }

  loadUserBankID(sessionId) {
    const payload = {
      sessionId,
    };
    return axios
      .post(`${API_URL}api/users/bankid/get_session/`, payload)
      .then((response) => {
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + response.data.jwt.access;
        localStorage.setItem("access_token", response.data.jwt.access);
        localStorage.setItem("refresh_token", response.data.jwt.refresh);

        return response.data;
      });
  }

  register(user) {
    return axios.post(
      API_URL + "api/users/create/",
      {
        email: user.email,
        password: user.password,
        first_name: user.firstName,
        last_name: user.lastName,
        role: user.role,
        company: user.company,
        phone: user.phone,
        personal_identity_number: user.personalNumber,
        city: user.city,
        invite_code: user.inviteCode,
        email_newsletter: user.email_newsletter,
      },
      config
    );
  }
  passwordReset(email) {
    return axios.post(
      API_URL + "api/users/request-reset-email/",
      {
        email: email,
      },
      config
    );
  }
}

export default new AuthService();
