<template>
  <div class="menubar">
    <v-tooltip
      v-for="(route, index) in routes"
      :key="index"
      bottom
      open-delay="500"
      :disabled="mobile"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          router
          :to="route.route"
          class="mr-2 mb-2"
        >
          <v-icon>{{ route.icon }}</v-icon>
          <span v-if="route.text"> {{ $t(route.text) }} </span>
        </v-btn>
      </template>
      <span> {{ route.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MenuBar",
  props: ["routes", "refresh"],
  methods: {},
  computed: {
    ...mapGetters(["isLoading"]),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style>
.menubar {
  margin-bottom: 16px;
  /* margin-top: 16px; */
  /* position: sticky;
  top: 76px;
  z-index: 1; */
}
</style>
