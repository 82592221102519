var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"block":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(_vm._s(_vm.$t("account.withdraw")))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("account.selectWithDrawalMethod")))]),_c('v-divider'),_c('v-card-text',[(!_vm.getUser.personal_identity_number_verified)?_c('BankIDVerifyDialog',{attrs:{"user":_vm.getUser}}):_vm._e(),(!_vm.getUser.phone_verified)?_c('VerifyPhone',{attrs:{"phone":_vm.getUser.phone}}):_vm._e(),_c('div',{staticClass:"d-flex justify-center flex-wrap"},_vm._l((_vm.availableMethods),function(item,index){return _c('v-card',{key:index,staticClass:"pa-4 ma-4",attrs:{"outlined":"","width":"400px","disabled":(item.method === 'Banköverföring (kommer snart)' &&
              !_vm.getUser.is_superuser) ||
            item.disabled,"elevation":item.method === _vm.withDrawalMethod.method ? 0 : 5},on:{"click":function($event){return _vm.selectWithdrawalMethod(item)}}},[_c('v-card-title',[_vm._v(" "+_vm._s(item.method))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t(item.info))+" ")])],1)}),1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("account.yourCurrentBalance"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.getUserAccount.balance))]),_vm._v(" kr ")]),(_vm.getUserAccount.max_withdrawal < _vm.getUserAccount.balance)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Du har totalt samlat ihop REWARDS av ett värde på "),_c('strong',[_vm._v(_vm._s(_vm.getUserAccount.total_rewards)+" kr")]),_vm._v(". Just nu uppfyller du inte omsättningskravet för alla dina REWARDS därför kan du maximalt ta ut "),_c('strong',[_vm._v(_vm._s(parseFloat(_vm.getUserAccount.max_withdrawal).toFixed(2))+" kr")]),_vm._v(". ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('amount'),"outlined":"","type":"number","suffix":"kr"},model:{value:(_vm.withDrawalAmount),callback:function ($$v) {_vm.withDrawalAmount=$$v},expression:"withDrawalAmount"}})],1),_c('v-col',{attrs:{"md":"8","sm":"12"}},[(_vm.withDrawalMethod.method === 'Bank')?_c('span',{staticClass:"text-h5"},[_vm._v(" till bankkonto "),_c('strong',[_vm._v(" "+_vm._s(_vm.getUser.bank_clearing)+" "+_vm._s(_vm.getUser.bank_account)+" ("+_vm._s(_vm.getUser.bank)+")")]),(
              !_vm.getUser.bank_clearing || !_vm.getUser.bank_account || !_vm.getUser.bank
            )?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Du måste ange dina fullständiga kontouppgifter för att kunna göra uttag. Gå till inställningar för att göra detta. ")]):_vm._e()],1):_vm._e(),(_vm.withDrawalMethod.method === 'Swish')?_c('span',{staticClass:"text-h5"},[_vm._v(" till telefonnummer "),_c('strong',[_vm._v(" "+_vm._s(_vm.getUser.phone)+" ")]),(!_vm.getUser.phone)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Du måste ange ditt telefonnummer för att kunna göra uttag. Gå till inställningar för att göra detta. ")]):_vm._e()],1):_vm._e()])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),(
          _vm.getUser.personal_identity_number_verified && _vm.getUser.phone_verified
        )?_c('v-btn',{attrs:{"color":"success","disabled":parseFloat(_vm.withDrawalAmount) >
            parseFloat(_vm.getUserAccount.max_withdrawal) ||
          parseFloat(_vm.withDrawalAmount) <= 0},on:{"click":_vm.withdrawal}},[_vm._v(" "+_vm._s(_vm.$t("account.doWithdrawal"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }