<template>
  <v-dialog v-model="dialogCancel" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="" v-bind="attrs" v-on="on">
        {{ $t("cancelSubscription") }}</v-btn
      >
    </template>

    <v-card>
      <v-card-title>{{ $t("cancelSubscriptionTitle") }}</v-card-title>
      <v-card-text
        >{{ $t("cancelSubscriptionText") }}

        <v-text-field
          class="mt-4"
          v-model="check"
          outlined
          :label="$t('enterYourEmailHere')"
          :placeholder="getUser.email"
        ></v-text-field
      ></v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">{{
          $t("close")
        }}</v-btn>
        <v-btn
          color="error"
          :disabled="setCheckValue"
          @click.stop="cancelSubscription"
          :loading="loading"
        >
          {{ $t("cancelSubscription") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axiosInstance from "../../services/axiosAPI";

import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "CancelSubscriptionDialog",
  data() {
    return {
      dialogCancel: false,
      check: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),

    setCheckValue() {
      if (this.check === "") return true;

      if (this.check.toLowerCase().trim() !== this.getUser.email) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["fetchUser"]),
    closeDialog() {
      this.dialogCancel = false;
      this.check = "";
    },
    cancelSubscription() {
      this.loading = true;
      return (
        axiosInstance
          .post("/api/payments/v2/stripe/cancel-at-period-end-subscription/")
          .then((response) => {
            return response;
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            this.loading = false;

            this.closeDialog();
            this.fetchUser();
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            this.loading = false;
            return {
              paymentMethodId: paymentMethodId,
              subscription: result,
            };
          })
          // // Some payment methods require a customer to be on session
          // // to complete the payment process. Check the status of the
          // // payment intent to handle these actions.
          // .then(handlePaymentThatRequiresCustomerAction)
          // // If attaching this card to a Customer object succeeds,
          // // but attempts to charge the customer fail, you
          // // get a requires_payment_method error.
          // .then(handleRequiresPaymentMethod)
          // // No more actions required. Provision your service for the user.
          // .then(onSubscriptionComplete)
          .catch((error) => {
            this.loading = false;
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            console.log(error);
          })
      );
    },
  },
};
</script>
<style lang=""></style>
