var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"navbar-main"}},[_c('v-tour',{attrs:{"name":"navBarTour","steps":_vm.steps,"options":_vm.tourOptions}}),_c('v-app',[_c('v-app-bar',{attrs:{"app":"","clipped-left":"","color":"primary"}},[(_vm.mobile)?_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),(!_vm.mobile && !_vm.tablet)?_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleNavDrawer}},[_c('v-avatar',{staticClass:"secondary",attrs:{"size":"30"}},[(_vm.getUser.avatar)?_c('v-img',{attrs:{"src":_vm.imgPrefix + _vm.getUser.avatar}}):_vm._e()],1)],1):_vm._e(),_c('v-img',{staticClass:"hidden-sm-and-down",attrs:{"contain":"","max-height":"45","max-width":"160","src":require("@/assets/Zwop_Logo_White.png")}}),_c('FilterRow',{on:{"refreshFetch":_vm.refreshFetch}}),(!_vm.mobile && !_vm.tablet)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":_vm.startNavTour}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1):_vm._e(),_c('NotificationMenu',{attrs:{"data-v-step":"11"}}),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","outlined":"","color":"secondary","data-v-step":"12"},on:{"click":_vm.goToAccount}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getUserAccount.balance ? _vm.getUserAccount.balance : "...")+" kr ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.accountTooltip")))])])],1),_c('v-navigation-drawer',{attrs:{"value":_vm.getNavDrawer,"app":"","clipped":""}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('SettingsDialog',{attrs:{"data-v-step":"2"}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.settingsTooltip")))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.toggleNavDrawer}},[_c('v-icon',[_vm._v("mdi-arrow-expand-left ")])],1)],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{staticClass:"d-flex justify-center",attrs:{"to":'/user/' + _vm.getUser.id}},[_c('v-badge',{attrs:{"avatar":"","bordered":"","overlap":"","value":_vm.getUser.subscription,"offset-x":"20","offset-y":"20"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-img',{attrs:{"src":require("@/assets/Zwop_Logo_Z.png")}})],1)]},proxy:true}],null,true)},[_c('v-avatar',{staticClass:"secondary",attrs:{"size":"70"}},[(_vm.getUser.avatar)?_c('v-img',{attrs:{"src":_vm.imgPrefix + _vm.getUser.avatar}}):_vm._e()],1)],1)],1),_c('v-list-item',{staticClass:"d-flex justify-center",attrs:{"to":'/user/' + _vm.getUser.id}},[_c('v-list-item-content',{staticClass:"text-center"},[(_vm.getUser)?_c('v-list-item-title',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getUser.first_name)+" ")]):_vm._e(),(_vm.getUser)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getUser.email))]):_vm._e()],1)],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.profileTooltip"))+" ")])]),_c('v-list-item',[_c('v-list-item-content',{attrs:{"data-v-step":"0"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddLead',{attrs:{"showAvatar":false}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.addLeadToolTip"))+" ")])])],1)],1),_vm._l((_vm.items),function(item,i){return [(item.heading)?_c('v-row',{key:i,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(item.heading)?_c('v-subheader',[_vm._v(" "+_vm._s(item.heading)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v("edit")])],1)],1):(item.divider)?_c('v-divider',{key:i,staticClass:"my-4"}):_c('v-list-item',{key:i,attrs:{"link":"","router":"","to":item.route}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',_vm._g(_vm._b({staticClass:"grey--text",attrs:{"data-v-step":i + 4},domProps:{"innerHTML":_vm._s(_vm.$t(item.text))}},'v-list-item-title',attrs,false),on))],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(item.tooltip))}})])],1)]})],2),_c('div',{staticClass:"container",attrs:{"data-v-step":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('QrCodeDialog',{attrs:{"user":_vm.getUser}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.QRCodeTooltip"))+" ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12"}},[_c('Connectivity'),_c('router-view')],1)],1),_c('BottomNav')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }