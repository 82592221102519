<template>
  <div class="backlog-view">
    <menu-bar :routes="routes"></menu-bar>

    <v-card
      v-for="item in items"
      :key="item.id"
      class="mb-2 d-flex align-center justify-space-between pr-4"
      color=""
      @click="openDetail(item)"
    >
      <v-card-title> #{{ item.id }} {{ item.title }}</v-card-title>

      <v-chip label :color="badgeColor(item)" class="white--text">
        {{ badgeText(item) }}
      </v-chip>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import MenuBar from "@/components/navigation/MenuBar";

const badgeDetail = {
  todo: {
    text: "todo",
    color: "teal darken-4",
  },

  open: {
    text: "Open",
    color: "yellow darken-4",
  },

  closed: {
    text: "Closed",
    color: "success",
  },
};

export default {
  name: "Backlog",
  components: {
    "menu-bar": MenuBar,
  },

  data() {
    return {
      routes: [
        {
          route: "/board",
          icon: "mdi-bulletin-board",
        },
        {
          route: "/backlog",
          icon: "mdi-clipboard-list-outline",
        },

        {
          route: "/table",
          icon: "mdi-table",
        },
      ],
    };
  },
  computed: mapState({
    items: (s) => [
      ...s.wallet.items.todo,
      ...s.wallet.items.open,
      ...s.wallet.items.closed,
    ],
  }),
  methods: {
    itemLane(item) {
      if (this.$store.state.wallet.items.todo.includes(item)) {
        return "todo";
      }

      if (this.$store.state.wallet.items.open.includes(item)) {
        return "open";
      }

      return "closed";
    },

    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },

    badgeText(item) {
      const lane = this.itemLane(item);
      return badgeDetail[lane].text;
    },

    badgeColor(item) {
      const lane = this.itemLane(item);
      return badgeDetail[lane].color;
    },
  },
};
</script>
