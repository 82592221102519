<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-1" block @click="dialog = !dialog">{{
        $t("account.withdraw")
      }}</v-btn>
    </template>

    <v-card>
      <v-card-title> {{ $t("account.selectWithDrawalMethod") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <BankIDVerifyDialog
          :user="getUser"
          v-if="!getUser.personal_identity_number_verified"
        />
        <VerifyPhone :phone="getUser.phone" v-if="!getUser.phone_verified" />

        <div class="d-flex justify-center flex-wrap">
          <v-card
            v-for="(item, index) in availableMethods"
            :key="index"
            class="pa-4 ma-4"
            outlined
            width="400px"
            @click="selectWithdrawalMethod(item)"
            :disabled="
              (item.method === 'Banköverföring (kommer snart)' &&
                !getUser.is_superuser) ||
              item.disabled
            "
            :elevation="item.method === withDrawalMethod.method ? 0 : 5"
          >
            <v-card-title> {{ item.method }}</v-card-title>
            <v-card-subtitle>
              {{ $t(item.info) }}
            </v-card-subtitle>
          </v-card>
        </div>

        <v-col cols="12">
          {{ $t("account.yourCurrentBalance") }}
          <strong> {{ getUserAccount.balance }}</strong>
          kr
        </v-col>
        <v-col
          cols="12"
          v-if="getUserAccount.max_withdrawal < getUserAccount.balance"
        >
          Du har totalt samlat ihop REWARDS av ett värde på
          <strong>{{ getUserAccount.total_rewards }} kr</strong>. Just nu
          uppfyller du inte omsättningskravet för alla dina REWARDS därför kan
          du maximalt ta ut
          <strong
            >{{
              parseFloat(getUserAccount.max_withdrawal).toFixed(2)
            }}
            kr</strong
          >.
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="withDrawalAmount"
            :label="$t('amount')"
            outlined
            type="number"
            suffix="kr"
          ></v-text-field>
        </v-col>

        <v-col md="8" sm="12"
          ><span v-if="withDrawalMethod.method === 'Bank'" class="text-h5">
            till bankkonto
            <strong>
              {{ getUser.bank_clearing }} {{ getUser.bank_account }} ({{
                getUser.bank
              }})</strong
            >

            <v-alert
              type="error"
              v-if="
                !getUser.bank_clearing || !getUser.bank_account || !getUser.bank
              "
            >
              Du måste ange dina fullständiga kontouppgifter för att kunna göra
              uttag. Gå till inställningar för att göra detta.
            </v-alert>
          </span>
          <span v-if="withDrawalMethod.method === 'Swish'" class="text-h5">
            till telefonnummer <strong> {{ getUser.phone }} </strong>

            <v-alert type="error" v-if="!getUser.phone">
              Du måste ange ditt telefonnummer för att kunna göra uttag. Gå till
              inställningar för att göra detta.
            </v-alert>
          </span>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClose" class="mr-2">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="success"
          :disabled="
            parseFloat(withDrawalAmount) >
              parseFloat(getUserAccount.max_withdrawal) ||
            parseFloat(withDrawalAmount) <= 0
          "
          v-if="
            getUser.personal_identity_number_verified && getUser.phone_verified
          "
          @click="withdrawal"
        >
          {{ $t("account.doWithdrawal") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
import { mapActions, mapGetters } from "vuex";
import BankIDVerifyDialog from "../leadcard/BankIDVerifyDialog.vue";
import VerifyPhone from "../usersettings/VerifyPhone.vue";
import swishLogo from "@/assets/Swish_Logo_Primary_RGB.png";

export default {
  name: "WithdrawDialog",

  components: { BankIDVerifyDialog, VerifyPhone },

  watch: {},

  methods: {
    ...mapActions(["fetchUserAccount", "fetchTransactions", "responseError"]),

    selectWithdrawalMethod(item) {
      this.withDrawalMethod = {
        method: item.method,
        info: item.info,
      };
    },

    onClose() {
      this.dialog = false;
      this.e6 = 1;
    },
    tradBankWithdrawal() {
      if (this.withDrawalAmount === null) return;
      if (this.withDrawalAmount <= 0) {
        const error = {
          message: "Du verkar ha fyllt i ett felaktigt uttagsbelopp.",
        };

        this.responseError(error);

        return;
      }
      const payload = {
        withdrawal_amount: this.withDrawalAmount,
        internal_comment: `Användaren har valt uttagsmetod:${this.withDrawalMethod.method}`,
      };

      axiosInstance
        .post("/api/payments/withdrawal/", payload)
        .then((response) => {
          console.log(response);
        })
        .then(() => {
          this.fetchUserAccount();
          this.fetchTransactions();
        })
        .catch((error) => console.error(error));

      // this.addTransaction(payload);
      // TODO Call Swish payouts API instead of manual handling

      this.onClose();
    },

    zimplerWithdrawal() {
      if (this.withDrawalAmount === null) return;
      const payload = {
        amount: parseFloat(this.withDrawalAmount).toFixed(2),
        transaction_type: "withdrawal",
      };

      axiosInstance
        .post(
          "/api/payments/v2/zimpler/create-zimpler-bank-transaction/",
          payload
        )
        .then((response) => {
          console.log(response);
          window.location = response.data.user_form_url;
          return response;
        });
    },

    withdrawal() {
      if (this.withDrawalMethod.method === "Bank") {
        this.tradBankWithdrawal();
      } else if (
        this.withDrawalMethod.method === "Banköverföring (kommer snart)"
      ) {
        this.zimplerWithdrawal();
      }
    },
  },
  computed: {
    ...mapGetters([
      "allTags",
      "isLoading",
      "getError",
      "getUser",
      "getUserAccount",
    ]),

    disabledButton() {
      if (
        this.getUser.personal_identity_number_verified &&
        this.getUser.phone_verified
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  data: () => ({
    dialog: false,
    e6: 1,
    withDrawalMethod: {
      method: "Bank",
      logotype: "",
      info: "withdrawalMethodBankInfo",
      disabled: false,
    },
    availableMethods: [
      {
        method: "Banköverföring (kommer snart)",
        logotype: "",
        info: "Gör ett uttag direkt till ditt bankkonto",
        disabled: false,
      },
      {
        method: "Bank",
        logotype: "",
        info: "withdrawalMethodBankInfo",
        disabled: false,
      },

      {
        method: "Swish (kommer snart)",
        logotype: "",
        info: "withdrawalMethodSwishInfo",
        disabled: true,
      },
    ],
    withDrawalAmount: null,

    rules: {
      required: (value) => !!value || "Obligatorisk",
      max: (v) =>
        (v && v <= parseFloat(this.getUserAccount.max_withdrawal)) ||
        "Ange ett lägre belopp",
      min: (v) => (v && v > 0) || "Ange ett högre belopp",
    },
    formErrors: (value) => false,
  }),
};
</script>

<style scoped></style>
