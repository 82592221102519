<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="ma-1">
        <v-icon>mdi-alert-octagram-outline</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-4">
      <v-card-title>Anmäl</v-card-title>
      <v-card-text
        >Här beskriver du så utförligt som möjlig vad du upplever är felaktigt
        med leadkortet.</v-card-text
      >
      <v-card-text>
        <v-textarea
          outlined
          :label="'Anmälan'"
          auto-grow
          counter
          v-model="reportText"
          maxlength="4000"
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="success" type="submit" @click.stop="reportAbuse"
          >Anmäl</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
import { mapActions } from "vuex";

export default {
  name: "ReportAbuseDialog",
  props: ["item"],
  methods: {
    ...mapActions(["responseStatus"]),
    reportAbuse() {
      if (this.reportText === "") return;
      const payload = {
        report_text: this.reportText,
        lead_id: this.item.id,
      };
      axiosInstance
        .post(`api/leads/report_abuse/`, payload)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            message: response.data?.message,
          });

          return response;
        })
        .catch((error) => {
          console.error(error);
        });
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
      reportText: "",
    };
  },
};
</script>

<style></style>
