<template>
  <v-card class="pa-3 fill-height my-2" outlined id="news-box">
    <div class="fill-height d-flex-column justify-center align-center">
      <div v-if="news.length" class="d-flex justify-center my-2">
        <v-btn color="success" small @click="loadMore" :disabled="!hasNext">
          <v-icon>mdi-sync</v-icon> {{ $t("loadMoreNews") }}</v-btn
        >
      </div>
      <v-simple-table :height="tableHeight" id="news-table">
        <template v-slot:default>
          <tbody>
            <tr v-for="article in news" :key="article.id">
              <td>
                <a
                  class="text-decoration-none"
                  :href="article.url"
                  target="_blank"
                >
                  {{ article.heading }}
                </a>
              </td>
              <td nowrap>
                {{ new Date(article.published) | date("yyyy-MM-dd") }}
              </td>
              <td v-if="!mobile">
                <v-chip x-small color="primary">{{ article.label }}</v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-if="!news.length" class="mt-4 text-center" width="200px">
        <v-alert class="my-6" dense text color="error">
          <span class="">
            {{ $t("noNewsAvailable") }}
          </span>
        </v-alert>
      </div>
    </div>
  </v-card>
</template>

<script>
import { dateFilter } from "vue-date-fns";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "LeadNews",
  props: ["lead"],
  filters: {
    date: dateFilter,
  },
  methods: {
    loadMore() {
      if (this.hasNext) {
        this.currentPage += 1;
        this.getNews();
      }
      if (!this.hasNext) {
        console.log("Lead Detail: No more news articles to load");
      }
    },
    getNews() {
      axiosInstance
        .get(`${API_URL}api/leads/news/${this.regno}/?page=${this.currentPage}`)
        .then((response) => {
          const data = response.data;
          this.hasNext = false;
          if (data.next) {
            this.hasNext = true;
          }
          data.results.map((result) => this.news.push(result));
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    regno() {
      return this.lead?.contact_person.company.regno;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tableHeight() {
      // const newsBox = document.querySelector("#news-box");
      // console.log(newsBox);
      // console.log(newsBox.getBoundingClientRect().height);
      // const heightOfBox = newsBox.getBoundingClientRect().height - 100 || 400;
      // console.log(heightOfBox - 100);
      const articles = this.news.length;

      return articles >= 5 || this.hasNext ? "400px" : "";
    },
  },
  created() {
    // window.addEventListener("scroll", this.handleScroll);
    this.getNews();
  },
  mounted() {},
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      news: [],
      currentPage: 1,
      hasNext: true,
    };
  },
};
</script>
