<template>
  <v-alert v-model="alert" text prominent :type="type" :icon="icon">
    <v-row align="center">
      <v-col class="grow">
        {{ text }}
      </v-col>
      <v-col class="shrink">
        <v-btn @click="action" color="success" :disabled="disabled">
          {{ actionLabel }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  props: ["icon", "type", "text", "actionLabel", "action"],

  props: {
    icon: { type: String },
    type: { type: String },
    text: { type: String },
    actionLabel: { type: String },
    action: { type: Function },
  },

  data() {
    return {
      alert: true,
      disabled: false,
    };
  },
};
</script>
