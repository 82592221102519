<template>
  <div class="dashboard">
    <Account />
    <CompletedExchanges />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Account from "@/components/account/Account";
import CompletedExchanges from "@/components/dashboard/CompletedExchanges";

export default {
  name: "Dashboard",
  components: { Account, CompletedExchanges },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters([]),
  },
  beforeMount() {},
  created() {},
  data: () => ({}),
};
</script>
