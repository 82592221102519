<template>
  <v-card class="pt-3 pr-3 pl-3 pb-3 my-2" outlined>
    <iframe
      width="100%"
      height="400"
      frameborder="0"
      style="border: 0"
      :src="
        'https://www.google.com/maps/embed/v1/search?q=' +
        lead.contact_person.street +
        '%2C' +
        lead.contact_person.postal_code +
        lead.contact_person.city +
        '&key=AIzaSyCOie0uYUnQkVUONuXwlIbhCjpS8ImyMek'
      "
      allowfullscreen
    ></iframe>
    <!-- <div id="map"></div> -->
  </v-card>
</template>

<script>
// import { Loader } from "@googlemaps/js-api-loader";
// const loader = new Loader({
//   apiKey: "AIzaSyDwffg5iqVUJpklBrF12MyYu_DH6jyKJrs",
//   version: "weekly",
// });
export default {
  name: "LeadMap",
  props: ["lead"],
  methods: {
    // codeAddress(address) {
    //   const geocoder = new google.maps.Geocoder();
    //   geocoder.geocode(
    //     {
    //       address: address,
    //     },
    //     function (results, status) {
    //       if (status == google.maps.GeocoderStatus.OK) {
    //         const myOptions = {
    //           zoom: 8,
    //           center: results[0].geometry.location,
    //           mapTypeId: google.maps.MapTypeId.ROADMAP,
    //         };
    //         const map = new google.maps.Map(
    //           document.getElementById("map"),
    //           myOptions
    //         );
    //         const marker = new google.maps.Marker({
    //           map: map,
    //           position: results[0].geometry.location,
    //         });
    //       }
    //     }
    //   );
    // },
  },
  mounted() {
    // const address = `${this.lead.contact_person.street}, ${this.lead.contact_person.postal_code} ${this.lead.contact_person.city}`;
    // loader.load().then(() => {
    //   this.codeAddress(address);
    // });
  },
};
</script>

<style scoped>
#map {
  height: 400px;
  width: 100%;
}
</style>
