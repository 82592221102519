<template>
  <v-card class="pa-3 my-2 fill-height" outlined>
    <line-chart
      v-if="chartLoaded && chartdata.labels.length > 0"
      :chartdata="chartdata"
      :options="options"
    />
    <div
      class="fill-height d-flex justify-center align-center"
      v-if="chartLoaded && chartdata.labels.length == 0"
    >
      <v-alert class="my-6" dense text color="error">
        <span class="">
          {{ $t("noFinancialDataAvailable") }}
        </span>
      </v-alert>
    </div>
  </v-card>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import { format } from "date-fns";
import LineChart from "./Chart.vue";

export default {
  name: "LeadChart",
  components: {
    LineChart,
  },
  props: ["lead"],
  methods: {
    // getFinancial(regno) {
    //   axiosInstance
    //     .get(`${API_URL}api/leads/financial/${regno}`)
    //     .then((response) => {
    //       this.financialData = response.data.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
  computed: {},

  watch: {},
  data: () => ({
    chartLoaded: false,
    chartdata: null,

    options: {
      responsive: true,
      maintainAspectRatio: false,
      devicePixelRatio: 2,
    },
  }),
  async mounted() {
    this.chartLoaded = false;
    try {
      axiosInstance
        .get(
          `${API_URL}api/leads/financial/${this.lead.contact_person.company.regno}/`
        )
        .then((response) => {
          // this.financialData = response.data.data;
          const obj = {
            labels: [],
            datasets: [
              {
                label: "Nettoomsättning",
                backgroundColor: "#e2e4f3",
                borderColor: "#005486",
                borderWidth: 1,

                borderSkipped: "bottom",
                data: [],
              },
              {
                label: "Rörelseresultat EBIT",
                backgroundColor: "#dbeef9",
                borderColor: "#44a9df",
                borderWidth: 1,

                borderSkipped: "bottom",
                data: [],
              },
              {
                label: "Årets resultat",
                backgroundColor: "#dff5f4",
                borderColor: "#4ac5c1",
                borderWidth: 1,

                borderSkipped: "bottom",
                data: [],
              },
            ],
          };

          response.data.data.map((item) => {
            const divided = item.financial_year.split("-");
            const yearOnly = divided[0];
            obj.labels.unshift(format(new Date(yearOnly), "yyyy"));
            obj.datasets[0].data.unshift(item.net_revenue);
            obj.datasets[1].data.unshift(item.ebit);
            obj.datasets[2].data.unshift(item.operating_profit);
          });
          this.chartdata = obj;

          this.chartLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
