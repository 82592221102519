<template>
  <div v-if="profile">
    <v-row>
      <v-col lg="3" md="4" sm="12" v-if="profile">
        <div class="mb-4">
          <ProfileCard :profile="getUser" />
        </div>
        <div>
          <SubscriptionCard :profile="getUser" :menuButton="false" />
        </div>
      </v-col>
      <v-col lg="9" md="8" sm="12">
        <Account />
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.sticky {
  position: sticky;
  top: 70px;
  z-index: 3;
}
</style>
<script>
import Account from "@/components/account/Account";

import { mapGetters, mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import LeadCard from "@/components/leadcard/LeadCard";
import MenuBar from "@/components/navigation/MenuBar";
import InfinityLoader from "@/components/InfinityLoader";
import AddLead from "@/components/leadcard/AddLead";
import ReviewsDialog from "@/components/profile/ReviewsDialog";
import ProfileStatistics from "@/components/profile/ProfileStatistics.vue";
import ProfileCard from "@/components/profile/ProfileCard.vue";
import SubscriptionCard from "@/components/profile/SubscriptionCard.vue";
export default {
  name: "Profile",
  components: {
    MenuBar,
    LeadCard,
    InfinityLoader,
    ReviewsDialog,
    AddLead,
    ProfileStatistics,
    ProfileCard,
    SubscriptionCard,
    Account,
  },
  methods: {
    ...mapActions(["leadUpdate", "leadInActivate"]),
    getProfile() {
      axiosInstance
        .get(`${API_URL}api/users/user/${this.id}`)
        .then((response) => {
          this.profile = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapGetters(["isLoading", "getUser"]),
  },
  created() {},
  mounted() {
    this.getProfile();
  },
  beforeDestroy() {},
  data() {
    return {
      id: this.$route.params.id,
      profile: null,
      profileStatistics: null,

      attrs: {},

      routes: [
        {
          route: `/user/${this.$route.params.id}`,
          icon: "mdi-account-circle-outline",
        },
      ],
    };
  },
};
</script>
