<template>
  <v-dialog scrollable v-model="dialogEdit" max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-1" v-bind="attrs" v-on="on">
        <v-icon> mdi-pencil-outline </v-icon></v-btn
      >
    </template>
    <v-form
      @submit="onSubmit"
      v-model="valid"
      lazy-validation
      v-if="leadToEdit"
    >
      <v-card class="">
        <v-card-title>{{ $t("edit") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <v-list three-line subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("addLeadDialog.publicInformation") }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{
                    $t("addLeadDialog.publicInformationSubtitle")
                  }}</v-list-item-subtitle
                >

                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="leadToEdit.title"
                      :label="$t('addLeadDialog.title')"
                      outlined
                      persistent-hint
                      :hint="$t('addLeadDialog.titleHint')"
                      placeholder="Ex. Företag söker flyttfirma för kontorsflytt"
                      counter
                      maxlength="80"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      outlined
                      :label="$t('addLeadDialog.description')"
                      :hint="$t('addLeadDialog.descriptionHint')"
                      placeholder="Ex. Medelstort företag i Stockholm city brehöver hjälp med att flytta till större kontor p.g.a. expanderande verksamhet. Idag sitter företaget på en central adress och ska flytta till en av Stockholms kranskommuner."
                      auto-grow
                      counter
                      v-model="leadToEdit.body"
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    $t("addLeadDialog.criticalInforamtion")
                  }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{
                    $t("addLeadDialog.criticalInforamtionSubtitle")
                  }}</v-list-item-subtitle
                >
                <v-row class="mt-2">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.first_name"
                      :label="$t('addLeadDialog.fistName')"
                      outlined
                      persistent-hint
                      placeholder="John"
                      :hint="$t('addLeadDialog.fistNameHint')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.last_name"
                      :label="$t('addLeadDialog.lastName')"
                      outlined
                      persistent-hint
                      placeholder="Doe"
                      :hint="$t('addLeadDialog.lastNameHint')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.role"
                      :label="$t('addLeadDialog.role')"
                      outlined
                      persistent-hint
                      placeholder="Projektledare"
                      :hint="$t('addLeadDialog.roleHint')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.email"
                      :label="$t('addLeadDialog.email')"
                      outlined
                      persistent-hint
                      placeholder="john.doe@foretaget.com"
                      :hint="$t('addLeadDialog.emailHint')"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.phone"
                      :label="$t('addLeadDialog.phone')"
                      outlined
                      persistent-hint
                      placeholder="07312312312"
                      :hint="$t('addLeadDialog.phoneHint')"
                      :rules="phoneRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.linkedin"
                      :label="
                        $t('addLeadDialog.linkedin') +
                        ' (' +
                        $t('addLeadDialog.optional') +
                        ')'
                      "
                      outlined
                      persistent-hint
                      placeholder="https://linkedin.com/johndoesprofile"
                      :rules="webpageRules"
                      :hint="$t('addLeadDialog.linkedinHint')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.company.name"
                      :label="$t('addLeadDialog.company')"
                      outlined
                      persistent-hint
                      placeholder="Företaget AB"
                      :hint="$t('addLeadDialog.companyHint')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.company.company_url"
                      :label="
                        $t('addLeadDialog.companyWebpage') +
                        ' (' +
                        $t('addLeadDialog.optional') +
                        ')'
                      "
                      outlined
                      placeholder="https://foretaget.com"
                      persistent-hint
                      :hint="$t('addLeadDialog.companyWebpageHint')"
                      :rules="webpageRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="leadToEdit.contact_person.company.regno"
                      :label="$t('addLeadDialog.registrationNumber')"
                      outlined
                      persistent-hint
                      placeholder="556036-0793"
                      :hint="$t('addLeadDialog.registrationNumberHint')"
                      :rules="regNoRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="leadToEdit.contact_person.street"
                      :label="$t('addLeadDialog.street')"
                      outlined
                      persistent-hint
                      placeholder="Sveavägen 1"
                      :hint="$t('addLeadDialog.streetHint')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="leadToEdit.contact_person.city"
                      :label="$t('addLeadDialog.city')"
                      :hint="$t('addLeadDialog.cityHint')"
                      placeholder="Stockholm"
                      outlined
                      persistent-hint
                      :rules="cityRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      outlined
                      :label="$t('addLeadDialog.criticalInforamtion')"
                      :hint="$t('addLeadDialog.criticalInforamtionHint')"
                      placeholder="Ex. John har varit en kund till mig länge och behöver snabb och smidig hjälp. Du får gärna hälsa från mig när ni pratas vid. Han föredrar kontakt via telefon och du når honom antagligen lättast på förmiddagen."
                      auto-grow
                      counter
                      persistent-hint
                      v-model="leadToEdit.critical_info"
                    ></v-textarea>
                  </v-col>

                  <v-col md="4" offset-md="4" xs="12">
                    <v-text-field
                      v-model="leadToEdit.price"
                      :label="$t('addLeadDialog.price')"
                      :hint="$t('addLeadDialog.priceHint')"
                      outlined
                      persistent-hint
                      suffix="kr"
                      type="number"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogEdit = false">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            color="success"
            :disabled="!valid"
            :loading="isLoading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
} from "tiptap-vuetify";
export default {
  name: "EditDialog",
  components: { TiptapVuetify },
  props: ["item"],
  methods: {
    ...mapActions(["leadUpdate"]),

    onSubmit(event) {
      event.preventDefault();
      var payload = {
        id: this.item.id,
        title: this.leadToEdit.title,
        price: this.leadToEdit.price,
        body: this.leadToEdit.body,
        first_name: this.leadToEdit.first_name,
        last_name: this.leadToEdit.last_name,
        role: this.leadToEdit.role,
        email: this.leadToEdit.email,
        phone: this.leadToEdit.phone,
        linkedin: this.leadToEdit.linkedin,
        company_url: this.leadToEdit.company_url,
        regno: this.leadToEdit.regno,
        street: this.leadToEdit.street,
        postal_code: this.leadToEdit.postal_code,
        city: this.leadToEdit.city,
        critical_info: this.leadToEdit.critical_info,
      };

      this.leadUpdate(payload);
      this.dialogEdit = false;
    },
  },
  computed: {
    ...mapGetters(["myLeads", "isLoading"]),

    leadToEdit: function () {
      return this.myLeads.find((i) => i.id === this.item.id);
    },
  },
  created() {},

  data() {
    return {
      dialogEdit: false,
      valid: true,

      rules: {
        required: (value) => !!value || "Obligatorisk",
        min: (v) => (v && v.length >= 5) || "Min. 5 tecken",
      },
      emailRules: [
        (v) => !!v || "Obligatorisk",
        (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
      ],
      phoneRules: [
        (v) => !!v || "Obligatorisk",
        (v) =>
          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(v) ||
          "Telefonnumret måste vara korrekt",
      ],
      regNoRules: [
        (v) => !!v || "Obligatorisk",
        (v) =>
          /^(\d{6}(-\d{4})|\d{10})$/.test(v) ||
          "Reg. no. must be valid format NNNNNN-NNNN",
      ],
      postalCodeRules: [
        (v) => !!v || "Obligatorisk",
        (v) => /\d/.test(v) || "Postnumret kan bara innehålla siffror",
      ],
      cityRules: [
        (v) => !!v || "Obligatorisk",
        (v) => /[A-Za-z]/.test(v) || "Stadsnamnet kan bara innehålla bokstäver",
      ],
      webpageRules: [
        (v) =>
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v
          ) || "Hemsideadressen måste vara giltig",
      ],

      // declare extensions you want to use
      extensions: [
        Bold,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
      ],
      // starting editor's content
      content: "",
    };
  },
};
</script>
