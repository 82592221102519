import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
// const API_URL = "http://localhost:8000/";

const state = {
  swaps: [],
  offerings: [],
  pending: [],
  notifications: [],
  items: {
    todo: [],
    open: [],
    closed: [],
    unassigned: [],
  },
};

const getters = {
  getSwaps: (state) => state.swaps,
  getOfferings: (state) => state.offerings,
  getPending: (state) => state.pending,
  getNotifications: (state) => state.notifications,
  getWebPageTitle: (state) => state.webPageTitle,
};

const actions = {
  // fetchSwapLeads({ commit }) {
  //   commit("setLoading");
  //   axiosInstance
  //     .get(API_URL + "api/leads/swap-leads/")
  //     .then((response) => {
  //       commit("setSwaps", response.data);
  //       commit("unsetLoading");
  //     })
  //     .catch((error) => {
  // commit("setError", error);
  //       setTimeout(() => {
  //         commit("unsetError");
  //       }, 3000);

  //       commit("unsetLoading");
  //     });
  // },
  fetchOfferings({ commit }) {
    commit("setLoading");
    axiosInstance
      .get(API_URL + "api/leads/my-offerings/")
      .then((response) => {
        commit("setOfferings", response.data);
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  fetchPending({ commit }) {
    commit("setLoading");
    axiosInstance
      .get(API_URL + "api/leads/my-pending-offers/")
      .then((response) => {
        commit("setPendingOffers", response.data);
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  leadUpdateStatus({ commit, dispatch }, payload) {
    commit("setLoading");
    commit("updateItems", payload);
    axiosInstance
      .put(API_URL + "api/leads/update_status/", payload)
      .then((response) => {
        commit("changeLeadStatus", response.data.data);
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data.message,
        });
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  leadExchange({ commit }, payload) {
    commit("setLoading");
    axiosInstance
      .put(API_URL + "api/leads/make-exchange/", payload)
      .then((response) => {
        commit("makeExchange", response.data);
        console.log(response.data);
        commit("unsetLoading");
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
        });
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  fetchNotifications({ commit }) {
    commit("setLoading");
    axiosInstance
      .get(API_URL + "api/notifications/")
      .then((response) => {
        commit("setNotifications", response.data);
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);

        const errorHuman = {
          message: "Något gick fel när dina aviseringar skulle hämtas",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  clearNotification({ commit }, payload) {
    commit("setLoading");
    console.log(payload);
    axiosInstance
      .put(API_URL + "api/notifications/clear/", payload)
      .then((response) => {
        if (response.data.data.length === 1) {
          commit("clearNotification", response.data.data[0]);
        } else if (response.data.data.length > 1) {
          commit("clearAllNotifications");
        }
        commit("unsetLoading");
      })
      .catch((error) => {
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när du skulle rensa dina aviseringar",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
};

const mutations = {
  changeLeadStatus: (state, payload) => state,
  makeExchange: (state, payload) => {
    if (payload.data.offering) {
      let i = state.offerings.map((item) => item.id).indexOf(payload.data.id); // find index of your object
      return state.offerings.splice(i, 1); // remove it from array
    } else {
      let i = state.pending.map((item) => item.id).indexOf(payload.data.id); // find index of your object
      return state.pending.splice(i, 1); // remove it from array
    }
  },
  // setOwnedLeads: (state, leads) => {
  //   state.owned = leads;
  //   state.items.todo = [];
  //   state.items.open = [];
  //   state.items.closed = [];

  //   state.owned.forEach((lead) => {
  //     if (lead.status === "todo") {
  //       state.items.todo = [...state.items.todo, lead];
  //     } else if (lead.status === "open") {
  //       state.items.open = [...state.items.open, lead];
  //     } else if (lead.status === "closed") {
  //       state.items.closed = [...state.items.closed, lead];
  //     } else {
  //       state.items.unassigned = [...state.items.unassigned, lead];
  //     }
  //   });
  // },
  setSwaps: (state, leads) => (state.swaps = leads),
  // addItem(state, item) {
  //   state.items.todo.push(Object.assign(item));
  // },
  setOfferings: (state, offer) => (state.offerings = offer),

  setNotifications: (state, notifications) =>
    (state.notifications = notifications),
  clearNotification: (state, notification) => {
    const id = notification.id;
    const i = state.notifications.map((item) => item.id).indexOf(id);
    state.notifications.splice(i, 1);
  },
  clearAllNotifications: (state) => (state.notifications = []),
  setPendingOffers: (state, offer) => (state.pending = offer),

  updateItems(state, { items, id }) {
    state.items[id] = items;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
