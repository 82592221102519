<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="" class="ma-2" v-bind="attrs" v-on="on">
        Dina inbjudningar</v-btn
      >
    </template>

    <v-card>
      <v-card-title> Dina inbjudningar</v-card-title>
      <v-card-text
        >Lista över lyckade inbjudningar

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Email</th>
                <th class="text-left">Joinade</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUser.invitations" :key="item.id">
                <td>{{ item.email }}</td>
                <td>
                  {{ new Date(item.date_joined) | date("yyyy-MM-dd") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">{{
          $t("close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { dateFilter, format } from "vue-date-fns";
export default {
  name: "CancelSubscriptionDialog",
  filters: {
    date: dateFilter,
  },
  computed: { ...mapGetters(["getUser"]) },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.check = null;
    },
  },
  data() {
    return {
      dialog: false,
      check: null,
      loading: false,
    };
  },
};
</script>

<style></style>
