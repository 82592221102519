<template>
  <div fluid v-if="!isLoading">
    <v-btn class="mb-2" @click="$router.go(-1)">
      <v-icon large dark>mdi-chevron-left</v-icon>
    </v-btn>
    <v-row>
      <v-col md="12" xs="12">
        <v-card class="pa-4" outlined>
          <v-row>
            <v-col md="8">
              <div class="caption grey-text d-flex justify-end">
                {{ new Date(lead.created_at) | date("dd MMMM yyyy") }}
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  <v-chip
                    class="mr-1 mb-1"
                    v-for="tag in lead.tags"
                    :key="tag.slug"
                    color="primary"
                    small
                  >
                    <v-icon x-small left> mdi-tag-outline </v-icon
                    >{{ tag.name }}</v-chip
                  >
                </div>
                <div v-if="lead.price && !lead.owner" class="lead-price">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" color="success" label>
                        <v-icon small>mdi-check-circle-outline</v-icon>
                        {{ leadTotalPrice }} kr
                      </v-chip>
                    </template>
                    <div class="text-center">
                      <v-chip dark color="" label outlined>
                        <v-icon small>mdi-content-cut</v-icon>
                        {{ leadFee }} kr
                      </v-chip>
                      <br />
                      <span> Inklusive betald förmedlingsavgift</span> <br />
                      <span v-if="!getUser.is_premium"
                        >Skaffa
                        <v-icon small color="warning"> mdi-license </v-icon
                        >Premium för att sänka avgiften</span
                      >
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <v-card-title
                style="word-break: break-word"
                class="d-flex justify-space-between pl-0 pb-1 pt-1"
              >
                <h3>{{ lead.title }}</h3>
              </v-card-title>

              <div v-html="lead.body" class="lead-detail-text-area mb-1"></div>
              <v-chip
                class="mr-1 mb-1"
                v-for="sni in lead.contact_person.company.sni"
                :key="sni.code"
                color=""
                outlined
                small
              >
                <v-icon x-small left> mdi-information-outline </v-icon>
                {{ sni.description }}</v-chip
              >
              <div>
                <ReportAbuseDialog :item="lead" />
              </div>
            </v-col>

            <v-col md="4" xs="12" v-if="!lead.hidden_creator">
              <div class="d-flex-column text-center">
                <v-badge
                  overlap
                  :value="lead.created_by.is_premium"
                  offset-x="25"
                  offset-y="25"
                  icon="mdi-license"
                  color="warning"
                >
                  <v-avatar
                    v-if="lead.created_by.avatar"
                    class="secondary"
                    size="80"
                  >
                    <v-img :src="imgPrefix + lead.created_by.avatar"></v-img>
                  </v-avatar>

                  <v-avatar
                    color="secondary"
                    v-if="!lead.created_by.avatar"
                    size="102"
                  >
                    <span class="text-h2">{{ lead.created_by.initials }}</span>
                  </v-avatar>
                </v-badge>

                <div v-if="lead.created_by" class="text-capitalize text-h6">
                  {{ lead.created_by.first_name }}
                  {{ lead.created_by.last_name }}
                  <v-icon v-show="lead.created_by.linkedin_profile"
                    >mdi-linkedin</v-icon
                  >
                </div>
                <div>
                  {{ lead.created_by.role }} {{ $t("profile.at") }}
                  <v-btn
                    class="text-capitalize"
                    small
                    text
                    outlined
                    color="primary"
                    :href="lead.created_by.company_url"
                    target="_blank"
                    :disabled="!lead.created_by.company_url"
                    >{{ lead.created_by.company }}</v-btn
                  >
                </div>

                <ReviewsDialog :profile="lead.created_by" />
                <div>
                  <v-card class="pa-2" outlined>
                    <span class="text-caption">{{
                      lead.created_by.summary
                    }}</span>
                  </v-card>
                </div>
                <div class="mt-2" v-if="lead.user_reference">
                  <v-icon class="mr-1">mdi-bullhorn-outline</v-icon>Använd mig
                  gärna som referens
                </div>
              </div>
            </v-col>

            <v-col md="4" xs="12" v-if="lead.hidden_creator">
              <div class="d-flex-column text-center">
                <v-badge
                  overlap
                  :value="lead.created_by.is_premium"
                  offset-x="25"
                  offset-y="25"
                  icon="mdi-license"
                  color="warning"
                >
                  <v-avatar color="grey" size="102">
                    <span class="text-h2">{{ lead.created_by.initials }}</span>
                  </v-avatar>
                </v-badge>

                <div v-if="lead.created_by" class="text-capitalize text-h6">
                  Dold profil
                </div>

                <ReviewsDialog :profile="lead.created_by" />
                <div>
                  <v-card class="pa-2" outlined>
                    <span class="text-caption">Dold bio</span>
                  </v-card>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col v-if="lead.owner && lead.owner.id == getUser.id" class="pt-0">
        <v-card class="mt-2">
          <v-row>
            <v-col md="6" cols="12">
              <v-card-text>
                <div>
                  <strong>{{ $t("leadDetail.rating") }}</strong>
                </div>

                <div class="text--primary">
                  {{ $t("leadDetail.ratingText") }}
                </div>

                <v-rating
                  v-model="rating"
                  color="warning"
                  length="5"
                  hover
                  dense
                  size="30"
                  @input="handleRating"
                ></v-rating>
              </v-card-text>
            </v-col>
            <v-col md="6" cols="12">
              <v-card-text>
                <div>
                  <strong>{{ $t("leadDetail.review") }}</strong>
                </div>

                <v-textarea
                  outlined
                  v-model="leadReview"
                  :label="`${$t('leadDetail.leaveComment')}`"
                  :placeholder="$t('leadDetail.leaveCommentExample')"
                  class="mt-2"
                  @blur="handleRating"
                ></v-textarea>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="lead.contact_person.email">
      <LeadCritical :lead="lead" />
    </div>
    <div v-else>
      <LeadLocked :lead="lead" @leadWasPurchased="refreshCard" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import { createDateFilter } from "vue-date-fns";
import LeadCritical from "./LeadCritical";
import LeadLocked from "./LeadLocked.vue";
import LeadBidding from "./LeadBidding.vue";
import ReportAbuseDialog from "./ReportAbuseDialog.vue";
import ProfileCard from "@/components/profile/ProfileCard";
import ProfileStatistics from "@/components/profile/ProfileStatistics";
import ReviewsDialog from "@/components/profile/ReviewsDialog";
import locale from "date-fns/locale/sv";
export default {
  name: "LeadDetail",
  components: {
    LeadCritical,
    LeadLocked,
    LeadBidding,
    ProfileCard,
    ProfileStatistics,
    ReviewsDialog,
    ReportAbuseDialog,
  },
  filters: {
    date: createDateFilter("dd MMMM yyyy", { locale }),
  },
  methods: {
    ...mapActions([
      "fetchMyLeads",
      "leadLike",
      "leadOffer",
      "leadRate",
      "fetchFinancial",
      "leadInstantBuy",
      "fetchUserAccount",
      "responseStatus",
    ]),

    profileHasSubscription(user) {
      if (user.subscription === null) return false;
      if (
        user.subscription &&
        new Date(user.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },

    refreshCard() {
      console.log(this.lead.slug);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    getLead(slug) {
      axiosInstance
        .get(`${API_URL}api/leads/lead/${slug}/`)
        .then((response) => {
          const data = response.data.data;
          this.lead = data;
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    handleLeadOffer: function () {
      var payload = {
        id: this.lead.id,
        user: this.getUser.id,
        offered: this.selectedLeads,
      };

      axiosInstance
        .post(`${API_URL}api/leads/offer/`, payload)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
          });
        })
        .catch((error) => {
          console.error(error);
        });

      this.$router.push("/pending/").catch(() => {});
    },
    handleRating: function () {
      var payload = {
        id: this.lead.id,
        rating: this.lead.rating,
        review: this.leadReview,
      };
      this.leadRate(payload);
    },
    handleInstantBuy: function () {
      var payload = {
        id: this.lead.id,
      };
      this.leadInstantBuy(payload);
      this.fetchUserAccount();
    },

    onCopy: function (e) {
      this.shareCopyLabel = "Link copied!";
      this.shareCopyColor = "success";

      setTimeout(() => {
        this.shareCopyColor = "primary";
        this.shareCopyLabel = "Share link";
      }, 500);
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
  },
  computed: {
    ...mapGetters(["myLeads", "getUser", "isLoading"]),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    leadFee() {
      const fee = this.lead.price * this.getUser.courtage;

      return parseFloat(fee).toFixed(2);
    },

    leadTotalPrice() {
      if (this.lead.created_by.id === this.getUser.id) {
        const fee = this.lead.price * this.getUser.courtage;

        return parseFloat(this.lead.price - fee).toFixed(2);
      } else {
        const fee = this.lead.price * this.getUser.courtage;

        return parseFloat(this.lead.price + fee).toFixed(2);
      }
    },

    createdByOrOwner: function () {
      if (this.lead.created_by?.id === this.getUser.id) {
        return true;
      }
      if (this.lead.owner?.id === this.getUser.id) {
        return true;
      }
      return false;
    },

    rating: {
      get() {
        return this.lead.rating;
      },
      set(newValue) {
        this.lead.rating = newValue;
      },
    },
    leadReview: {
      get() {
        return this.lead.review;
      },
      set(newValue) {
        this.lead.review = newValue;
      },
    },
  },
  created() {
    this.fetchMyLeads();
    // this.fetchLead(this.slug);
    this.getLead(this.slug);
  },
  watch: {
    // whenever lead changes, this function will run
    lead(newLead, oldLead) {
      if (newLead.title !== undefined) {
        document.title = `Zwop | ${newLead.title}`;
      }
    },
  },

  mounted() {
    this.scrollToTop();
  },
  destroyed() {},

  data() {
    return {
      slug: this.$route.params.slug,

      lead: {
        contact_person: {
          company: {},
        },
        owner: null,
        likes: [],
        is_verified: false,
        is_premium: false,
        is_active: false,
        user_reference: false,
        created_by: {
          id: "",
          avatar: null,
          city: "",
          company: "",
          company_url: null,
          email: "",
          email_verified: false,
          first_name: "",
          last_name: "",
          linkedin: null,
          phone: "",
          phone_verified: false,
          role: "",
          subscription: null,
          summary: "",
          rating: null,
        },
        created_at: null,
        tags: [],
        status: "",
        review: null,
      },
      shareCopyLabel: "Share link",
      shareCopyColor: "primary",

      selectedLeads: [],
      attrs: {},
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    };
  },
};
</script>

<style>
.lead-detail-text-area p {
  margin-bottom: 5px;
}

.lead-price {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
