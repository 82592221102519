<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <div class="ma-4">
        <v-alert
          text
          prominent
          :type="type"
          icon="mdi-shield-account-outline"
          v-if="!mobile"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ $t("verifyPhoneText") }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click="dialog = true"
                color="success"
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
                v-show="showButton"
              >
                {{ $t("verifyPhone") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert
          text
          dense
          type="error"
          icon="mdi-shield-account-outline"
          v-if="mobile"
        >
          {{ $t("verifyPhoneText") }}
          <v-btn
            small
            @click="dialog = true"
            color="success"
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            v-show="showButton"
          >
            {{ $t("verifyPhone") }}
          </v-btn></v-alert
        >
      </div>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("verifyPhone") }}
      </v-card-title>
      <v-card-text>
        <v-btn
          @click="sendSmsCodeToUser"
          block
          color="success"
          :disabled="disableSmsCodeBtn"
          class="my-2"
        >
          Skicka SMS-kod
        </v-btn>
        <p>
          {{ $t("verifyPhoneInfo") }}. Ditt telefonnummer är {{ this.phone }}
        </p>
        <v-text-field
          v-model="smsCode"
          :label="$t('verifyPhoneLabel')"
          maxlength="6"
          outlined
          autofocus
          :rules="[rules.required]"
        >
        </v-text-field
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="dialog = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="success" @click="submitSmsCode">
          {{ $t("verify") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "VerifyPhone",
  props: ["phone", "disabled"],
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions(["responseStatus"]),
    sendSmsCodeToUser() {
      if (this.phone === null) return;
      const payload = {
        phone_number: this.phone,
      };

      this.disableSmsCodeBtn = true;
      axiosInstance
        .post(`${API_URL}api/users/send_sms_verification/`, payload)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
          });

          setTimeout(() => {
            this.disableSmsCodeBtn = false;
          }, 6000);
          return response;
        })
        .catch((error) => {
          this.disableSmsCodeBtn = false;

          console.error(error);
        });
    },
    submitSmsCode() {
      if (this.smsCode === null) return;
      if (this.phone === null) return;
      const payload = {
        sms_code: this.smsCode,
        phone_number: this.phone,
      };
      axiosInstance
        .post(`${API_URL}api/users/check_sms_verification/`, payload)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
          });
          this.showButton = false;
          this.type = "success";
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
      this.dialog = false;
    },
  },

  data() {
    return {
      dialog: false,
      disableSmsCodeBtn: false,
      showButton: true,
      type: "error",
      smsCode: null,
      rules: {
        required: (value) => !!value || "Obligatorisk",
      },
    };
  },
};
</script>

<style></style>
