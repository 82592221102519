<template>
  <v-card class="pa-4" outlined>
    <v-row>
      <v-col md="6" sm="12">
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title class="text-h6"
              >{{ $t("leadDetail.leadingBid") }}
              {{ $t("leadDetail.startingPrice") }}</v-list-item-title
            >

            <v-list-item-subtitle class="text-right text-h3">
              {{ price }} kr
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{
                $t("leadDetail.deadline")
              }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ remainingDays }} days {{ remainingHours }} hours
                {{ remainingMinutes }} minutes {{ remainingSeconds }} seconds
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{
                new Date(lead.auction.expire_datetime).toLocaleString()
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="d-flex flex-column">
          <v-text-field
            placeholder="Lägg 305 kr eller mer..."
            suffix="kr"
            outlined
          ></v-text-field>
          <v-btn color="success">{{ $t("leadDetail.placeBid") }}</v-btn>
        </div>
      </v-col>
      <v-col md="6" sm="12">
        <v-simple-table dense fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("leadDetail.bidder") }}
                </th>
                <th class="text-left">
                  {{ $t("leadDetail.amount") }}
                </th>
                <th class="text-left">
                  {{ $t("leadDetail.date") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in bids" :key="item.id">
                <td>{{ item.bidder }}</td>
                <td>{{ item.amount }} kr</td>
                <td>{{ item.date }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatDuration } from "vue-date-fns";

export default {
  props: ["lead"],
  methods: {
    getTimeRemaining(endtime) {
      const total = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      this.remainingDays = days;
      this.remainingHours = hours;
      this.remainingMinutes = minutes;
      this.remainingSeconds = seconds;

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },
  },
  computed: {
    price() {
      console.log(this.lead.auction.bids);
      if (this.lead.auction.bids.length > 0) {
        return 0;
      }
      return this.lead.auction.starting_price;
    },
  },
  created() {
    this.getTimeRemaining(this.lead.auction.expire_datetime);
  },
  data() {
    return {
      remainingDays: 0,
      remainingHours: 0,
      remainingMinutes: 0,
      remainingSeconds: 0,
      bids: [
        { id: 1, amount: 300 },
        { id: 2, amount: 435 },
        { id: 3, amount: 535 },
        { id: 4, amount: 635 },
        { id: 5, amount: 735 },
        { id: 6, amount: 435 },
        { id: 7, amount: 535 },
        { id: 8, amount: 635 },
        { id: 9, amount: 735 },
      ],
    };
  },
};
</script>

<style></style>
