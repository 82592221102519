var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-2 align-center justify-space-between"},[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.$t("account.invoices")))])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getInvoices,"items-per-page":10,"search":_vm.search,"sort-desc":[false, true],"item-key":"timestamp","multi-sort":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseAmount(item.amount))+" kr ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.paid),callback:function ($$v) {_vm.$set(item, "paid", $$v)},expression:"item.paid"}})]}},{key:"item.hosted_invoice_url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","disabled":!item.hosted_invoice_url,"href":item.hosted_invoice_url,"target":"_blank"}},[_vm._v("Faktura")])]}},{key:"item.receipt_url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","disabled":!item.receipt_url,"href":item.receipt_url,"target":"_blank"}},[_vm._v("Kvitto")])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.timestamp),"yyyy-MM-dd HH:mm")))])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("account.noInvoices"))+" ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }