<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" class="ml-1 mr-1" dark @click="dialog = !dialog">
        <div class="white--text">
          <v-icon left large v-if="!editMode">mdi-plus-circle</v-icon> Skapa
          leadkort
        </div>

        <v-icon v-if="editMode"> mdi-pencil-outline </v-icon>
      </v-btn>
    </template>
    <v-form @submit="onSubmit" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>{{ $t("addLeadDialog.addLead") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px"
          ><div class="">
            <BankIDVerifyDialog
              :user="getUser"
              v-if="!getUser.personal_identity_number_verified"
            />
          </div>
          <v-list three-line subheader>
            <div v-blur="!getUser.personal_identity_number_verified">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      $t("addLeadDialog.publicInformation")
                    }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    {{
                      $t("addLeadDialog.publicInformationSubtitle")
                    }}</v-list-item-subtitle
                  >

                  <v-row class="mt-2">
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="title"
                        :label="$t('addLeadDialog.title')"
                        outlined
                        persistent-hint
                        :hint="$t('addLeadDialog.titleHint')"
                        placeholder="Ex. Företag söker flyttfirma för kontorsflytt"
                        counter
                        maxlength="80"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-combobox
                        v-model="tags"
                        :items="availableTags"
                        :search-input.sync="search"
                        clearable
                        :hint="$t('addLeadDialog.hintMaxTags')"
                        :label="$t('addLeadDialog.tags')"
                        multiple
                        small-chips
                        outlined
                        persistent-hint
                        :rules="[rules.required]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Taggen finns inte "<strong>{{ search }}</strong
                                >". Tryck <kbd>Enter</kbd> för att skapa en helt
                                ny tagg
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="6" md="4">
                      <v-select
                        :items="types"
                        :label="$t('addLeadDialog.type')"
                        :hint="$t('addLeadDialog.typeHint')"
                        v-model="type"
                        outlined
                        persistent-hint
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" md="4">
                      <v-select
                        :items="locations"
                        :label="$t('addLeadDialog.location')"
                        :hint="$t('addLeadDialog.locationHint')"
                        v-model="location"
                        outlined
                        persistent-hint
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" md="4">
                      <v-select
                        :items="phases"
                        :label="$t('addLeadDialog.phase')"
                        :hint="$t('addLeadDialog.phaseHint')"
                        v-model="phase"
                        outlined
                        persistent-hint
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        outlined
                        :label="$t('addLeadDialog.description')"
                        :hint="$t('addLeadDialog.descriptionHint')"
                        placeholder="Ex. Medelstort företag i Stockholm city brehöver hjälp med att flytta till större kontor p.g.a. expanderande verksamhet. Idag sitter företaget på en central adress och ska flytta till en av Stockholms kranskommuner. Om du har en uppfattning om värdet på den potenitiella affären nämn det här!"
                        auto-grow
                        counter
                        v-model="body"
                        persistent-hint
                      ></v-textarea>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12" md="12">
                      <v-switch
                        v-model="reference"
                        :disabled="hidden_creator"
                        :label="$t('addLeadDialog.reference')"
                        :hint="$t('addLeadDialog.referenceHint')"
                        persistent-hint
                      ></v-switch>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="12" md="12">
                      <v-switch
                        v-model="hidden_creator"
                        :disabled="!getUser.is_premium || reference"
                        :hint="`Du kan välja att inte visa att det är du som lagt upp detta leadkort.`"
                        persistent-hint
                      >
                        <template slot="label">
                          Dölj min profil
                          <v-icon color="warning"> mdi-license </v-icon>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      $t("addLeadDialog.criticalInforamtion")
                    }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    {{ $t("addLeadDialog.criticalInforamtionSubtitle") }}
                    <strong>{{
                      $t("addLeadDialog.criticalInforamtionSubtitle2")
                    }}</strong>
                  </v-list-item-subtitle>
                  <v-row class="mt-2">
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="first_name"
                        :label="$t('addLeadDialog.fistName')"
                        outlined
                        persistent-hint
                        placeholder="John"
                        :hint="$t('addLeadDialog.fistNameHint')"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="last_name"
                        :label="$t('addLeadDialog.lastName')"
                        outlined
                        persistent-hint
                        placeholder="Doe"
                        :hint="$t('addLeadDialog.lastNameHint')"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="role"
                        :label="$t('addLeadDialog.role')"
                        outlined
                        persistent-hint
                        placeholder="Projektledare"
                        :hint="$t('addLeadDialog.roleHint')"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="email"
                        :label="$t('addLeadDialog.email')"
                        outlined
                        persistent-hint
                        placeholder="john.doe@foretaget.com"
                        :hint="$t('addLeadDialog.emailHint')"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="phone"
                        :label="$t('addLeadDialog.phone')"
                        outlined
                        persistent-hint
                        placeholder="07312312312"
                        :hint="$t('addLeadDialog.phoneHint')"
                        :rules="phoneRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="linkedin"
                        :label="
                          $t('addLeadDialog.linkedin') +
                          ' (' +
                          $t('addLeadDialog.optional') +
                          ')'
                        "
                        outlined
                        persistent-hint
                        placeholder="https://linkedin.com/johndoesprofile"
                        :rules="webpageRules"
                        :hint="$t('addLeadDialog.linkedinHint')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="company"
                        :label="$t('addLeadDialog.company')"
                        outlined
                        persistent-hint
                        placeholder="Företaget AB"
                        :hint="$t('addLeadDialog.companyHint')"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="company_url"
                        :label="
                          $t('addLeadDialog.companyWebpage') +
                          ' (' +
                          $t('addLeadDialog.optional') +
                          ')'
                        "
                        outlined
                        placeholder="https://foretaget.com"
                        persistent-hint
                        :hint="$t('addLeadDialog.companyWebpageHint')"
                        :rules="webpageRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="regno"
                        :label="$t('addLeadDialog.registrationNumber')"
                        outlined
                        persistent-hint
                        placeholder="556036-0793"
                        :hint="$t('addLeadDialog.registrationNumberHint')"
                        :rules="regNoRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="company_linkedin_page"
                        :label="
                          $t('addLeadDialog.companyPageLinkedin') +
                          ' (' +
                          $t('addLeadDialog.optional') +
                          ')'
                        "
                        outlined
                        persistent-hint
                        placeholder="https://linkedin.com/foretagsnamn/about/"
                        :hint="$t('addLeadDialog.companyPageLinkedinHint')"
                        :rules="webpageRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="street"
                        :label="$t('addLeadDialog.street')"
                        outlined
                        persistent-hint
                        placeholder="Sveavägen 1"
                        :hint="$t('addLeadDialog.streetHint')"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="city"
                        :label="$t('addLeadDialog.city')"
                        :hint="$t('addLeadDialog.cityHint')"
                        placeholder="Stockholm"
                        outlined
                        persistent-hint
                        :rules="cityRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-alert text type="error" v-if="showAlertForSimilarText">
                        Din <em>gömda information</em> verkar vara väldigt lik
                        den <em>publika beskrivningen</em>. Det är smart att
                        uppge lite mer information som kan vara till nytta för
                        den som köper ditt leadkort?
                      </v-alert>
                      <v-textarea
                        outlined
                        :label="$t('addLeadDialog.criticalInforamtion')"
                        :hint="$t('addLeadDialog.criticalInforamtionHint')"
                        placeholder="Ex. John har varit en kund till mig länge och behöver snabb och smidig hjälp. Du får gärna hälsa från mig när ni pratas vid. Han föredrar kontakt via telefon och du når honom antagligen lättast på förmiddagen."
                        auto-grow
                        counter
                        persistent-hint
                        v-model="critical_info"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <div class="text-center text-h6">
                        <div v-if="allFieldsGotInformation">
                          {{ $t("addLeadDialog.priceRangeInfo") }}
                        </div>
                        <div v-if="!allFieldsGotInformation">
                          {{ $t("addLeadDialog.moreInfoIsNeeded") }}
                        </div>
                        <div
                          class="text"
                          v-if="
                            !loadingRandomPrice &&
                            valid &&
                            allFieldsGotInformation
                          "
                        >
                          {{ recommendPrice.from }} - {{ recommendPrice.to }} kr
                        </div>
                        <div v-else class="d-flex justify-center align-center">
                          <v-skeleton-loader type="chip"></v-skeleton-loader>
                        </div>
                        <span v-if="allFieldsGotInformation" class="caption">
                          (
                          {{
                            $t("addLeadDialog.priceRangeInfoSecondary")
                          }})</span
                        >
                      </div>
                    </v-col>

                    <v-col md="4" offset-md="4" xs="12">
                      <v-text-field
                        v-model="price"
                        :label="$t('addLeadDialog.price')"
                        :hint="$t('addLeadDialog.priceHint')"
                        outlined
                        persistent-hint
                        suffix="kr"
                        type="number"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-center text-caption">
                      <v-alert text dense class="text-center text-caption">
                        Vid försäljning kommer en förmedlingsavgift om
                        {{ getUser.courtage * 100 }}% debiteras.
                        <span v-show="!getUser.is_premium"
                          >Du kan halvera avgiften genom att skaffa
                          <v-icon color="warning"> mdi-license </v-icon
                          >Premium.</span
                        ></v-alert
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <!-- <v-btn text @click="saveDraft">
            {{ $t("saveDraft") }}
          </v-btn> -->

          <v-btn
            :disabled="!valid || !getUser.personal_identity_number_verified"
            :loading="isLoading"
            type="submit"
            color="success"
          >
            {{ $t("addNewLead") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BankIDVerifyDialog from "./BankIDVerifyDialog";

import stringSimilarity from "string-similarity";

export default {
  name: "AddLead",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    leadToEdit: {},
  },

  components: { BankIDVerifyDialog },
  data: () => ({
    dialog: false,
    loadingRandomPrice: false,
    valid: true,
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    rules: {
      required: (value) => !!value || "Obligatorisk",
      min: (v) => (v && v.length >= 5) || "Min. 5 tecken",
    },
    formErrors: (value) => false,
    emailRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
    ],
    phoneRules: [
      (v) => !!v || "Obligatorisk",
      (v) =>
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(v) ||
        "Telefonnumret måste vara korrekt",
    ],
    regNoRules: [
      (v) => !!v || "Obligatorisk",
      (v) =>
        /^(\d{6}(-\d{4})|\d{10})$/.test(v) ||
        "Organisationnumret måste ha formatet XXXXXX-XXXX",
    ],
    postalCodeRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /\d/.test(v) || "Postnumret kan bara innehålla siffror",
    ],
    cityRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /[A-Za-z]/.test(v) || "Stadsnamnet kan bara innehålla bokstäver",
    ],
    webpageRules: [
      (v) =>
        /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
          v
        ) || "Hemsideadressen måste vara giltig",
    ],
    search: "",

    types: ["Hårdvara", "Mjukvara", "Tjänst"],
    locations: [
      "Hela Sverige",
      "Blekinge",
      "Dalarna",
      "Gotland",
      "Gävleborg",
      "Halland",
      "Jämtland",
      "Jönköping",
      "Kalmar",
      "Kronoberg",
      "Norrbotten",
      "Skåne",
      "Stockholm",
      "Södermanland",
      "Uppsala",
      "Värmland",
      "Västerbotten",
      "Västernorrland",
      "Västmanland",
      "Västra Götaland",
      "Örebro",
      "Östergötland",
    ],

    phases: ["Direkt", "6-12 månader", "1- år"],

    title: "",
    tags: [],
    price: "",
    type: "",
    location: "",
    phase: "",
    body: "",
    regno: "",
    email: "",
    reference: false,
    hidden_creator: false,

    first_name: "",
    last_name: "",
    role: "",
    phone: "",
    linkedin: "",
    company: "",
    company_url: "",
    company_linkedin_page: "",
    street: "",
    postal_code: "",
    city: "",
    critical_info: "",
  }),
  watch: {
    tags(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.tags.pop());
      }
    },
  },
  methods: {
    ...mapActions(["addLead", "fetchTags", "responseError"]),
    toggleBlur() {
      this.blurConfig.isBlurred = !this.blurConfig.isBlurred;
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
      }
    },
    next() {
      this.e1 = 2;
      this.formErrors(false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onClose() {
      this.dialog = false;
      this.e1 = 1;
      this.title = "";
      this.tags = [];
      this.price = "";
      this.type = "";
      this.location = "";
      this.phase = "";
      this.body = "";
      this.regno = "";
      this.email = "";
      this.reference = false;
      this.hidden_creator = false;
      this.first_name = "";
      this.last_name = "";
      this.role = "";
      this.phone = "";
      this.linkedin = "";
      this.company = "";
      this.company_url = "";
      this.company_linkedin_page = "";
      this.street = "";
      this.postal_code = "";
      this.city = "";
      this.critical_info = "";
      this.isAuction = false;
      this.starting_price = "";
      this.auction_days = "";
    },
    saveDraft() {
      var payload = {
        title: this.title || "DRAFT",
        tags: this.tags,
        price: this.price || 0,
        type: this.type || "",
        location: this.location || "",
        phase: this.phase || "",
        body: this.body || "DRAFT",
        reference: this.reference,
        hidden_creator: this.hidden_creator,
        first_name: this.first_name,
        last_name: this.last_name,
        role: this.role,
        email: this.email,
        phone: this.phone,
        linkedin: this.linkedin,
        company: this.company,
        company_url: this.company_url,
        regno: this.regno,
        street: this.street,
        postal_code: this.postal_code,
        city: this.city,
        critical_info: this.critical_info,
        // is_auction: this.isAuction,
        // starting_price: this.starting_price,
        // auction_days: this.auction_days,
        is_draft: true,
      };

      this.addLead(payload);
    },
    onSubmit(event) {
      event.preventDefault();

      if (
        this.title === "" ||
        this.tags === [] ||
        this.price === "" ||
        this.type === "" ||
        this.location === "" ||
        this.phase === "" ||
        this.body === "" ||
        this.email === "" ||
        this.first_name === "" ||
        this.last_name === "" ||
        this.role === "" ||
        this.phone === "" ||
        this.company === "" ||
        this.street === "" ||
        this.street === "" ||
        this.critical_info === ""
      ) {
        const error = {
          message:
            "Du har missat att fylla i vissa fält. I princip alla är obligatoriska.",
        };

        this.responseError(error);
        return;
      }

      var payload = {
        title: this.title,
        tags: this.tags,
        price: this.price,
        type: this.type,
        location: this.location,
        phase: this.phase,
        body: this.body,
        reference: this.reference,
        hidden_creator: this.hidden_creator,
        first_name: this.first_name,
        last_name: this.last_name,
        role: this.role,
        email: this.email,
        phone: this.phone,
        linkedin: this.linkedin,
        company: this.company,
        company_url: this.company_url,
        company_linkedin_page: this.company_linkedin_page,
        regno: this.regno,
        street: this.street,
        postal_code: this.postal_code,
        city: this.city,
        critical_info: this.critical_info,
        // is_auction: this.isAuction,
        // starting_price: this.starting_price,
        // auction_days: this.auction_days,
        is_draft: false,
      };

      this.addLead(payload);
      // this.onClose();
    },

    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
    },
  },
  computed: {
    ...mapGetters(["allTags", "isLoading", "getError", "getUser"]),
    // title: this.editMode ? this.leadToEdit.title : "",
    //     tags: this.editMode ? this.leadToEdit.tags : [],
    //     price: this.editMode ? this.leadToEdit.price : "",
    //     type: this.editMode ? this.leadToEdit.type : "",
    //     location: this.editMode ? this.leadToEdit.location : "",
    //     phase: this.editMode ? this.leadToEdit.phase : "",
    //     body: this.editMode ? this.leadToEdit.body : "",
    // regno: this.editMode ? this.leadToEdit.regno : "",
    // email: this.editMode ? this.leadToEdit.email : "",
    // reference: this.editMode ? this.leadToEdit.reference : false,
    // hidden_creator: this.editMode ? this.leadToEdit.hidden_creator : false,
    leadFee() {
      const fee = this.price * this.getUser.courtage;

      return Math.round(fee);
    },

    showAlertForSimilarText() {
      if (this.critical_info === "") return false;
      if (this.body === "") return false;

      const BOUNDARY = 0.7;

      let similarity = stringSimilarity.compareTwoStrings(
        this.critical_info,
        this.body
      );

      console.log(similarity);

      if (similarity >= BOUNDARY) {
        return true;
      } else {
        return false;
      }
    },

    userHasSubscription() {
      if (this.getUser.subscription === null) return false;
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },

    availableTags() {
      let tagsList = [];
      this.allTags.forEach((element) => {
        tagsList.push(element.name);
      });
      return tagsList;
    },
    allFieldsGotInformation() {
      if (
        this.title.length > 10 &&
        this.tags.length >= 1 &&
        this.type.length > 1 &&
        this.location.length > 1 &&
        this.phase.length > 1 &&
        this.body.toString().length > 100 &&
        this.regno.length > 8 &&
        this.email.length > 3 &&
        this.first_name.length > 1 &&
        this.last_name.length > 1 &&
        this.role.length > 1 &&
        this.phone.length > 8 &&
        this.company.length > 1 &&
        this.street.length > 2 &&
        this.city.length > 1 &&
        this.critical_info.toString().length > 100
      ) {
        return true;
      }
      return false;
    },

    recommendPrice() {
      this.loadingRandomPrice = true;
      let factor = 1.0;
      const BASE_PRICE = 45;
      const randomPrice = this.getRandomIntInclusive(
        BASE_PRICE,
        BASE_PRICE + 20
      );

      if (this.tags.length > 3) {
        factor += 0.2;
      }
      if (this.body.toString().length > 150) {
        factor += 0.1;
      }
      if (this.body.toString().length > 200) {
        factor += 0.1;
      }
      if (this.body.toString().length > 300) {
        factor += 0.2;
      }
      if (this.phase === "Instant") {
        factor += 0.1;
      }
      if (this.location === "Stockholm") {
        factor += 0.3;
      }
      if (this.critical_info.toString().length > 200) {
        factor += 0.2;
      }
      if (this.critical_info.toString().length > 400) {
        factor += 0.4;
      }
      setTimeout(() => {
        this.loadingRandomPrice = false;
      }, 4000);

      return {
        from: parseInt(randomPrice * factor),
        to: parseInt(
          randomPrice * factor +
            this.getRandomIntInclusive(BASE_PRICE - 20, BASE_PRICE + 100)
        ),
      };
    },
  },
  mounted() {},
};
</script>

<style scoped>
.add-lead {
  cursor: pointer;
  z-index: 3;
  position: sticky;
  top: 70px;
}
</style>
