<template>
  <div class="d-flex flex-column mb-6 locked-wrapper">
    <div class="lead-locked d-flex flex-column">
      <v-icon x-large class="mb-4">mdi-lock</v-icon>

      <h2>Du får tillgång till informationen genom att köpa leadkortet</h2>

      <BuyLead :item="lead" @completedPurchase="completedPurchase" />

      <div class="mt-6">
        <LikeLead :item="lead" />
        <ShareLead :item="lead" />
      </div>
    </div>
    <div v-blur="blurConfig">
      <v-row>
        <v-col md="6" cols="12">
          <div class="d-flex-column">
            <div>
              <LeadContact :lead="mockup" />
            </div>
            <div v-if="!mobile">
              <LeadAllabolag :lead="mockup" />
            </div>
            <div v-if="!mobile">
              <LeadLinkedin :lead="mockup" />
            </div>
          </div>
        </v-col>
        <v-col md="6" cols="12">
          <div class="d-flex-column">
            <div v-if="!mobile">
              <LeadChart :lead="mockup" />
            </div>
            <div v-if="!mobile">
              <LeadMap :lead="mockup" />
            </div>
            <div v-if="!mobile">
              <LeadNews :lead="mockup" />
            </div>
          </div> </v-col
      ></v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LeadChart from "./LeadChart.vue";
import LeadMap from "./LeadMap.vue";
import LeadContact from "./LeadContact.vue";
import LeadNews from "./LeadNews.vue";
import BuyLead from "@/components/leadcard/BuyLead";
import ShareLead from "@/components/leadcard/ShareLead";
import LikeLead from "@/components/leadcard/LikeLead";
import LeadLinkedin from "./LeadLinkedin.vue";
import LeadAllabolag from "./LeadAllabolag.vue";
import axiosInstance from "../../services/axiosAPI";
export default {
  name: "LeadLocked",
  components: {
    LeadChart,
    LeadMap,
    LeadContact,
    LeadNews,
    BuyLead,
    LikeLead,
    ShareLead,
    LeadLinkedin,
    LeadAllabolag,
  },
  props: ["lead"],
  mounted() {
    this.getMockupLead();
  },
  methods: {
    completedPurchase() {
      this.$emit("leadWasPurchased");
    },
    getMockupLead() {
      axiosInstance
        .get(`api/leads/lead/mockup/`)
        .then((response) => {
          const data = response.data.data;
          this.mockup = data;
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapGetters(["getUserAccount", "getUser"]),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  data() {
    return {
      blurConfig: {
        isBlurred: true,
        opacity: 0.1,
        filter: "blur(1.2px)",
        transition: "all .3s linear",
      },

      mockup: {
        locked: true,
        contact_person: {
          first_name: "Anders",
          last_name: "Andersson",
          city: "Stockholm",
          phone: "070-000 00 00",
          linkedin: "https://linkedin.com",
          email: "andersfake@zwop.io",
          company: {
            regno: "5563718526",
          },
        },
        owner: null,
        likes: [],
        is_verified: false,
        is_premium: false,
        is_active: false,
        user_reference: false,
        created_by: {
          id: "",
          avatar: null,
          city: "",
          company: "",
          company_url: null,
          email: "",
          email_verified: false,
          first_name: "",
          last_name: "",
          linkedin: null,
          phone: "",
          phone_verified: false,
          role: "",
          subscription: null,
          summary: "",
          rating: null,
        },
        created_at: null,
        tags: [],
        status: "",
        review: null,
      },
    };
  },
};
</script>

<style>
.locked-wrapper {
  position: relative;
}
.lead-locked {
  z-index: 3;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}
</style>
