<template>
  <div class="board">
    <menu-bar :routes="routes" :refresh="fetchOwnedLeads"></menu-bar>
    <v-row>
      <v-col md="6" sm="12" xs="12">
        <task-lane
          id="open"
          :title="$t('wallet.open')"
          bgColor=""
          :items="openItems"
        ></task-lane>
      </v-col>
      <v-col md="6" sm="12" xs="12">
        <task-lane
          id="closed"
          :title="$t('wallet.closed')"
          bgColor=""
          :items="closedItems"
        ></task-lane>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TaskLane from "./TaskLane";
import MenuBar from "@/components/navigation/MenuBar";

export default {
  name: "KanbanBoard",
  components: {
    "task-lane": TaskLane,
    "menu-bar": MenuBar,
  },
  methods: { ...mapActions(["fetchOwnedLeads"]) },
  computed: mapState({
    openItems: (s) => s.wallet.items.open,
    closedItems: (s) => s.wallet.items.closed,
  }),
  created() {
    this.fetchOwnedLeads();
  },
  data() {
    return {
      routes: [
        {
          route: "/board",
          icon: "mdi-bulletin-board",
        },

        {
          route: "/table",
          icon: "mdi-table",
        },
      ],
    };
  },
};
</script>
