<template>
  <v-card class="pa-4 text-center">
    <div class="text-h6">Vad innebär premium?</div>
    <div>
      Med premium betalar du halverad transaktions-fee (d.v.s. 9% istället för
      18%) varje gång du säljer eller köper ett leadkort. Du får även tillgång
      till funktioner som export till CRM och dold profil. Premiummedlemmar får
      även ta del av erbjudanden och kampanjer.
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PremiumDesc",
  props: [""],
  filters: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
