<template>
  <div id="navbar-main">
    <v-tour name="navBarTour" :steps="steps" :options="tourOptions"></v-tour>
    <!-- <OnboardingStepper /> -->
    <v-app>
      <v-app-bar app clipped-left color="primary">
        <a @click="$router.go(-1)" v-if="mobile"
          ><v-icon large dark>mdi-chevron-left</v-icon></a
        ><v-app-bar-nav-icon @click="toggleNavDrawer" v-if="!mobile && !tablet"
          ><v-avatar class="secondary" size="30">
            <v-img v-if="getUser.avatar" :src="imgPrefix + getUser.avatar">
            </v-img
          ></v-avatar>
        </v-app-bar-nav-icon>
        <v-img
          contain
          max-height="45"
          max-width="160"
          src="@/assets/Zwop_Logo_White.png"
          class="hidden-sm-and-down"
        ></v-img>

        <FilterRow @refreshFetch="refreshFetch" />

        <v-btn dark icon @click="startNavTour" v-if="!mobile && !tablet">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>

        <NotificationMenu data-v-step="11" />
        <v-tooltip bottom open-delay="500" :disabled="mobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="goToAccount"
              small
              outlined
              color="secondary"
              class="ml-2"
              data-v-step="12"
              >{{ getUserAccount.balance ? getUserAccount.balance : "..." }} kr
            </v-btn>
          </template>
          <span> {{ $t("tooltip.accountTooltip") }}</span>
        </v-tooltip>
      </v-app-bar>

      <v-navigation-drawer :value="getNavDrawer" app clipped>
        <v-list dense>
          <v-list-item class="d-flex">
            <v-tooltip bottom open-delay="500" :disabled="mobile">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <SettingsDialog data-v-step="2" />
                </div>
              </template>
              <span> {{ $t("tooltip.settingsTooltip") }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleNavDrawer">
              <v-icon>mdi-arrow-expand-left </v-icon>
            </v-btn>
          </v-list-item>

          <v-tooltip bottom open-delay="500" :disabled="mobile">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-list-item
                  :to="'/user/' + getUser.id"
                  class="d-flex justify-center"
                >
                  <v-badge
                    avatar
                    bordered
                    overlap
                    :value="getUser.subscription"
                    offset-x="20"
                    offset-y="20"
                  >
                    <template v-slot:badge>
                      <v-avatar>
                        <v-img src="@/assets/Zwop_Logo_Z.png"></v-img>
                      </v-avatar>
                    </template>

                    <v-avatar class="secondary" size="70">
                      <v-img
                        v-if="getUser.avatar"
                        :src="imgPrefix + getUser.avatar"
                      ></v-img>
                    </v-avatar>
                  </v-badge>
                </v-list-item>
                <v-list-item
                  :to="'/user/' + getUser.id"
                  class="d-flex justify-center"
                >
                  <v-list-item-content class="text-center">
                    <v-list-item-title
                      class="text-uppercase font-weight-bold"
                      v-if="getUser"
                    >
                      {{ getUser.first_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="getUser">{{
                      getUser.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
            <span>{{ $t("tooltip.profileTooltip") }} </span>
          </v-tooltip>

          <v-list-item>
            <v-list-item-content data-v-step="0">
              <v-tooltip bottom open-delay="500" :disabled="mobile">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <AddLead :showAvatar="false" />
                  </div>
                </template>
                <span> {{ $t("tooltip.addLeadToolTip") }} </span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>

          <template v-for="(item, i) in items">
            <v-row v-if="item.heading" :key="i" align="center">
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn small text>edit</v-btn>
              </v-col>
            </v-row>
            <v-divider
              v-else-if="item.divider"
              :key="i"
              class="my-4"
            ></v-divider>
            <v-list-item v-else :key="i" link router :to="item.route">
              <v-tooltip bottom open-delay="500" :disabled="mobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-action>
                    <v-icon :color="item.color"> {{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-bind="attrs"
                      v-on="on"
                      :data-v-step="i + 4"
                      class="grey--text"
                      v-html="$t(item.text)"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <span v-html="$t(item.tooltip)"> </span>
              </v-tooltip>
            </v-list-item>
          </template>
        </v-list>
        <div class="container" data-v-step="1">
          <v-tooltip bottom open-delay="500" :disabled="mobile">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <QrCodeDialog :user="getUser" />
              </div>
            </template>
            <span> {{ $t("tooltip.QRCodeTooltip") }} </span>
          </v-tooltip>
        </div>
      </v-navigation-drawer>
      <v-row>
        <v-col xs="12" sm="12" md="12">
          <Connectivity />
          <router-view></router-view>
        </v-col>
      </v-row>
      <BottomNav />
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import SettingsDialog from "@/components/usersettings/SettingsDialog";
import AddLead from "@/components/leadcard/AddLead";
import NotificationMenu from "@/components/navigation/NotificationMenu";
import Connectivity from "@/components/navigation/Connectivity";
import QrCodeDialog from "@/components/navigation/QrCodeDialog";
import BottomNav from "@/components/navigation/BottomNav";
import Sidebar from "@/components/navigation/Sidebar";
import Footer from "@/components/navigation/Footer";
// // import OnboardingStepper from "@/components/OnboardingStepper";
import FilterRow from "@/components/FilterRow";
export default {
  name: "NavbarMain",
  components: {
    SettingsDialog,
    AddLead,
    NotificationMenu,
    Connectivity,
    QrCodeDialog,
    BottomNav,
    Sidebar,
    Footer,
    // OnboardingStepper,
    FilterRow,
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "editUser",
      "fetchNotifications",
      "fetchUserAccount",
      "toggleNavDrawer",
      "fetchTags",
    ]),
    startNavTour() {
      this.showTour = !this.showTour;
      if (this.showTour) {
        this.getNavDrawer = true;
        this.$tours["navBarTour"].start();
      }
      return this.showTour;
    },
    goToAccount() {
      this.$router.push("/dashboard/").catch(() => {});
    },

    globalSearch() {
      this.$router.push("/search/" + this.search).catch(() => {});
      this.searchClosed = true;
      this.search = null;
    },

    focusSearch() {
      this.searchClosed = !this.searchClosed;
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },

    randomImages() {
      axios.get("https://picsum.photos/v2/list").then((response) => {
        const imgIndexes = [
          this.getRandomInt(20),
          this.getRandomInt(20),
          this.getRandomInt(20),
        ];

        imgIndexes.map((index) =>
          this.randomSidebarImages.push(response.data[index].download_url)
        );
      });
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "isLoggedIn",
      "isLoading",

      "getUserAccount",
      "getNavDrawer",
    ]),

    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  created() {
    this.fetchNotifications();
    this.fetchUserAccount();
    this.fetchTags();
  },
  beforeMount() {
    this.fetchUser();
  },
  mounted() {
    // this.lastScrollPosition = window.pageYOffset;
    // window.addEventListener("scroll", this.onScroll);
    // const viewportMeta = document.createElement("meta");
    // viewportMeta.name = "viewport";
    // viewportMeta.content =
    //   "width=device-width, initial-scale=1.0, viewport-fit=cover";
    // document.head.appendChild(viewportMeta);
    this.randomImages();
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.onScroll);
  },
  data: () => ({
    value: 1,
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    randomSidebarImages: [],
    showNavbar: true,
    lastScrollPosition: 0,
    scrollValue: 0,
    searchClosed: true,
    search: null,
    showTour: false,

    tourOptions: {
      useKeyboardNavigation: true,
      labels: {
        buttonSkip: "Skip tour",
        buttonPrevious: "Previous",
        buttonNext: "Next",
        buttonStop: "Finish",
      },
    },
    steps: [
      {
        target: '[data-v-step="0"]',
        content: "Click here to add a new lead",
      },
      {
        target: '[data-v-step="1"]',
        content:
          "By sharing your personal invite code you will get various benefits and rewards when new members sign up.",
      },
      {
        target: '[data-v-step="2"]',
        content:
          "Here you'll find you profile information and personal settings.",
      },
      {
        target: '[data-v-step="3"]',
        content:
          "Click here to enter your profile. Here you can read your reviews and find your uploaded leads.",
        params: {
          placement: "right", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        },
      },
      {
        target: '[data-v-step="4"]',
        content:
          "In the feed you'll find intersting leads. You are able to apply filters in order to quickly find what you are looking for.",
      },
      {
        target: '[data-v-step="5"]',
        content:
          "Here's your personal dashboard showing various statistics and metrics.",
      },

      {
        target: '[data-v-step="6"]',
        content:
          "Here you'll find pending lead zwops and zwop offerings from other memebers.",
      },
      {
        target: '[data-v-step="7"]',
        content: "In the wallet you can view and handle the leads you own.",
      },
      {
        target: '[data-v-step="8"]',
        content:
          "Here you'll find you account balance and transaction history. Also deposits and withdrawals to you account are done here.",
      },
      {
        target: '[data-v-step="9"]',
        content: "Your connections are listed here.",
      },
      {
        target: '[data-v-step="10"]',
        content: "Ongoing conversations with other memebers.",
      },

      {
        target: '[data-v-step="11"]',
        content: "All your notifications are listed here.",
      },
      {
        target: '[data-v-step="12"]',
        content:
          "Your current account balance. Click to make deposit or withdrawal.",
      },
    ],

    items: [
      {
        text: "nav.market",
        tooltip: "tooltip.feedTooltip",
        icon: "mdi-store-outline",
        route: "/",
      },
      {
        text: "nav.dashboard",
        tooltip: "tooltip.dashboardTooltip",
        icon: "mdi-view-dashboard-outline",
        route: "/dashboard",
      },

      {
        text: "nav.exchange",
        tooltip: "tooltip.exchangeTooltip",
        icon: "mdi-handshake-outline",
        route: "/exchange",
      },
      {
        text: "nav.wallet",
        tooltip: "tooltip.walletTooltip",
        icon: "mdi-wallet-outline",
        route: "/board",
      },
      // { text: "nav.account", icon: "mdi-cash-multiple", route: "/account" },
      // {
      //   text: "nav.network",
      //   tooltip: "tooltip.networkTooltip",
      //   icon: "mdi-family-tree",
      //   route: "/network",
      // },

      // TODO - Add chat feature later
      // {
      //   text: "nav.messages",
      //   icon: "mdi-message-text-outline",
      //   route: "/messages",
      // },
      {
        text: "nav.logout",
        tooltip: "tooltip.logoutTooltip",
        icon: "mdi-logout",
        route: "/logout",
      },
    ],

    dialog: false,
    tab: 0,
    tabs: [
      { id: 1, name: "nav.profile", icon: "mdi-account-outline" },
      { id: 2, name: "nav.password", icon: "mdi-key-outline" },
      { id: 3, name: "nav.settings", icon: "mdi-tune" },
    ],
    valid: true,

    // password: "",
    verify: "",
    emailRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
    ],
    show1: false,
    rules: {
      required: (value) => !!value || "Obligatorisk",
      min: (v) => (v && v.length >= 5) || "Min. 5 tecken",
    },
  }),
};
</script>

<style scoped>
.closed {
  display: none;
}
.open {
  width: 100%;
  position: sticky;
  right: 0;
  transform: translate(50px, 45px);

  z-index: 3;
}
</style>
