<template>
  <div class="network">
    <MenuBar :routes="routes" :refresh="() => {}"></MenuBar>

    <v-row class="fill-height"
      ><v-col cols="12">
        <v-card class="">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            outlined
            dense
            @input="refreshFetch"
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col>
        <div v-for="profile in profilesInfiniteArray" :key="profile.id">
          <ProfileCardRow :profile="profile" />
        </div>
      </v-col>
    </v-row>
    <div>
      <p
        class="ma-4 text-center"
        v-if="profilesInfiniteArray.length === 0 && !nextIsLoading"
      >
        <v-icon x-large>mdi-account-circle-outline</v-icon><br />
        {{ $t("noContactsInNetworkYet") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import MenuBar from "@/components/navigation/MenuBar";
import ProfileCardRow from "@/components/profile/ProfileCardRow";
export default {
  name: "Network",
  components: {
    MenuBar,
    ProfileCardRow,
  },
  methods: {
    ...mapActions([]),
    getProfiles() {
      this.nextIsLoading = true;

      axiosInstance
        .get(
          `${API_URL}api/users/?page=${this.currentPage}&search=${this.search}`
        )
        .then((response) => {
          const data = response.data;

          this.hasNext = false;
          if (data.next) {
            this.hasNext = true;
          }
          data.results.map((result) => this.profilesInfiniteArray.push(result));
          this.nextIsLoading = false;
          return response;
        })
        .catch((error) => {
          console.error(error);
          this.nextIsLoading = false;
        });
    },
    refreshFetch() {
      this.profilesInfiniteArray = [];
      this.currentPage = 1;
      this.hasNext = true;
      this.getProfiles();
    },
    openProfile(item) {
      this.$router.push("/user/" + item.id).catch(() => {});
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  created() {},
  mounted() {
    this.getProfiles();
    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      if (bottomOfWindow && this.hasNext) {
        this.currentPage += 1;
        this.getProfiles();
      }
    };
  },
  destroyed() {
    this.profilesInfiniteArray = [];
    this.currentPage = 1;
    this.hasNext = true;
    window.onscroll = () => {};
  },
  data() {
    return {
      search: "",
      profilesInfiniteArray: [],
      currentPage: 1,
      hasNext: true,
      nextIsLoading: false,
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
      routes: [
        {
          route: "/network",
          icon: "mdi-view-grid-outline",
        },
      ],
    };
  },
  watch: {},
};
</script>
