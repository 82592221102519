<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="ma-1">
        <v-icon>mdi-share-variant-outline</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-4">
      <v-card-title>{{ $t("shareLeadCard") }}</v-card-title>
      <v-card-text
        >Dela leadkortet via email. Fyll i de emaliadresser du vill ska ta del
        av informationen du har hittat.</v-card-text
      >
      <v-card-text>
        <v-combobox
          class="mb-1"
          v-model="send_to"
          deletable-chips
          outlined
          multiple
          chips
          @keyup="extractEmails"
          :hint="'Fyll i de mailadresser du vill dela detta lead till'"
          :delimiters="[',', ' ', ';', '\n', '\r\n']"
        ></v-combobox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="success" type="submit" @click.stop="shareLeadMail">{{
          $t("share")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
import { mapActions } from "vuex";

export default {
  name: "ShareLead",
  props: ["item"],
  methods: {
    ...mapActions(["responseStatus"]),
    shareLeadMail() {
      if (this.send_to.length === 0) return;
      const payload = {
        send_to: this.send_to,
        lead_url: this.baseUrl + "card/" + this.item.slug,
        lead_title: this.item.title,
      };
      axiosInstance
        .post(`api/leads/share_lead/`, payload)
        .then((response) => {
          this.responseStatus({
            code: response.status,
            message: response.data?.message,
          });

          return response;
        })
        .catch((error) => {
          console.error(error);
        });
      this.dialog = false;
    },
    extractEmails() {
      let cleanedArray = [];
      this.emails.map((item) => {
        let emailsInString = item.match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );
        if (emailsInString) {
          emailsInString.forEach((element) => {
            cleanedArray.push(element);
          });
        }
      });

      // this.emails = cleanedArray;
      this.emails = cleanedArray.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
    },
  },
  data() {
    return {
      send_to: [],
      lead_url: null,
      lead_title: null,
      dialog: false,
      baseUrl: process.env.VUE_APP_BASE_URL,

      emailRules: [
        (v) => !!v || "Obligatorisk",
        (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
      ],
    };
  },
};
</script>

<style></style>
