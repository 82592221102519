<template>
  <div class="d-flex justify-center align-center bank-id-info">
    <v-card class="pa-10 align-center" max-width="300">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <v-img
        class="ma-4"
        max-width="200px"
        src="@/assets/Zwop_Logo_Color.png"
      ></v-img>

      <h2>
        {{ this.$route.query.actn }} lyckades du skickas nu vidare till din
        profil
      </h2>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DepositSuccessPage",
  methods: {},
  computed: {
    ...mapGetters(["getUser"]),
  },
  mounted() {
    setTimeout(() => {
      this.$router.push(`/user/${this.getUser.id}`).catch(() => {});
    }, 3000);
  },
  data() {
    return {};
  },
};
</script>

<style>
i {
  background-size: 20px 30px;
}

.bank-id-info {
  height: 100vh;
}
</style>
