<template>
  <v-card exact @click="openProfile(profile)" outlined class="pa-4">
    <v-row>
      <v-col lg="2" md="2" sm="2" xs="12">
        <v-avatar class="secondary" size="80">
          <v-img :src="imgPrefix + profile.avatar"></v-img>
        </v-avatar>
      </v-col>
      <v-col lg="6" md="6" sm="6" xs="12">
        <h3>{{ profile.first_name }} {{ profile.last_name }}</h3>
        <p>
          {{ profile.role }} at
          <v-btn
            class="text-capitalize"
            small
            text
            outlined
            color="primary"
            :href="profile.company_url"
            :disabled="!profile.company_url"
            >{{ profile.company }}</v-btn
          >
        </p>
      </v-col>

      <v-col lg="4" md="4" sm="4" xs="12">
        <HandleProfile
          :item="extractRequests(profile)"
          v-if="showHandleProfileButtons(profile)"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HandleProfile from "./HandleProfile.vue";

export default {
  name: "ProfileCardRow",
  props: ["profile"],
  components: { HandleProfile },
  methods: {
    ...mapActions(["handleFollowingRequest", "fetchFollowerRequests"]),
    openProfile(item) {
      this.$router.push("/user/" + item.id).catch(() => {});
    },
    extractRequests(profile) {
      return this.getFollowerRequests.filter(
        (e) => e.from_user.id === profile.id
      )[0];
    },
    showHandleProfileButtons(item) {
      if (this.getFollowerRequests.some((e) => e.from_user.id === item.id)) {
        return true;
      }
      return false;
    },
  },
  computed: { ...mapGetters(["getFollowerRequests"]) },
  mounted() {
    // this.fetchFollowerRequests();
  },
  data() {
    return {
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    };
  },
};
</script>

<style></style>
