<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-1" v-bind="attrs" v-on="on">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text style="max-height: 800px">
        <v-simple-table class="mt-4">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <h3>{{ offeredLead.title }}</h3>
                </td>
                <td>
                  <h3>{{ wantedLead.title }}</h3>
                </td>
              </tr>
              <tr>
                <td>
                  <v-avatar class="secondary" size="35">
                    <v-img
                      :src="imgPrefix + offeredLead.created_by.avatar"
                    ></v-img>
                  </v-avatar>
                  {{ offeredLead.created_by.first_name }}
                  {{ offeredLead.created_by.last_name }}
                </td>
                <td>
                  <v-avatar class="secondary" size="35">
                    <v-img
                      :src="imgPrefix + wantedLead.created_by.avatar"
                    ></v-img>
                  </v-avatar>
                  {{ wantedLead.created_by.first_name }}
                  {{ wantedLead.created_by.last_name }}
                </td>
              </tr>
              <tr>
                <td>
                  <v-chip
                    small
                    class="mr-2 mt-2"
                    v-for="tag in offeredLead.tags"
                    :key="tag.slug"
                    color="primary"
                  >
                    <v-icon x-small left> mdi-tag-outline </v-icon
                    >{{ tag.name }}</v-chip
                  >
                </td>
                <td>
                  <v-chip
                    small
                    class="mr-2 mt-2"
                    v-for="tag in wantedLead.tags"
                    :key="tag.slug"
                    color="primary"
                  >
                    <v-icon x-small left> mdi-tag-outline </v-icon
                    >{{ tag.name }}</v-chip
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <v-chip
                    small
                    outlined
                    class="mr-2 mt-2"
                    v-for="sni in offeredLead.contact_person.company.sni"
                    :key="sni.code"
                  >
                    <v-icon x-small left> mdi-information-outline </v-icon>
                    {{ sni.description }}</v-chip
                  >
                </td>
                <td>
                  <v-chip
                    small
                    outlined
                    class="mr-2 mt-2"
                    v-for="sni in wantedLead.contact_person.company.sni"
                    :key="sni.code"
                  >
                    <v-icon x-small left> mdi-information-outline </v-icon>
                    {{ sni.description }}</v-chip
                  >
                </td>
              </tr>
              <tr>
                <td>{{ offeredLead.price }} kr</td>
                <td>{{ wantedLead.price }} kr</td>
              </tr>
              <tr>
                <td>{{ offeredLead.body }}</td>
                <td>{{ wantedLead.body }}</td>
              </tr>
              <tr>
                <td>
                  <v-btn @click="openDetail(offeredLead)">
                    {{ $t("open") }}
                  </v-btn>
                </td>
                <td>
                  <v-btn @click="openDetail(wantedLead)">
                    {{ $t("open") }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
var imgPrefix = process.env.VUE_APP_IMG_PREFIX;
export default {
  name: "ExchangeCompare",
  props: ["offeredLead", "wantedLead"],
  components: {},
  methods: {
    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },
  },
  computed: {},
  created() {},
  mounted() {},

  data: () => ({
    dialog: false,
    comparisonArray: [],
    imgPrefix: imgPrefix,
  }),
};
</script>
