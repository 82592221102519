<template>
  <div>
    <v-card class="pa-4 text-center" v-if="!menuButton">
      <div v-if="userHasSubscription" class="d-flex-column justify-center">
        <div>
          <v-icon x-large color="warning"> mdi-license </v-icon>
        </div>
        <span class="text-h6"> {{ $t("youGotZwopPremium") }}</span>
        <div v-if="subscriptionEndsAtPeriodEnd">
          <span class="text-caption red--text"
            >{{ $t("subscriptionCancelled") }}
            {{
              new Date(getUser.subscription.valid_through) | date("yyyy-MM-dd")
            }}
          </span>
        </div>
        <br />
        <CancelSubscriptionDialog
          v-if="userHasSubscription && !subscriptionEndsAtPeriodEnd"
        />

        <v-btn
          v-if="subscriptionEndsAtPeriodEnd"
          @click="undoCancelSubscription"
          color="success"
          class=""
          :loading="loading"
          >{{ $t("regretCancelZwopPremium") }}</v-btn
        >
      </div>
      <div v-if="!userHasSubscription" class="d-flex-column justify-center">
        <div>
          <!-- <v-icon x-large color="primary"> mdi-star-off </v-icon> -->
        </div>
        <div class="text-h6 mb-4">{{ $t("youGotZwopFreemium") }}</div>

        <v-divider></v-divider>

        <div class="text-h5 mt-4 mb-2">Bli premium</div>

        <v-select
          v-model="billing_period"
          :items="billing_periods_options"
          item-text="display"
          item-value="label"
          label="Betala abonnemanget"
          hint="Välj hur ofta abonnemangsfakturan ska betalas"
          persistent-hint
          return-object
          dense
          outlined
        ></v-select>
        <!-- <v-radio-group v-model="billing_period">
          <v-radio
            v-for="n in billing_periods_options"
            :key="n.label"
            :label="`${n.display}`"
            :value="n"
            :disabled="n.disabled"
          ></v-radio>
        </v-radio-group> -->
        <div class="text-h6 mb-2">
          {{ billing_period.comparePrice }} kr/månad
        </div>

        <v-btn
          @click="createSubscription"
          :loading="loading"
          color="success"
          class=""
          :disabled="hasUnpaidInvoice"
        >
          {{ $t("getZwopPremium") }}
          <v-icon color="white"> mdi-license </v-icon></v-btn
        >

        <span v-if="hasUnpaidInvoice" class="text-body">
          Betalningen av din senaste faktura misslyckades. Klicka på
          fakturalänken för att aktivera premium igen.
        </span>
      </div>
      <PremiumDesc class="mt-4" />
    </v-card>

    <v-btn
      v-if="menuButton && !userHasSubscription"
      small
      @click="createSubscription"
      :loading="loading"
      color="success"
      class=""
      :disabled="hasUnpaidInvoice"
    >
      {{ $t("getZwopPremium") }}
      <v-icon small color="white"> mdi-license </v-icon></v-btn
    >
    <v-btn small text v-if="menuButton && userHasSubscription">
      <v-icon color="warning"> mdi-license </v-icon>
      <span class="text-small"> {{ $t("youGotZwopPremium") }}</span></v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axiosInstance from "../../services/axiosAPI";
import { dateFilter } from "vue-date-fns";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import PremiumDesc from "./PremiumDesc";
export default {
  name: "SubscriptionCard",
  props: ["profile", "menuButton"],
  filters: {
    date: dateFilter,
  },
  components: {
    CancelSubscriptionDialog,
    PremiumDesc,
  },
  data() {
    return {
      loading: false,
      billing_periods_options: [
        {
          label: "beta_monthly",
          display: "Månadsvis (beta)",
          price: 99,
          comparePrice: 99,
          disabled: false,
        },
        {
          label: "monthly",
          display: "Månadsvis",
          price: 199,
          comparePrice: 199,
          disabled: true,
        },
        {
          label: "every_6_months",
          display: "Halvårsvis",
          price: 999,
          comparePrice: 166,
          disabled: true,
        },
        {
          label: "yearly",
          display: "Årsvis",
          price: 1799,
          comparePrice: 149,
          disabled: true,
        },
      ],
      billing_period: {
        label: "beta_monthly",
        display: "Månadsvis (beta)",
        price: 99,
        comparePrice: 99,
        disabled: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getUser"]),

    userHasSubscription() {
      if (this.getUser.subscription === null) return false;
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },
    subscriptionEndsAtPeriodEnd() {
      if (this.getUser.subscription === null) return;
      return this.getUser.subscription.stripe_cancel_at_period_end;
    },
    hasUnpaidInvoice() {
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date(1990, 5, 4)
      )
        return true;
      return false;
    },
  },
  methods: {
    ...mapActions(["fetchUser"]),
    createSubscription() {
      if (this.billing_period.label === null) return;
      const payload = {
        checkout_mode: "subscription",
        billing_period: this.billing_period.label,
      };
      this.loading = true;
      axiosInstance
        .post("/api/payments/v2/stripe/create-checkout-session/", payload)
        .then((response) => {
          console.log(response);
          window.location = response.data.session.url;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    cancelSubscription() {
      this.loading = true;
      return (
        axiosInstance
          .post("/api/payments/v2/stripe/cancel-at-period-end-subscription/")
          .then((response) => {
            return response;
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            this.loading = false;
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            this.fetchUser();
            this.loading = false;
            return {
              paymentMethodId: paymentMethodId,
              subscription: result,
            };
          })
          // // Some payment methods require a customer to be on session
          // // to complete the payment process. Check the status of the
          // // payment intent to handle these actions.
          // .then(handlePaymentThatRequiresCustomerAction)
          // // If attaching this card to a Customer object succeeds,
          // // but attempts to charge the customer fail, you
          // // get a requires_payment_method error.
          // .then(handleRequiresPaymentMethod)
          // // No more actions required. Provision your service for the user.
          // .then(onSubscriptionComplete)
          .catch((error) => {
            this.loading = false;
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            console.log(error);
          })
      );
    },
    undoCancelSubscription() {
      this.loading = true;
      return (
        axiosInstance
          .post("/api/payments/v2/stripe/undo-cancel-subscription/")
          .then((response) => {
            this.loading = false;
            this.fetchUser();
            return response;
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            this.loading = false;

            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            this.loading = false;
            return {
              paymentMethodId: paymentMethodId,
              subscription: result,
            };
          })
          // // Some payment methods require a customer to be on session
          // // to complete the payment process. Check the status of the
          // // payment intent to handle these actions.
          // .then(handlePaymentThatRequiresCustomerAction)
          // // If attaching this card to a Customer object succeeds,
          // // but attempts to charge the customer fail, you
          // // get a requires_payment_method error.
          // .then(handleRequiresPaymentMethod)
          // // No more actions required. Provision your service for the user.
          // .then(onSubscriptionComplete)
          .catch((error) => {
            this.loading = false;
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            console.log(error);
          })
      );
    },
  },
};
</script>

<style></style>
