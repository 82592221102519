import AuthService from "../../services/auth.service";
import axiosInstance from "../../services/axiosAPI";

import jwt_decode from "jwt-decode";
import router from "../../router";

const refresh_token = localStorage.getItem("refresh_token");
const access_token = localStorage.getItem("access_token");
const API_URL = process.env.VUE_APP_API_URL;
const WEBSITE_URL = process.env.VUE_APP_WEBSITE_URL;

const initialState = access_token
  ? {
      status: { loggedIn: true },
      user: { access_token, refresh_token },
      account: {},
      transactions: [],
      invoices: [],
      exchanges: [],
      profile: {},
    }
  : {
      status: { loggedIn: false },
      user: { access_token: null, refresh_token: null },
      account: {},
      transactions: [],
      invoices: [],
      exchanges: [],
      profile: {},
    };

const state = initialState;

const getters = {
  getUser: (state) => state.profile,
  getUserAccount: (state) => state.account,
  getTransactions: (state) => state.transactions,
  getInvoices: (state) => state.invoices,
  getExchanges: (state) => state.exchanges,
  isLoggedIn: (state) => state.status.loggedIn,
};

const actions = {
  login({ commit, dispatch }, user) {
    return AuthService.login(user).then(
      (user) => {
        commit("loginSuccess", user);
        dispatch("fetchUser");
        router.push("/").catch(() => {});

        return Promise.resolve(user);
      },
      (error) => {
        commit("loginFailure");

        if (error.detail) {
          commit("setError", {
            message: error.detail,
          });
        } else {
          commit("setError", {
            message:
              "Det gick inte att logga in. Har du angivit rätt email och lösenord?",
          });
        }

        // console.error("login", error);
        return Promise.reject(error);
      }
    );
  },
  fetchUser({ commit }) {
    const access_token = localStorage.getItem("access_token");

    var decoded_token = jwt_decode(access_token);
    axiosInstance
      .get(`${API_URL}api/users/user/${decoded_token.user_id}`)
      .then((response) => {
        commit("setUserProfile", response.data);

        localStorage.setItem(
          "theme",
          response.data.app_theme === "dark" ? "dark" : "light"
        );
        localStorage.setItem("language", response.data.language);
      })
      .catch((error) => {
        const errorHuman = {
          message: "Något gick fel när dina användaruppgifter skulle hämtas",
        };
        commit("setError", errorHuman);
        console.error("fetchUser", error);
      });
  },

  editUser({ commit }, payload) {
    axiosInstance
      .patch(API_URL + "api/users/edit/", payload)
      .then((response) => {
        console.log(response.data);
        commit("setUserProfile", response.data);
      })
      .catch((error) => {
        console.error("editUser", error);

        const errorHuman = {
          message: "Något gick fel när du skulle spara dina inställningar",
        };
        commit("setError", errorHuman);
      });
  },

  saveSettings({ commit }, payload) {
    axiosInstance
      .post(API_URL + "api/users/follow/", payload)
      .then((response) => commit("followProfile", response.data))
      .catch((error) => {
        console.error(error);
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  loadUser({ commit }, user) {
    return AuthService.loadUser(user).then(
      (user) => {
        commit("loadUserSuccess", user);
        return Promise.resolve(user);
      },
      (error) => {
        // commit("setError", error);
        console.error("loadUser", error);
        commit("loadUserFailure");
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    AuthService.logout();
    commit("logout");
    router.push("/login").catch(() => {});
    const status = {
      timeout: 6000,

      message: "Utloggad",
    };
    commit("setStatus", status);
  },

  loginBankID({ commit }, personalNumber) {
    return AuthService.loginBankID(personalNumber).then(
      (data) => {
        // commit("loginSuccess", user);
        // console.log(data);
        window.location = data.redirectUrl;
        // dispatch("fetchUser");
        // router.push("/").catch(() => {});

        return Promise.resolve(data);
      },
      (error) => {
        commit("loginFailure");
        if (error.error === "account_not_activated") {
          const info = {
            timeout: 10000,
            label: "Ok",
            icon: "mdi-alert",

            message:
              "Ditt konto är inte aktiverat. Gå till mailet du fick efter du skapat ditt konto och klicka på länken för att aktivera ditt konto. Dubbelkolla i skräpposten i fall du inte hittar något i din inkorg.",
          };
          commit("setInfo", info);
        } else {
          const info = {
            timeout: 10000,
            label: "Ok",
            icon: "mdi-alert",

            message: "Du verkar inte ha något konto.",
            linkText: "Skapa konto",
            link: "/signup",
          };
          commit("setInfo", info);
        }
        console.error("loginBankID", error);
        return Promise.reject(error);
      }
    );
  },

  loadUserBankID({ commit }, sessionId) {
    return AuthService.loadUserBankID(sessionId).then(
      (data) => {
        const jwt = data.jwt;
        const user = { access_token: jwt.access, refresh_token: jwt.refresh };
        commit("loginSuccess", user);

        dispatch("fetchUser");
        router.push("/").catch(() => {});

        return Promise.resolve(data);
      },
      (error) => {
        if (error.error === "account_not_activated") {
          const info = {
            timeout: 10000,
            label: "Ok",
            icon: "mdi-alert",

            message:
              "Ditt konto är inte aktiverat. Gå till mailet du fick efter du skapat ditt konto och klicka på länken för att aktivera ditt konto. Dubbelkolla i skräpposten i fall du inte hittar något i din inkorg.",
          };
          commit("setInfo", info);
        } else {
          const info = {
            timeout: 10000,
            label: "Ok",
            icon: "mdi-alert",

            message: "Du verkar inte ha något konto.",
            linkText: "Skapa konto",
            link: "/signup",
          };
          commit("setInfo", info);
        }
        console.error("loadUserBankID", error);
        return Promise.reject(error);
      }
    );
  },

  register({ commit }, user) {
    return AuthService.register(user).then(
      (response) => {
        commit("registerSuccess");
        router.push("/login").catch(() => {});
        window.open(`${WEBSITE_URL}welcome`, "_blank");
        const info = {
          timeout: 10000,
          label: "Ok",
          icon: "mdi-email-alert",
          text: "Aktivering av konto",
          message:
            "Du har fått en aktiveringslänk via mail. Vänligen aktivera ditt konto genom att följa instruktionerna i mailet. Dubbelkolla i skräpposten i fall du inte hittar något i din inkorg.",
        };
        commit("setInfo", info);
        return Promise.resolve(response.data);
      },
      (error) => {
        console.error("register", error);
        commit("registerFailure");
        // commit("setError", error);
        let errorHuman;
        if (error.response.data && error.response.data.message) {
          errorHuman = {
            message: error.response.data.message,
          };
        } else {
          errorHuman = {
            message:
              "Något gick fel när du skulle skapa ditt konto. Maila oss på help@zwop.io",
          };
        }

        commit("setError", errorHuman);
        return Promise.reject(error);
      }
    );
  },
  requestPasswordReset({ commit }, email) {
    return AuthService.passwordReset(email).then(
      (response) => {
        router.push("/login").catch(() => {});
        const info = {
          timeout: 10000,
          label: "Ok",
          icon: "mdi-email-alert",
          text: "Återställning av lösenord",
          message:
            "Du har fått en återställningslänk via mail. Dubbelkolla i skräpposten i fall du inte hittar något i din inkorg.",
        };
        commit("setInfo", info);

        return Promise.resolve(response.data);
      },
      (error) => {
        console.error("requestPasswordReset", error);
        return Promise.reject(error);
      }
    );
  },

  // TODO This is not used right now. Where to implement this? For deposit in user account?
  createCheckOut() {
    axiosInstance
      .get(API_URL + "api/payments/create-checkout-session/")

      .then((result) => {
        return result.json();
      })
      .then((data) => {
        console.log(data);

        // Redirect to Stripe Checkout
        return stripe.redirectToCheckout({ sessionId: data.sessionId });
      })
      .catch((error) => {
        console.error("createCheckOut", error);
      });
  },

  fetchUserAccount({ commit }) {
    axiosInstance
      .get(`${API_URL}api/payments/account/`)
      .then((response) => commit("setUserAccount", response.data))
      .catch((error) => {
        console.error(error);
        // commit("setError", error);

        const errorHuman = {
          message: "Något gick fel när ditt konto och saldo skulle läsas in",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  fetchTransactions({ commit }) {
    axiosInstance
      .get(`${API_URL}api/payments/transactions/`)
      .then((response) => commit("setTransactions", response.data))
      .catch((error) => {
        console.error(error);
        // commit("setError", error);

        const errorHuman = {
          message: "Något gick fel när dina transaktioner skulle hämtas",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  fetchInvoices({ commit }) {
    axiosInstance
      .get(`${API_URL}api/payments/invoices/`)
      .then((response) => commit("setInvoices", response.data))
      .catch((error) => {
        console.error(error);
        // commit("setError", error);
        const errorHuman = {
          message: "Något gick fel när dina fakturor skulle hämtas",
        };
        commit("setError", errorHuman);

        commit("unsetLoading");
      });
  },
  fetchCompletedExchanges({ commit }) {
    axiosInstance
      .get(`${API_URL}api/leads/completed-exchanges/`)
      .then((response) => commit("setExchanges", response.data))
      .catch((error) => {
        console.error(error);
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
  followProfile({ commit }, payload) {
    console.log(payload);
    commit("followProfile", payload);
    axiosInstance
      .post(API_URL + "api/users/follow/", payload)
      .then((response) => {
        console.log(response.data);
        commit("setStatus", {
          code: response.status,
          text: response.statusText,
          message: response.data?.message,
        });
      })
      .catch((error) => {
        // commit("setError", error);

        commit("unsetLoading");
      });
  },
};

const mutations = {
  loginSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  setUserProfile: (state, profile) => (state.profile = profile),
  setUserAccount: (state, account) => (state.account = account),
  setTransactions: (state, transactions) => (state.transactions = transactions),
  setInvoices: (state, invoices) => (state.invoices = invoices),
  setExchanges: (state, exchanges) => (state.exchanges = exchanges),
  editUserProfile: (state, profile) => (state.profile = profile),
  loginFailure(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.status.loggedIn = false;
    state.user = null;
    state.profile = null;
  },
  registerSuccess(state) {
    state.status.loggedIn = false;
  },
  registerFailure(state) {
    state.status.loggedIn = false;
  },
  followProfile: (state, follow) => {
    if (state.profile.follows.some((id) => id == follow.id)) {
      state.profile.follows = state.profile.follows.filter(
        (id) => id != follow.id
      );
    } else {
      state.profile.follows.push(follow.id);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
