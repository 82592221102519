<template>
  <v-snackbar bottom left text :value="value" :color="color" :timeout="timeout">
    <span>
      <slot></slot>
    </span>

    <v-progress-linear
      absolute
      bottom
      :value="Math.floor(100 * (currentTime / timeout))"
    />
  </v-snackbar>
</template>

<script>
export default {
  props: {
    timeout: {
      default: 6000,
    },
    value: {
      default: false,
    },
    color: {
      default: false,
    },
  },
  data() {
    return {
      currentTime: 0,
    };
  },
  methods: {
    syncPbar() {
      //Create a timeout every 100 miliseconds
      setTimeout(() => {
        //Increment the time counter by 100
        this.currentTime += 100;

        //If our current time is larger than the timeout
        if (this.timeout <= this.currentTime) {
          //Wait 500 miliseconds for the dom to catch up, then reset the snackbar
          setTimeout(() => {
            this.$emit("input", false); //Update the v-model to false
            this.currentTime = 0; // reset the current time
          }, 500);
        } else {
          //Recursivly update the progress bar
          this.syncPbar();
        }
      }, 100);
    },
  },
  watch: {
    value(v) {
      if (v) this.syncPbar();
    },
  },
};
</script>

<style></style>
