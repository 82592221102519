<template>
  <v-tooltip bottom open-delay="500" :disabled="mobile">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        v-if="item.created_by.id != getUser.id"
        class="ma-1"
        @click.stop="likeLead"
      >
        <v-icon v-if="!isLeadLiked">mdi-bookmark-outline</v-icon>
        <v-icon color="primary" v-if="isLeadLiked">mdi-bookmark</v-icon>
      </v-btn>
    </template>
    <span> {{ $t("tooltip.saveLead") }}</span>
  </v-tooltip>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LikeLead",
  props: ["item"],

  methods: {
    ...mapActions(["responseStatus", "fetchMyLikes", "toggleLike"]),
    likeLead() {
      const payload = {
        id: this.item.id,
        lead: this.item,
      };
      this.toggleLike(payload);
    },
  },
  computed: {
    ...mapGetters(["myLikes", "getUser"]),
    isLeadLiked() {
      return this.myLikes.some((lead) => lead.id === this.item.id);
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style></style>
