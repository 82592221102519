<template>
  <div class="exchange">
    <MenuBar :routes="routes" :refresh="fetchOfferings"></MenuBar>
    <v-row class="fill-height"
      ><v-col cols="12">
        <v-card class="">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col
        :sm="12"
        :lg="6"
        :xl="4"
        v-for="offering in filteredList"
        :key="offering.offered_lead.id"
      >
        <LeadCard :lead="offering.offered_lead" />
      </v-col>
      <v-col cols="12">
        <p class="ma-4 text-center" v-if="filteredList.length === 0">
          <v-icon x-large>mdi-swap-horizontal</v-icon><br />
          {{ $t("noZwopOffersYet") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import LeadCard from "@/components/leadcard/LeadCard";
import ExchangeCompare from "./ExchangeCompare.vue";

export default {
  name: "Exchange",
  components: {
    MenuBar,
    ExchangeCompare,
    LeadCard,
  },
  methods: {
    ...mapActions(["leadExchange", "fetchOfferings"]),

    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },

    acceptExchange(item) {
      var payload = {
        id: item.id,
        action: "accept",
        offering: true,
      };
      this.leadExchange(payload);
    },

    rejectExchange(item) {
      var payload = {
        id: item.id,
        action: "reject",
        offering: true,
      };
      this.leadExchange(payload);
    },
  },
  computed: {
    ...mapGetters(["getOfferings", "getUser"]),

    filteredList: function () {
      const listItems = this.getOfferings;
      return listItems
        .filter((item) => {
          return item.offered_lead.title
            .toLowerCase()
            .includes(this.search.toLowerCase());
        })
        .filter((item) => {
          return item.offered_lead.body
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
    },
  },

  created() {
    this.fetchOfferings();
  },
  mounted() {},

  data: () => ({
    search: "",
    headers: [
      {
        text: "User",
        align: "start",
        sortable: true,
        value: "user.first_name",
      },
      {
        text: "Offered lead",
        align: "start",
        sortable: true,
        value: "offered_lead.title",
      },
      {
        text: "My lead",
        align: "start",
        sortable: true,
        value: "wanted_lead.title",
      },
      { text: "", value: "actions", sortable: false },
    ],
    routes: [
      {
        route: "/exchange",
        icon: "mdi-swap-horizontal",
      },
      {
        route: "/bids",
        icon: "mdi-timer-outline",
      },
      {
        route: "/pending",
        icon: "mdi-timer-sand",
      },
      {
        route: "/liked",
        icon: "mdi-bookmark-outline",
      },
    ],
  }),
};
</script>
