<template>
  <v-app class="background-img">
    <div class="d-flex justify-center align-center">
      <v-card max-width="600px">
        <p>RESET PASSW</p>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="password"
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min, rules.password]"
              :type="show_password ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('password')"
              hint="At least 8 characters"
              counter
              outlined
              @click:append="show_password = !show_password"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              block
              v-model="verify"
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, passwordMatch]"
              :type="show_password ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('confirmPassword')"
              counter
              outlined
              @click:append="show_password = !show_password"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import User from "../models/user";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "PasswordReset",
  components: {},

  computed: {
    ...mapGetters(["isLoading", "getError"]),
    passwordMatch() {
      return () =>
        this.password === this.verify || "Lösenorden måste vara lika";
    },
  },
  created() {},
  methods: {
    ...mapActions(["responseInfo"]),
  },

  data: () => ({
    user: new User(localStorage.getItem("login_email") || "", ""),

    loading: false,
    message: "",

    password: "",
    verify: "",

    show_password: false,

    rules: {
      required: (value) => !!value || "Obligatorisk",
      min: (v) => (v && v.length >= 8) || "Minst 8 tecken",
      password: (v) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/.test(
          v
        ) ||
        "Lösenordet måste innehålla minst en liten bokstav, en stor bokstav, en siffra och ett specialtecken",
    },
  }),
  watch: {},
};
</script>

<style scoped>
.background-img {
  background-image: url("~@/assets/teamwork_bg.jpg") !important;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background-size: cover;
}
</style>
