<template>
  <v-card class="pa-4">
    <v-list class="text-center pa-0">
      <div class="d-flex justify-center" v-if="getUser.id == profile.id">
        <SettingsDialog />
      </div>
      <v-list-item class="d-flex justify-center">
        <v-badge
          overlap
          :value="profile.subscription"
          offset-x="25"
          offset-y="25"
          icon="mdi-license"
          color="warning"
        >
          <v-avatar v-if="profile.avatar" class="secondary" size="102">
            <v-img :src="imgPrefix + profile.avatar"></v-img>
          </v-avatar>

          <v-avatar color="secondary" v-if="!profile.avatar" size="102">
            <span class="text-h2">{{ profile.initials }}</span>
          </v-avatar>
        </v-badge>
      </v-list-item>

      <v-list-item-title
        class="title d-flex justify-center ma-2"
        v-if="profile"
      >
        {{ profile.first_name }} {{ profile.last_name }}
        <v-icon v-show="profile.linkedin_profile">mdi-linkedin</v-icon>
      </v-list-item-title>

      {{ profile.role }} {{ $t("profile.at") }}
      <v-btn
        class="text-capitalize"
        small
        text
        outlined
        color="primary"
        :href="profile.company_url"
        target="_blank"
        :disabled="!profile.company_url"
        >{{ profile.company }}</v-btn
      >
      <!-- <v-card-actions class="d-flex justify-center">
        <FollowProfile :profile="profile" />
      </v-card-actions> -->
      <v-card-actions class="d-flex justify-center">
        <ReviewsDialog :profile="profile" />
      </v-card-actions>

      <v-card class="pa-2" outlined>
        <span class="text-caption">{{ profile.summary }}</span>
      </v-card>
      <ProfileStatistics :profile="profile" />
      <v-expansion-panels v-if="profile.linkedin_profile" accordion>
        <v-expansion-panel :key="'linkedin-profile-acc-1'">
          <v-expansion-panel-header>
            Profil på LinkedIn <v-icon>mdi-linkedin</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column">
              <div class="text-h6">{{ profile.linkedin_profile.headline }}</div>
              <div class="text-subtitle-1">
                {{ profile.linkedin_profile.industry }}
              </div>
              <div class="text-caption">
                {{ profile.linkedin_profile.summary }}
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Företag</th>
                      <th class="text-left">Roll</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="company in linkedinCompanies"
                      :key="company.company"
                    >
                      <td class="text-left">{{ company.companyName }}</td>
                      <td class="text-left">{{ company.title }}</td>
                      <td class="text-left">
                        {{ companyDateRangeEmploment(company) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-spacer></v-spacer>
      <!-- <v-card-actions
        class="d-flex justify-center"
        v-if="showHandleProfileButtons(profile)"
      >
        <HandleProfile :item="extractRequests(profile)" />
      </v-card-actions> -->
    </v-list>
  </v-card>
</template>

<script>
import SettingsDialog from "@/components/usersettings/SettingsDialog";

import { mapGetters, mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import ReviewsDialog from "./ReviewsDialog";
import HandleProfile from "@/components/profile/HandleProfile";
import FollowProfile from "@/components/profile/FollowProfile";
import ProfileStatistics from "@/components/profile/ProfileStatistics";
import SignOut from "@/components/usersettings/SignOut";
export default {
  name: "ProfileCard",
  props: ["profile"],
  components: {
    ReviewsDialog,
    HandleProfile,
    SettingsDialog,
    ProfileStatistics,
    FollowProfile,
    SignOut,
  },
  methods: {
    ...mapActions([
      "followProfile",
      "followProfileRequest",
      "handleFollowingRequest",
      "fetchFollowerRequests",
    ]),
    companyDateRangeEmploment(item) {
      if (!item.dateRange.start) return;
      if (item.dateRange.start && item.dateRange.end) {
        return `${item.dateRange.start.year} - ${item.dateRange.end.year}`;
      } else {
        return `${item.dateRange.start.year} - nu`;
      }
    },
    unFollow(event) {
      event.preventDefault;
      var payload = {
        id: this.profile.id,
        user: this.getUser.id,
      };
      this.followProfile(payload);
    },
    onFollow() {
      this.disabledFollow = true;
      this.followProfileRequest(this.profile.id);
    },
    extractRequests(profile) {
      return this.getFollowerRequests.filter(
        (e) => e.from_user.id === profile.id
      )[0];
    },
    showHandleProfileButtons(item) {
      if (this.getFollowerRequests.some((e) => e.from_user.id === item.id)) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters(["getUser", "getFollowerRequests"]),
    linkedinCompanies() {
      const parsed_array = JSON.parse(
        this.profile.linkedin_profile.companies
      ).sort((a, b) =>
        a.dateRange.start.year < b.dateRange.start.year
          ? 1
          : b.dateRange.start.year < a.dateRange.start.year
          ? -1
          : 0
      );

      return parsed_array;
    },
  },
  mounted() {
    // this.fetchFollowerRequests();
  },
  beforeDestroy() {},
  data() {
    return {
      disabledFollow: false,
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    };
  },
  watch: {},
};
</script>

<style></style>
