<template>
  <div class="exchange">
    <MenuBar :routes="routes" :refresh="() => {}"></MenuBar>
    <v-row class="fill-height"
      ><v-col cols="12">
        <v-card class=" ">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col
        :sm="12"
        :lg="6"
        :xl="4"
        v-for="lead in filteredList"
        :key="lead.id"
      >
        <LeadCard :lead="lead" />
      </v-col>
      <v-col cols="12">
        <p class="ma-4 text-center" v-if="myLikes.length === 0">
          <v-icon x-large>mdi-bookmark-outline</v-icon><br />
          {{ $t("noLikesYet") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import LeadCard from "@/components/leadcard/LeadCard";

export default {
  name: "ExchangeLiked",
  components: {
    MenuBar,
    LeadCard,
  },
  methods: {
    ...mapActions(["fetchMyLikes"]),
  },
  computed: {
    ...mapGetters(["myLikes"]),
    filteredList: function() {
      const listItems = this.myLikes;
      return listItems
        .filter((item) => {
          return item.title.toLowerCase().includes(this.search.toLowerCase());
        })
        .filter((item) => {
          return item.body.toLowerCase().includes(this.search.toLowerCase());
        });
    },
  },

  created() {
    this.fetchMyLikes();
  },
  mounted() {},

  data: () => ({
    search: "",

    routes: [
      {
        route: "/exchange",
        icon: "mdi-swap-horizontal",
      },
      {
        route: "/pending",
        icon: "mdi-timer-sand",
      },
      {
        route: "/liked",
        icon: "mdi-bookmark-outline",
      },
    ],
  }),
};
</script>
