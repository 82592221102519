<template>
  <div class="exchange">
    <MenuBar :routes="routes" :refresh="() => {}"></MenuBar>
    <v-row>
      <v-col :sm="12" :lg="12" :xl="12" v-for="lead in myLeads" :key="lead.id">
        <LeadCard :lead="lead" />
      </v-col>
      <v-col cols="12">
        <LargeLoader v-if="isLoading" />
        <p class="ma-4 text-center" v-if="!isLoading && myLeads.length === 0">
          <v-icon x-large>mdi-storefront-outline</v-icon><br />
          {{ $t("noUploadedLeads") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import LeadCard from "@/components/leadcard/LeadCard";
import LargeLoader from "@/components/LargeLoader";

export default {
  name: "UploadedLeads",
  components: {
    MenuBar,
    LeadCard,
    LargeLoader,
  },
  methods: {
    ...mapActions(["fetchMyLeads"]),
  },
  computed: {
    ...mapGetters(["getUser", "myLeads", "isLoading"]),
  },

  created() {},
  mounted() {
    this.fetchMyLeads();
  },
  beforeDestroy() {},
  data: () => ({
    search: "",

    routes: [
      {
        route: "/wallet",
        icon: "mdi-wallet-outline",
        text: "nav.boughtCards",
      },
      {
        route: "/saved",
        icon: "mdi-bookmark-outline",
        text: "nav.savedCards",
      },

      {
        route: "/uploaded",
        icon: "mdi-storefront-outline",
        text: "nav.myCards",
      },
      {
        route: "/archived",
        icon: "mdi-archive-outline",
        text: "nav.archivedCards",
      },
    ],
  }),
};
</script>
