<template>
  <div>
    <v-select
      v-model="year"
      :items="taxYears"
      label="År"
      dense
      outlined
    ></v-select>
    <v-btn @click="generateCsv" :disabled="!getUser.is_premium || year === null"
      >Deklarationsunderlag {{ year }}

      <v-icon color="warning"> mdi-license </v-icon></v-btn
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";

export default {
  name: "TaxDeclarationReport",
  components: {},

  methods: {
    // ...mapActions([]),
    download(filename, text) {
      var element = document.createElement("a");
      var universalBOM = "\uFEFF";
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(universalBOM + text)
      );

      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    generateCsv() {
      if (this.year === null) return;

      axiosInstance
        .get(`/api/payments/tax_declaration_csv/${this.year}`)
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          this.download(
            `zwop_declaration_report_${this.year.toString()}.csv`,
            data
          );
          return response;
        })

        .catch((error) => console.error(error));
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    userHasSubscription() {
      if (this.getUser.subscription === null) return false;
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },

    taxYears() {
      let listOfYears = [];
      const firstYear = 2021;
      let yearNow = new Date().getFullYear();

      for (let index = yearNow - 1; index >= firstYear; index--) {
        listOfYears.push(index);
      }

      return listOfYears;
    },
  },

  created() {},
  mounted() {},

  data: () => ({ year: new Date().getFullYear() - 1 }),
};
</script>
