<template>
  <v-dialog v-model="dialogCancel" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text outlined color="error" v-bind="attrs" v-on="on">
        Ta bort konto</v-btn
      >
    </template>

    <v-card>
      <v-card-title>Radera konto</v-card-title>
      <v-card-text
        >För att bekräfta radering av konto vänligen fyll i din mailadress
        nedan. Observera att:

        <ul>
          <li>Ditt konto kommer inaktiveras och tas bort</li>
          <li>Du kommer loggas ut från Zwop</li>
        </ul>

        <v-text-field
          class="mt-4"
          v-model="check"
          outlined
          :label="$t('enterYourEmailHere')"
          :placeholder="getUser.email"
          autocomplete="off"
        ></v-text-field
      ></v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Ångra</v-btn>
        <v-btn
          color="error"
          :disabled="setCheckValue"
          @click.stop="deleteAccount"
          :loading="loading"
        >
          Ta bort konto</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axiosInstance from "../../services/axiosAPI";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "DeleteAccountDialog",
  data() {
    return {
      dialogCancel: false,
      check: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),

    setCheckValue() {
      if (this.check === "") return true;

      if (this.check.toLowerCase().trim() !== this.getUser.email) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["fetchUser", "responseInfo"]),
    closeDialog() {
      this.dialogCancel = false;
      this.check = "";
    },
    deleteAccount() {
      this.loading = true;
      return axiosInstance
        .get("/api/users/delete_account")
        .then((response) => {
          this.$router.push("/logout").catch(() => {});
          if (response.data.message === "account_deleted") {
            const info = {
              timeout: 10000,
              label: "Ok",
              icon: "mdi-alert",

              message: "Ditt konto är borttaget och du blev utloggad.",
            };
            this.responseInfo(info);
          }

          this.loading = false;

          this.closeDialog();
          return response;
        })

        .catch((error) => {
          this.loading = false;
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          console.log(error);
        });
    },
  },
};
</script>
<style lang=""></style>
