<template>
  <v-card class="pa-4" outlined>
    <div class="d-flex justify-space-between mb-2">
      <h3>{{ $t("feed.searchFilter") }}</h3>
      <v-btn small color="primary" @click="showFilterBox = !showFilterBox">
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </div>
    <div v-if="showFilterBox">
      <v-row dense>
        <v-col cols="12">
          <v-combobox
            v-model="getFilter.filterTags"
            :items="availableTags"
            :label="$t('feed.selectTags')"
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                small
                @click="select"
                @click:close="removeTag(item)"
              >
                <v-icon x-small left> mdi-tag-outline </v-icon>{{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="getFilter.filterSnis"
            :items="availableSnis"
            :label="$t('feed.filterOnSni')"
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                small
                @click="select"
                @click:close="removeSni(item)"
              >
                <v-icon x-small left> mdi-information-outline </v-icon
                >{{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getFilter.searchOnText"
            :label="$t('search')"
            single-line
            outlined
            clearable
            dense
            autocomplete="on"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="getFilter.priceMin"
            :items="priceList"
            :label="$t('feed.priceFrom')"
            dense
            outlined
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="getFilter.priceMax"
            :items="priceList"
            :label="$t('feed.priceTo')"
            dense
            clearable
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="getFilter.ratingMin"
            :items="ratingList"
            :label="$t('feed.ratingFrom')"
            dense
            outlined
            disabled
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="getFilter.ratingMax"
            :items="ratingList"
            :label="$t('feed.ratingTo')"
            dense
            outlined
            disabled
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-dialog
            ref="dialog"
            v-model="datePickerModal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                outlined
                dense
                :label="$t('feed.dateRange')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="getFilter.dateRange" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="" @click="datePickerModal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="success" @click="$refs.dialog.save(date)">
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn
          @click="saveFilter"
          :loading="false"
          color="success"
          elevation="2"
          small
          >{{ $t("findLeads") }}</v-btn
        >
        <v-btn
          @click="clearFilters"
          :loading="false"
          color="error"
          elevation="2"
          small
          text
        >
          {{ $t("feed.clearFilters") }}</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FilterBox",
  props: {},
  methods: {
    ...mapActions([
      "fetchTags",
      "fetchSnis",
      "globalFilter",
      "clearFilter",
      "removeTag",
      "removeSni",
      "getFilterSetting",
    ]),
    refreshFetch() {
      this.leadsInfiniteArray = [];
      this.currentPage = 1;
      this.hasNext = true;
      this.$emit("refreshFetch");
    },
    saveFilter() {
      let filterObj = {
        filterTags: this.getFilter.filterTags,
        filterSnis: this.getFilter.filterSnis,
        searchOnText: this.getFilter.searchOnText,
        priceMin: this.getFilter.priceMin,
        priceMax: this.getFilter.priceMax,
        ratingMin: this.getFilter.ratingMin,
        ratingMax: this.getFilter.ratingMax,
        dateRange: this.getFilter.dateRange,
      };
      this.globalFilter(filterObj);
      localStorage.setItem("filterSettings", JSON.stringify(filterObj));
      this.refreshFetch();
    },
    clearFilters(event) {
      event.preventDefault();
      this.clearFilter();
      localStorage.removeItem("filterSettings");
      this.leadsInfiniteArray = [];
      this.currentPage = 1;
      this.hasNext = true;
      this.$emit("refreshFetch");
    },
  },
  computed: {
    ...mapGetters(["allTags", "getFilter", "allSnis"]),
    dateRangeText() {
      return this.getFilter.dateRange.join(" -> ");
    },
    availableTags() {
      let tagsList = [];
      this.allTags.forEach((element) => {
        tagsList.push(element.name);
      });
      return tagsList;
    },
    availableSnis() {
      let snisList = [];
      this.allSnis.forEach((element) => {
        snisList.push(element.description);
      });
      return snisList;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  created() {
    this.fetchTags();
    this.fetchSnis();
  },
  data: () => ({
    showFilterBox: true,
    date: new Date().toISOString().substr(0, 10),
    datePickerModal: false,
    priceList: [
      50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750,
      800, 850, 900, 950, 1000,
    ],

    ratingList: [1, 2, 3, 4, 5],
  }),
};
</script>
<style scoped>
.sticky {
  position: sticky;
  top: 138px;
}
</style>
