<template>
  <v-bottom-navigation
    background-color="primary"
    class=""
    grow
    v-if="mobile"
    app
  >
    <AddLead />
  </v-bottom-navigation>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddLead from "@/components/leadcard/AddLead";

export default {
  components: { AddLead },
  data: () => ({
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,
  }),
  methods: {},
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
  },
};
</script>

<style scoped>
/*TODO Fix padding for iOS only*/
/* .ios-safe-bottom-nav {
  padding-bottom: 20px;
} */
</style>
