<template>
  <v-tooltip bottom open-delay="500" :disabled="mobile">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        v-if="canLeadBeArchived"
        class="ma-1"
        @click.stop="archiveLead"
      >
        <v-icon v-if="!item.is_archived" color=""
          >mdi-archive-arrow-down-outline</v-icon
        >
        <v-icon v-if="item.is_archived" color=""
          >mdi-archive-arrow-up-outline</v-icon
        >
      </v-btn>
    </template>
    <span v-if="!item.is_archived"> {{ $t("tooltip.archiveLead") }}</span>
    <span v-if="item.is_archived"> {{ $t("tooltip.unArchiveLead") }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ArchiveLead",
  props: ["item"],

  methods: {
    ...mapActions(["responseStatus", "leadArchive"]),
    archiveLead() {
      const payload = {
        id: this.item.id,
        lead: this.item,
      };
      this.leadArchive(payload);
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    canLeadBeArchived() {
      if (this.item.owner === null) return false;
      if (
        this.item.created_by.id != this.getUser.id &&
        this.item.owner.id === this.getUser.id
      )
        return true;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
