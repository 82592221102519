<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="ma-1">
        <v-icon color="red"> mdi-trash-can-outline </v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title> {{ $t("delete") }}</v-card-title>
      <v-card-text
        ><strong>{{ item.title }}</strong>
        {{ $t("deleteInfoText") }}</v-card-text
      >

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogDelete = false">
          {{ $t("cancel") }}</v-btn
        >
        <v-btn color="error" @click.stop="onDelete"> {{ $t("delete") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DeleteDialog",
  props: ["item"],
  methods: {
    ...mapActions(["leadInActivate"]),
    onDelete() {
      var payload = {
        id: this.item.id,
      };
      this.leadInActivate(payload);
      this.dialogDelete = false;
      // this.item = {};
    },
  },
  computed: { ...mapGetters(["isLoading"]) },
  created() {},

  data() {
    return {
      id: this.$route.params.id,
      dialogDelete: false,
    };
  },
};
</script>
