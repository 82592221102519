<template>
  <v-snackbar v-model="showInstallMessageIOS" timeout="20000">
    {{ $t("installOnIOS1") }} <v-icon>mdi-export-variant</v-icon>
    {{ $t("installOnIOS2") }}
  </v-snackbar>
</template>
<script>
export default {
  name: "IOSPopup",
  props: [],
  methods: {
    showInstallOnIos() {
      // Detects if device is in standalone mode
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;

      // Detects if device is on iOS
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.showInstallMessageIOS = false;
      }
    },
  },
  mounted() {
    this.showInstallOnIos();
  },

  data() {
    return { showInstallMessageIOS: false };
  },
};
</script>

<style scoped></style>
