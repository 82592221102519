<template>
  <div>
    <v-card-actions
      class="d-flex justify-center"
      v-if="!getUser.follows.includes(profile.id) && profile.id !== getUser.id"
    >
      <v-btn :disabled="disabledFollow" color="success" @click="onFollow">
        {{ $t("profile.follow") }}
      </v-btn>
      <v-btn
        color="error"
        @click="unFollow"
        v-if="getUser.follows.includes(profile.id) && profile.id !== getUser.id"
      >
        {{ $t("profile.unfollow") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FollowProfile",
  props: ["profile"],

  methods: {
    ...mapActions([
      "followProfile",
      "followProfileRequest",
      "handleFollowingRequest",
      "fetchFollowerRequests",
    ]),
    unFollow(event) {
      event.preventDefault;
      var payload = {
        id: this.profile.id,
        user: this.getUser.id,
      };
      this.followProfile(payload);
    },
    onFollow() {
      this.disabledFollow = true;
      this.followProfileRequest(this.profile.id);
    },
  },
  computed: {
    ...mapGetters(["getUser", "getFollowerRequests"]),
  },
  data() {
    return {
      disabledFollow: false,
    };
  },
};
</script>

<style></style>
