<template>
  <v-card outlined class="my-2 pa-3">
    <div
      class="d-flex flex-column flex-md-row justify-md-space-between"
      v-if="lead.contact_person.company"
    >
      <v-card-title style="word-break: break-word" class="">
        {{ lead.contact_person.company.name }}
      </v-card-title>

      <a
        class="text-decoration-none ma-4"
        :href="'https://allabolag.se/' + lead.contact_person.company.regno"
        target="_blank"
        v-if="lead.contact_person.company"
      >
        <v-chip class="" color="primary">
          <v-icon small left> mdi-barcode </v-icon>
          {{ regnoFormated }}</v-chip
        >
      </a>
    </div>

    <v-card-text class="d-flex flex-wrap">
      <v-card
        elevation="0"
        class="mr-4 mb-4 pa-0"
        v-if="lead.contact_person.company"
      >
        <h4>Verksamhetsbeskrivning</h4>
        <div class="mt-4" v-if="lead.contact_person.company.operation">
          {{ lead.contact_person.company.operation }}
        </div>
        Senast uppdaterad: {{ formatedTimestamp }}
        <div class="mt-4">
          <v-btn
            class="mr-4 mb-4 text-lowercase"
            :href="lead.contact_person.company.url"
            target="_blank"
            v-if="lead.contact_person.company.url"
            >{{ lead.contact_person.company.url }}
          </v-btn>
        </div>
      </v-card>

      <div
        class="d-flex justify-center align-center align-stretch"
        v-if="!lead.contact_person.company.operation"
      >
        <v-alert class="my-6" dense text color="error">
          <span class="">
            {{ $t("noCompanyOperationDataAvailable") }}
          </span>
        </v-alert>
      </div>
    </v-card-text></v-card
  >
</template>
<script>
import { format } from "date-fns";
export default {
  name: "LeadAllabolag",
  components: {},

  props: ["lead"],
  computed: {
    formatedTimestamp() {
      if (this.lead.contact_person.company === null) return "";

      const timestamp = format(
        new Date(this.lead.contact_person.company.updated_at),
        "yyyy-MM-dd HH:mm"
      );
      return timestamp;
    },
    regnoFormated() {
      const regno = this.lead.contact_person.company.regno;
      const firstPart = regno.slice(0, 6);
      const lastPart = regno.slice(6, 10);
      const formated = `${firstPart}-${lastPart}`;
      return formated;
    },
  },
};
</script>
<style>
.lead-detail-text-area p {
  margin-bottom: 5px;
}
</style>
