<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn text v-bind="attrs" v-on="on"> -->
      <div class="mb-2 mt-2 d-flex flex-row justify-center">
        <v-rating
          color="warning"
          length="5"
          readonly
          dense
          :size="20"
          :value="profile.rating"
        ></v-rating>
        ({{ profile.num_rated_leads }})
      </div>
      <!-- </v-btn> -->
    </template>
    <v-card>
      <v-card-title>{{ $t("reviews") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px">
        <div v-if="profile" class="">
          <div v-for="lead in leadsInfiniteArray" :key="lead.id">
            <v-card class="pa-4 mt-2" v-if="lead.review">
              <strong>{{ lead.review }}</strong>
              {{ lead.rating }}
              <p v-if="lead.owner" @click="openProfile(lead.owner)">
                <v-avatar class="secondary" size="30">
                  <v-img
                    v-if="lead.owner.avatar"
                    :src="imgPrefix + lead.owner.avatar"
                  >
                  </v-img>
                </v-avatar>
                {{ lead.owner.first_name }} {{ lead.owner.last_name }}
              </p>
            </v-card>
          </div>
          <div>
            <p class="ma-4 text-center" v-if="leadsInfiniteArray.length === 0">
              <v-icon x-large>mdi-comment-quote-outline</v-icon><br />
              {{ $t("profile.noReviewsYet") }}
            </p>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog = false">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "ReviewsDialog",
  props: ["profile"],
  components: {},
  methods: {
    getLeads() {
      if (!this.profile.id) return;
      axiosInstance
        .get(
          `${API_URL}api/leads/?page=${this.currentPage}&text=&tags=&user=${this.profile.id}`
        )
        .then((response) => {
          const data = response.data;

          this.hasNext = false;
          if (data.next) {
            this.hasNext = true;
          }
          data.results.map((result) => this.leadsInfiniteArray.push(result));
          return response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openProfile(item) {
      this.$router.push("/user/" + item.id).catch(() => {});
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  created() {
    this.getLeads();
    // window.onscroll = () => {
    //   let bottomOfWindow =
    //     document.documentElement.scrollTop + window.innerHeight ===
    //     document.documentElement.offsetHeight;
    //   if (bottomOfWindow && this.hasNext) {
    //     this.currentPage += 1;
    //     this.getLeads();
    //   }
    // };
  },
  mounted() {},

  data: () => ({
    dialog: false,
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    leadsInfiniteArray: [],
    currentPage: 1,
    nextIsLoading: false,
    hasNext: true,
  }),
};
</script>
