var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap justify-end"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",on:{"click":function($event){$event.stopPropagation();return _vm.acceptExchange()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.acceptZwop")))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",on:{"click":function($event){$event.stopPropagation();return _vm.rejectExchange()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.rejectZwop")))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('ExchangeCompare',{attrs:{"offeredLead":_vm.item.offered_lead,"wantedLead":_vm.item.wanted_lead}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.compare")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }