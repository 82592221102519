<template>
  <div class="exchange">
    <MenuBar :routes="routes" :refresh="() => {}"></MenuBar>
    <v-row>
      <v-col
        :sm="12"
        :lg="12"
        :xl="12"
        v-for="lead in filteredList"
        :key="lead.id"
      >
        <LeadCard :lead="lead" />
      </v-col>
      <v-col cols="12">
        <LargeLoader v-if="isLoading" />
        <p
          class="ma-4 text-center"
          v-if="!isLoading && filteredList.length === 0"
        >
          <v-icon x-large>mdi-archive-outline</v-icon><br />
          {{ $t("archiveIsEmpty") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import LeadCard from "@/components/leadcard/LeadCard";
import LargeLoader from "@/components/LargeLoader";

export default {
  name: "ArchivedLeads",
  components: {
    MenuBar,
    LeadCard,
    LargeLoader,
  },
  methods: {
    ...mapActions(["fetchOwnedLeads"]),

    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },
  },
  computed: {
    ...mapGetters(["getUser", "myOwnedLeads", "isLoading"]),

    filteredList: function () {
      const listItems = this.myOwnedLeads;
      return listItems
        .filter((item) => {
          return item.is_archived;
        })
        .filter((item) => {
          return item.title.toLowerCase().includes(this.search.toLowerCase());
        })
        .filter((item) => {
          return item.body.toLowerCase().includes(this.search.toLowerCase());
        });
    },
  },

  created() {
    this.fetchOwnedLeads();
  },
  mounted() {},

  data: () => ({
    search: "",

    routes: [
      {
        route: "/wallet",
        icon: "mdi-wallet-outline",
        text: "nav.boughtCards",
      },
      {
        route: "/saved",
        icon: "mdi-bookmark-outline",
        text: "nav.savedCards",
      },

      {
        route: "/uploaded",
        icon: "mdi-storefront-outline",
        text: "nav.myCards",
      },
      {
        route: "/archived",
        icon: "mdi-archive-outline",
        text: "nav.archivedCards",
      },
    ],
  }),
};
</script>
