<template>
  <v-row class=""
    ><v-col md="6" cols="12" class="">
      <v-card class="d-flex align-center justify-center">
        <v-card-title>
          <DepositDialog block />
          <WithdrawalDialog
        /></v-card-title>
      </v-card>
    </v-col>
    <v-col md="6" cols="12" class="">
      <v-card class="" color="">
        <v-card-title>
          <h3>
            {{ $t("account.balance") }}
          </h3>
        </v-card-title>

        <v-card-text class="text-h4 font-weight-bold">
          {{ getUserAccount.balance }} kr
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WithdrawalDialog from "./WithdrawalDialog";
import DepositDialog from "./DepositDialog";
export default {
  name: "Balance",
  components: { WithdrawalDialog, DepositDialog },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters(["getUserAccount"]),
  },
};
</script>
