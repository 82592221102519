import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import VueClipboard from "vue-clipboard2";
import { VueMasonryPlugin } from "vue-masonry";
// import "vue-tour/dist/vue-tour.css";

// import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

// import VueTour from "vue-tour";
import vBlur from "v-blur";

import VueHtmlToPaper from "vue-html-to-paper";
import VOffline from "v-offline";

Vue.use(VOffline);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper);

// Vue.use(VueTour);
Vue.use(vBlur);
Vue.use(VueMasonryPlugin);

// import './registerServiceWorker'
import wb from "./registerServiceWorker";

import i18n from "./i18n";
Vue.prototype.$workbox = wb;

Vue.config.productionTip = false;

// // use this package's plugin
// Vue.use(TiptapVuetifyPlugin, {
//   // the next line is important! You need to provide the Vuetify Object to this place.
//   vuetify, // same as "vuetify: vuetify"
//   // optional, default to 'md' (default vuetify icons before v2.0.0)
//   iconsGroup: "mdi",
// });

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
