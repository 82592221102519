<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item>
        <v-list-item-content @click="">Compare</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content @click="acceptExchange(item)"
          >Zwop</v-list-item-content
        >
      </v-list-item>
      <v-list-item>
        <v-list-item-content @click="rejectExchange(item)"
          >Reject zwop</v-list-item-content
        >
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Edit</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Delete</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeleteDialog from "@/components/leadcard/DeleteDialog";
import EditDialog from "@/components/leadcard/EditDialog";
import HandleLead from "@/components/leadcard/HandleLead";
export default {
  name: "ActionMenu",
  props: ["item"],
  components: {
    DeleteDialog,
    EditDialog,
    HandleLead,
  },
  methods: {
    ...mapActions(["leadExchange"]),

    acceptExchange(item) {
      var payload = {
        id: item.id,
        action: "accept",
        offering: true,
      };
      this.leadExchange(payload);
    },

    rejectExchange(item) {
      var payload = {
        id: item.id,
        action: "reject",
        offering: true,
      };
      this.leadExchange(payload);
    },
  },
  data: () => ({}),
};
</script>

<style>
v-list-item-content {
  cursor: pointer;
}
</style>
