<template>
  <div class="d-flex justify-center align-center bank-id-info">
    <v-card class="pa-10 ">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <p>{{ usersName }}</p>
      <v-img max-width="200px" src="@/assets/Zwop_Logo_Color.png"></v-img>
      <v-img max-width="200px" src="@/assets/logo-bank-id.svg"></v-img>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["loadUserBankID"]),
  },
  mounted() {
    this.grandSessionId = this.$route.query.grandidsession;
    this.loadUserBankID(this.grandSessionId).then((response) => {
      this.usersName = response.grandapi.userAttributes.name;
    });
    setTimeout(() => {
      this.$router.push("/").catch(() => {});
    }, 1000);
  },
  data() {
    return {
      grandSessionId: null,
      usersName: null,
    };
  },
};
</script>

<style>
i {
  background-size: 20px 30px;
}

.bank-id-info {
  height: 100vh;
}
</style>
