<template>
  <div class="account">
    <div class="mb-2 align-center justify-space-between">
      <div class="mb-2">
        <Balance />
      </div>
      <div class="">
        <Transactions />
        <Invoices />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MenuBar from "@/components/navigation/MenuBar";
import Balance from "./Balance";
import Transactions from "./Transactions";
import Invoices from "./Invoices";
import WithdrawalDialog from "./WithdrawalDialog";

export default {
  name: "Account",
  components: {
    "menu-bar": MenuBar,
    Balance,
    Transactions,
    WithdrawalDialog,
    Invoices,
  },
  methods: {
    ...mapActions(["fetchUserAccount", "fetchTransactions", "fetchInvoices"]),
    reFetchData() {
      this.fetchUserAccount();
      this.fetchTransactions();
      return;
    },
  },
  computed: {
    ...mapGetters(["getUserAccount"]),
  },

  created() {
    this.fetchTransactions();
    this.fetchInvoices();
  },
  mounted() {},

  data: () => ({
    routes: [
      {
        route: "/dashboard",
        icon: "mdi-view-dashboard-outline",
        tooltip: "tooltip.overview",
      },
    ],
  }),
};
</script>
