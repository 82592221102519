import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Market from "../views/market/Market.vue";
import MyLeads from "../views/myleads/MyLeads.vue";
import SavedLeads from "../views/myleads/SavedLeads.vue";
import Wallet from "../views/myleads/Wallet.vue";
import ArchivedLeads from "../views/myleads/ArchivedLeads.vue";
import UploadedLeads from "../views/myleads/UploadedLeads.vue";
import LeadDetail from "../views/leaddetail/LeadDetail.vue";
import Login from "../views/Login.vue";
import PasswordReset from "../views/PasswordReset.vue";
import SignUp from "../views/SignUp.vue";
import BankIDCallbackPage from "../views/BankIDCallbackPage.vue";
import DepositSuccessPage from "../views/DepositSuccessPage.vue";
import DepositFailPage from "../views/DepositFailPage.vue";
import Messages from "../views/Messages.vue";
import Administration from "../views/administration/Administration.vue";

import Profile from "../views/profile/Profile.vue";
// TODO Remove old Review page
// import Reviews from "../views/profile/old_Reviews.vue";
import NavbarMain from "../components/navigation/NavbarMain.vue";
import AppBar from "../components/navigation/AppBar.vue";
// import Wallet from "../views/wallet/Wallet.vue";
import Network from "../views/network/Network.vue";
import Exchange from "../views/exchange/Exchange.vue";
import ExchangeLiked from "../views/exchange/ExchangeLiked.vue";

import Backlog from "../views/wallet/Backlog.vue";
import Search from "../views/search/Search.vue";
import KanbanBoard from "../views/wallet/KanbanBoard.vue";
import ExchangePending from "../views/exchange/ExchangePending.vue";
// import NetworkTable from "../views/network/NetworkTable.vue";
// import NetworkDashboard from "../views/network/NetworkDashboard.vue";
// import Account from "../views/account/Account.vue";

import Logout from "../views/Logout.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:code?",
    name: "Login",
    component: Login,
    meta: {
      title: "Zwop | Logga in",
    },
  },
  {
    path: "/signup/:code?",
    name: "Signup",
    component: SignUp,
    meta: {
      title: "Zwop | Skapa konto",
    },
  },
  {
    path: "/bankid",
    name: "BankID",
    component: BankIDCallbackPage,
  },
  {
    path: "/deposit-success",
    name: "DepositSuccess",
    component: DepositSuccessPage,
  },
  {
    path: "/deposit-fail",
    name: "DepositFail",
    component: DepositFailPage,
  },

  {
    path: "",
    name: "",
    component: AppBar,
    children: [
      {
        path: "/password-reset",
        name: "Password reset",
        component: PasswordReset,
      },
      {
        path: "/only-for-admin",
        name: "Administration",
        component: Administration,
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/search/:query",
        name: "Search",
        component: Search,
      },
      {
        path: "/",
        name: "Market",
        component: Market,
        meta: {
          title: "Zwop | Marknad",
        },
      },
      {
        path: "/user/:id",
        name: "Profile",
        component: Profile,
        meta: {
          title: "Zwop | Profil",
        },
      },
      // {
      //   path: "/reviews/:id",
      //   name: "Reviews",
      //   component: Reviews,
      // },

      {
        path: "/leadcard/:slug",
        name: "Detail",
        component: LeadDetail,
        meta: {
          title: "Zwop | ",
        },
      },

      {
        path: "/table",
        name: "Wallet Table",
        component: Wallet,
      },
      {
        path: "/exchange",
        name: "Exchange",
        component: Exchange,
      },
      {
        path: "/wallet",
        name: "wallet",
        component: Wallet,
        meta: {
          title: "Zwop | Köpta leadkort",
        },
      },
      {
        path: "/myleads",
        name: "MyLeads",
        component: MyLeads,
      },
      {
        path: "/archived",
        name: "Archived",
        component: ArchivedLeads,
        meta: {
          title: "Zwop | Arkiverade leadkort",
        },
      },
      {
        path: "/uploaded",
        name: "Uploaded",
        component: UploadedLeads,
        meta: {
          title: "Zwop | Mina leadkort",
        },
      },
      {
        path: "/pending",
        name: "Pending",
        component: ExchangePending,
      },
      {
        path: "/saved",
        name: "Saved",
        component: SavedLeads,
        meta: {
          title: "Zwop | Sparade leadkort",
        },
      },
      {
        path: "/liked",
        name: "Liked",
        component: ExchangeLiked,
      },

      {
        path: "/network",
        name: "Network",
        component: Network,
      },
      // {
      //   path: "/network-table",
      //   name: "Network Table",
      //   component: NetworkTable,
      // },
      // {
      //   path: "/network-dashboard",
      //   name: "Network Dashboard",
      //component: NetworkDashboard,
      // },
      {
        path: "/backlog",
        name: "Wallet Backlog",
        component: Backlog,
      },

      {
        path: "/board",
        name: "Wallet Board",
        component: KanbanBoard,
      },
      // TODO - Add this feature later
      // {
      //   path: '/messages',
      //   name: 'Messages',
      //   component: Messages,
      // },
      // {
      //   path: "/account",
      //   name: "Account",
      //   component: Account,
      // },
      {
        path: "/logout",
        name: "Logout",
        component: Logout,
      },
      {
        path: "*",
        redirect: "/",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeResolve((to, from, next) => {
//   if (to.path) {
//     NProgress.start()
//   }
//   next()
// });

router.beforeEach((to, from, next) => {
  // document.title = process.env.VUE_APP_TITLE;
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/signup", "/bankid", "/password-reset"];
  const authRequired = !publicPages.includes(to.path);
  // const loggedIn = localStorage.getItem('user');
  const loggedIn = store.getters.isLoggedIn;

  if (to.params.code) {
    localStorage.setItem("zwop_inviteCode", to.params.code);
  }

  if (authRequired && !loggedIn) {
    if (to.path.includes("/signup")) {
      next("/signup");
    } else {
      next("/login");
    }
  }

  next();
});

// router.afterEach(() => {
//   setTimeout(() => NProgress.done(), 500)
// })

export default router;
