<template>
  <v-app>
    <OnboardingStepper />

    <v-app-bar app clipped-left color="primary">
      <v-btn dark text plain :to="'/'">
        <v-badge
          color="success"
          offset-x="40"
          offset-y="20"
          content="beta"
          class="hidden-sm-and-down"
        >
          <v-img
            contain
            max-height="45"
            max-width="160"
            src="@/assets/Zwop_Logo_Color.png"
            class="hidden-sm-and-down"
          ></v-img
        ></v-badge>
        <v-badge
          color="success"
          offset-x="20"
          offset-y="20"
          content="beta"
          class="hidden-md-and-up"
        >
          <v-img
            max-height="45"
            max-width="45"
            src="@/assets/Zwop_Logo_Z.png"
            class="hidden-md-and-up float-left"
          ></v-img
        ></v-badge>
      </v-btn>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <AddLead :editMode="false" v-if="!mobile" />
        <!-- <FilterRow @refreshMarketFetch="refreshMarketFetch" /> -->

        <v-btn class="ml-1 mr-1" dark :icon="mobile" :text="!mobile" :to="'/'">
          <v-icon>mdi-storefront-outline</v-icon>
          <span v-if="!mobile">Marknad</span>
        </v-btn>
        <v-btn
          class="ml-1 mr-1"
          dark
          :icon="mobile"
          :text="!mobile"
          :to="'/wallet'"
        >
          <v-icon>mdi-view-dashboard-outline</v-icon>
          <span v-if="!mobile">Leadkort</span>
        </v-btn>

        <NotificationMenu />
        <v-btn
          v-if="!mobile"
          dark
          @click="() => {}"
          small
          text
          class="ml-1 mr-1"
        >
          <v-icon left> mdi-cash-multiple </v-icon>
          {{ getUserAccount.balance ? getUserAccount.balance : "..." }}
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn icon v-bind="attrs" v-on="on">
                <v-badge
                  overlap
                  :value="getUser.is_premium"
                  offset-x="7"
                  offset-y="7"
                  dot
                  color="warning"
                >
                  <v-avatar v-if="getUser.avatar" class="secondary" size="30">
                    <v-img :src="imgPrefix + getUser.avatar"> </v-img
                  ></v-avatar>
                  <v-avatar class="secondary" v-if="!getUser.avatar" size="30">
                    <span class="text-h7">{{ getUser.initials }}</span>
                  </v-avatar>
                </v-badge>
              </v-btn>
            </div>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn small text :to="'/user/' + getUser.id">
                  <v-icon>mdi-account-circle-outline</v-icon>
                  <span class="text-small"> {{ $t("nav.profile") }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <SettingsDialog />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <QrCodeDialog :user="getUser" :campain="true" />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title
                ><v-btn small text :to="'/logout'">
                  <v-icon>mdi-logout</v-icon>
                  <span class="text-small"> {{ $t("nav.logout") }}</span>
                </v-btn></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <SubscriptionCard :profile="getUser" :menuButton="true" />
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="getUser.is_superuser">
              <v-list-item-title
                ><v-btn small text :to="'/only-for-admin'">
                  <v-icon>mdi-account-cowboy-hat-outline</v-icon>
                  <span class="text-small"> Admin</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title
                ><v-btn
                  class=""
                  text
                  color=""
                  :href="`https://zwop.io/contact?user=${getUser.id}`"
                  target="_blank"
                  small
                >
                  <v-icon>mdi-lifebuoy</v-icon>
                  <span class="text-small"> Supportärende</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- <v-app-bar-nav-icon :to="'/user/' + getUser.id"
        ><v-avatar v-if="getUser.avatar" class="secondary" size="30">
          <v-img :src="imgPrefix + getUser.avatar"> </v-img
        ></v-avatar>
        <v-avatar class="secondary" v-if="!getUser.avatar" size="30">
          <span class="text-h7">{{ getUser.initials }}</span>
        </v-avatar>
      </v-app-bar-nav-icon> -->
    </v-app-bar>

    <router-view></router-view>
    <BottomNav />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FilterRow from "@/components/FilterRow";
import BottomNav from "@/components/navigation/BottomNav";
import NotificationMenu from "@/components/navigation/NotificationMenu";
import AddLead from "@/components/leadcard/AddLead";
import SettingsDialog from "@/components/usersettings/SettingsDialog";
import QrCodeDialog from "@/components/navigation/QrCodeDialog";
import Connectivity from "@/components/navigation/Connectivity";
import OnboardingStepper from "@/components/OnboardingStepper";
import SubscriptionCard from "@/components/profile/SubscriptionCard";

export default {
  name: "AppBar",
  components: {
    NotificationMenu,
    AddLead,
    BottomNav,
    SettingsDialog,
    FilterRow,
    QrCodeDialog,
    Connectivity,
    OnboardingStepper,
    SubscriptionCard,
  },
  created() {
    this.fetchNotifications();
    this.fetchUserAccount();
    this.fetchTags();
  },
  mounted() {
    this.fetchUser();
  },
  beforeMount() {
    this.fetchUser();
  },

  methods: {
    ...mapActions([
      "fetchUser",
      "editUser",
      "fetchNotifications",
      "fetchUserAccount",
      "toggleNavDrawer",
      "fetchTags",
    ]),

    refreshMarketFetch() {
      console.log("AppBar refresh search");
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "isLoggedIn",
      "isLoading",

      "getUserAccount",
      "getNavDrawer",
    ]),

    userHasSubscription() {
      if (this.getUser.subscription === null) return false;
      if (
        this.getUser.subscription &&
        new Date(this.getUser.subscription.valid_through) < new Date()
      )
        return false;
      return true;
    },

    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  data: () => ({
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    appVersion: process.env.VUE_APP_VERSION,
  }),
};
</script>

<style></style>
