<template>
  <div class="mb-4">
    <LeadCard :lead="item" :gridCols="12" />
  </div>
</template>

<script>
import LeadCard from "@/components/leadcard/LeadCard";

export default {
  name: "TaskLaneItem",
  props: ["item"],
  components: {
    LeadCard,
  },
  methods: {
    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },
  },
};
</script>
