<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <a href="#" v-bind="attrs" v-on="on">{{ $t("forgotPassword") }}</a>
    </template>

    <v-card class="pa-4">
      <v-card-title>{{ $t("passwordReset") }}</v-card-title>
      <v-card-text>
        <v-form @submit="onSubmit" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :label="$t('email')"
            outlined
            autocomplete="off"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{
          $t("cancel")
        }}</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          type="submit"
          @click.stop="onSubmit"
          >{{ $t("send") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PasswordResetDialog",
  props: [],
  methods: {
    ...mapActions(["requestPasswordReset"]),
    onSubmit() {
      const cleanedEmail = this.email.toLowerCase().trim();
      this.requestPasswordReset(cleanedEmail);
      this.dialog = false;
      this.email = "";
    },
  },
  computed: { ...mapGetters([]) },
  created() {},

  data() {
    return {
      id: this.$route.params.id,
      dialog: false,
      valid: true,
      email: "",
      emailRules: [
        (v) => !!v || "Obligatorisk",
        (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
      ],
    };
  },
};
</script>
