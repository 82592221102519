import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
const state = {
  allUsers: [],
  conversation: {},
  message: {},
  currentRecipient: { email: "", id: null },
  sessionKey: "",
};

const getters = {
  allUsers: (state) => state.allUsers,
  getConversation: (state) => state.conversation,
  getMessage: (state) => state.message,
  getCurrentRecipient: (state) => state.currentRecipient,
};

const actions = {
  updateUserList({ commit }) {
    axiosInstance
      .get(API_URL + "api/chat/v1/user/")
      .then((response) => {
        commit("setAllUsers", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  fetchConversation({ commit }, recipient) {
    axiosInstance
      .get(API_URL + `api/chat/v1/message/?target=${recipient}`)
      .then((response) => {
        commit("setConversation", response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // getMessageById({ commit }, message) {
  //   let id = JSON.parse(message).message
  //   axiosInstance.get(API_URL + `api/chat/v1/message/${id}/`)
  //     .then((response) => {
  //       commit("setMessage", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
  sendMessage({ commit }, payload) {
    axiosInstance
      .post(API_URL + "api/chat/v1/message/", payload)
      .then((response) => {
        console.log("SEND MESSAGE", response);
        commit("sendMessage", response.data);
      })
      .catch((error) => {
        console.log("SEND MESSAGE", error);
      });
  },

  setCurrentRecipient({ commit }, payload) {
    commit("setCurrentRecipient", payload);
    // this.getConversation(currentRecipient);
  },
};

const mutations = {
  setAllUsers: (state, payload) => (state.allUsers = payload),
  setConversation: (state, payload) => (state.conversation = payload),
  sendMessage: (state) => state,
  setMessage: (state, payload) => {
    state.message = payload;
    state.conversation.push(payload);
  },
  setCurrentRecipient: (state, currentRecipient) => {
    state.currentRecipient.email = currentRecipient.email;
    state.currentRecipient.id = currentRecipient.id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
