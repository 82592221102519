<template>
  <!-- TODO Fix this page -->
  <div>
    <div v-for="(item, index) in getSearch.data" :key="index" class="">
      <v-col cols="12">
        <v-card @click="openProfile(item)" class="pa-4">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h5"
                v-text="item.first_name + ' ' + item.last_name"
              ></v-card-title>

              <v-card-subtitle v-text="item.company"></v-card-subtitle>
            </div>

            <v-avatar class="ma-3" size="60">
              <v-img :src="imgPrefix + item.avatar"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Search",
  methods: {
    ...mapActions(["globalSearch"]),
    openProfile(item) {
      this.$router.push("/user/" + item.id).catch(() => {});
    },
  },
  mounted() {
    console.log("MOUNTED", this.query);
    this.globalSearch(this.query);
  },
  computed: {
    ...mapGetters(["getSearch"]),
  },
  data() {
    return {
      query: this.$route.params.query,
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
    };
  },
  watch: {
    query() {},
  },
};
</script>
