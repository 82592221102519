<template>
  <v-card class="pa-3 mb-2" outlined>
    <div
      class="d-flex flex-row justify-space-between"
      v-if="lead.contact_person.company.linkedin_page"
    >
      <v-card-title
        ><v-icon>mdi-linkedin</v-icon>
        {{ lead.contact_person.company.linkedin_page.name }},
        {{ lead.contact_person.company.linkedin_page.company_type }}
      </v-card-title>

      <a
        class="text-decoration-none ma-4"
        :href="lead.contact_person.company.linkedin_url"
        target="_blank"
        v-if="lead.contact_person.company"
      >
        <v-chip class="" color="primary">
          <v-icon small left> mdi-linkedin </v-icon>
          {{ lead.contact_person.company.linkedin_page.universal_name }}</v-chip
        >
      </a>
    </div>

    <v-card-text
      class="d-flex flex-wrap"
      v-if="lead.contact_person.company.linkedin_page"
    >
      <v-card elevation="0" class="mr-4 mb-4 pa-0">
        <h4>
          {{ lead.contact_person.company.linkedin_page.tagline }}
        </h4>
        <div>
          {{ lead.contact_person.company.linkedin_page.description }}
        </div>
        Senast uppdaterad: {{ formatedTimestamp }}
      </v-card>

      <v-card
        elevation="0"
        class="mr-4 mb-4 pa-0"
        v-if="lead.contact_person.company.linkedin_page"
      >
        <div>
          <h4>Antal anställda</h4>
          {{ lead.contact_person.company.linkedin_page.staff_count }}
        </div>
        <div>
          <v-btn
            small
            :href="
              lead.contact_person.company.linkedin_page.job_search_page_url
            "
            target="_blank"
            class="mt-4"
          >
            Lediga jobb på
            {{ lead.contact_person.company.linkedin_page.name }}
          </v-btn>
        </div>
      </v-card>
      <v-card elevation="0" class="mr-4 mb-4 pa-0" v-if="headquarter.line1">
        <h4>Huvudkontor</h4>
        {{ headquarter.line1 }},
        {{ headquarter.postalCode }}
        {{ headquarter.city }}

        <div v-show="lead.contact_person.company.linkedin_page.phone">
          <br />
          Telefon: {{ lead.contact_person.company.linkedin_page.phone }}
        </div>
      </v-card>
      <v-card elevation="0" class="mr-4 mb-4 pa-0" v-if="specialities">
        <h4>Nyckelord</h4>

        <div v-for="(service, i) in specialities" :key="`speciality-${i}`">
          {{ service }}
        </div>
      </v-card>
      <v-card elevation="0" class="mr-4 mb-4 pa-0">
        <h4>Andra kontor</h4>

        <div v-for="(office, i) in offices" :key="`office-${i}`">
          {{ office.line1 }},
          {{ office.postalCode }}
          {{ office.city }},
          {{ office.country }}
        </div>
      </v-card>
      <v-card elevation="0" class="mr-4 mb-4 pa-0" v-if="funding">
        <h4>Finansiering</h4>

        <div>Antal investeringsrundor: {{ funding.numFundingRounds }}</div>
        <div>
          <a :href="funding.fundingRoundListCrunchbaseUrl"
            >Crunchbase for Round</a
          >
        </div>
        <div>
          <a :href="funding.investorsCrunchbaseUrl">Crunchbase for Investor</a>
        </div>
        <div>
          <a :href="funding.companyCrunchbaseUrl">Crunchbase for Company</a>
        </div>
      </v-card>
    </v-card-text>

    <div
      v-if="!lead.contact_person.company.linkedin_page"
      class="mt-4 text-center"
      width="200px"
    >
      <v-alert class="my-6" dense text color="error">
        <span class="">
          Ingen data från LinkedIn är tillgänglig (kommer snart)
        </span>
      </v-alert>
    </div>
  </v-card>
</template>
<script>
import { format } from "date-fns";
export default {
  name: "LeadLinkedin",
  components: {},

  props: ["lead"],
  computed: {
    formatedTimestamp() {
      if (this.lead.contact_person.company.linkedin_page === null) return "";

      const timestamp = format(
        new Date(this.lead.contact_person.company.linkedin_page.timestamp),
        "yyyy-MM-dd HH:mm"
      );
      return timestamp;
    },
    regnoFormated() {
      const regno = this.lead.contact_person.company.regno;
      const firstPart = regno.slice(0, 6);
      const lastPart = regno.slice(6, 10);
      const formated = `${firstPart}-${lastPart}`;
      return formated;
    },
    headquarter() {
      const parsed = JSON.parse(
        this.lead.contact_person.company.linkedin_page.headquarter
      );
      return parsed;
    },
    funding() {
      const parsed = JSON.parse(
        this.lead.contact_person.company.linkedin_page.funding_data
      );
      return parsed;
    },

    specialities() {
      const parsed_array = JSON.parse(
        this.lead.contact_person.company.linkedin_page.specialities
      ).sort();

      return parsed_array;
    },
    offices() {
      const parsed_array = JSON.parse(
        this.lead.contact_person.company.linkedin_page.confirmed_locations
      ).sort((a, b) =>
        a.country > b.country ? 1 : b.country > a.country ? -1 : 0
      );

      return parsed_array;
    },
  },
};
</script>
<style>
.lead-detail-text-area p {
  margin-bottom: 5px;
}
</style>
