<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text small v-bind="attrs" v-on="on">
        <v-icon>mdi-cog-outline</v-icon>
        <span class="text-small"> {{ $t("settings") }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("settings") }}</v-card-title>
      <v-divider></v-divider>

      <v-card-text style="height: 800px">
        <BankIDVerifyDialog
          :user="getUser"
          v-if="!getUser.personal_identity_number_verified"
        />
        <VerifyPhone :phone="getUser.phone" v-if="!getUser.phone_verified" />

        <div class="ma-4" v-if="missingUserSettings">
          <v-alert text prominent type="error" icon="mdi-account-check-outline">
            <v-row align="center">
              <v-col class="grow">
                {{ $t("settingsDialog.fillOutYourInfo") }}
              </v-col>
            </v-row>
          </v-alert>
        </div>

        <v-list three-line subheader>
          <v-subheader>{{ $t("settingsDialog.profile") }}</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>LinkedIn</v-list-item-title>
              <v-list-item-subtitle
                >Information hämtad från din profil på LinkedIn (kommer
                snart)</v-list-item-subtitle
              >
              <LinkedinDialog />

              <div v-if="getUser.linkedin_profile">
                <div class="text-h6">
                  {{ getUser.linkedin_profile.headline }}
                </div>
                <div class="text-subtitle-1">
                  {{ getUser.linkedin_profile.industry }}
                </div>
                <div class="text-caption">
                  {{ getUser.linkedin_profile.summary }}
                </div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Företag</th>
                        <th class="text-left">Roll</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="company in linkedinCompanies"
                        :key="company.company"
                      >
                        <td class="text-left">{{ company.companyName }}</td>
                        <td class="text-left">{{ company.title }}</td>
                        <td class="text-left">
                          {{ companyDateRangeEmploment(company) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-if="!getUser.linkedin_profile">
                Ingen profilinformation hämtad från LinkedIn
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("settingsDialog.information")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("settingsDialog.informationSub")
              }}</v-list-item-subtitle>
              <v-row class="mt-4 d-flex justify-center">
                <div id="preview" class="ma-4">
                  <v-avatar v-if="avatarUrl" class="secondary" size="100">
                    <v-img :src="avatarUrl"></v-img>
                  </v-avatar>
                  <v-avatar
                    color="secondary"
                    v-if="!getUser.avatar && !avatarUrl"
                    class="mr-2"
                    size="100"
                  >
                    <span class="text-h3">{{ getUser.initials }}</span>
                  </v-avatar>
                  <v-avatar
                    v-if="!avatarUrl && getUser.avatar"
                    class="secondary"
                    size="100"
                  >
                    <v-img :src="imgPrefix + getUser.avatar"></v-img>
                  </v-avatar>
                </div>
                <div
                  color=""
                  outlined
                  class="pa-5 ma-3 text-center bg-gray-100 border"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <div class="mb-4" v-for="file in filelist" :key="file.name">
                    <strong>{{ file.name }} </strong>
                    <button
                      class="ml-2"
                      type="button"
                      @click="remove(filelist.indexOf(file))"
                      title="Remove file"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </button>
                  </div>
                  <input
                    :rules="[rules.maxFileSize]"
                    type="file"
                    name="fields[assetsFieldHandle][]"
                    id="assetsFieldHandle"
                    class="opacity-0"
                    @change="onChange"
                    ref="file"
                    multiple
                    accept=".jpg,.jpeg,.png"
                  />
                  <label for="assetsFieldHandle" class="">
                    <div>
                      {{ $t("settingsDialog.dragNDrop1") }}

                      <span class="text-decoration-underline">
                        {{ $t("settingsDialog.dragNDrop2") }}</span
                      >
                      {{ $t("settingsDialog.dragNDrop3") }}
                    </div>
                  </label>
                </div>
              </v-row>
              <v-row v-if="mobile"> </v-row>
              <v-form ref="editForm" v-model="valid" lazy-validation>
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.first_name"
                      :rules="[rules.required]"
                      :label="$t('settingsDialog.firstName')"
                      maxlength="100"
                      required
                      outlined
                      autocomplete="given-name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.last_name"
                      :rules="[rules.required]"
                      :label="$t('settingsDialog.lastName')"
                      maxlength="100"
                      required
                      outlined
                      autocomplete="family-name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.role"
                      :rules="[rules.required]"
                      :label="$t('settingsDialog.role')"
                      :hint="$t('settingsDialog.roleHint')"
                      persistent-hint
                      maxlength="100"
                      required
                      outlined
                      autocomplete="on"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.company"
                      :rules="[rules.required]"
                      :label="$t('settingsDialog.company')"
                      :hint="$t('settingsDialog.companyHint')"
                      persistent-hint
                      maxlength="100"
                      required
                      outlined
                      autocomplete="on"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.company_url"
                      :label="
                        $t('settingsDialog.companyUrl') +
                        ' (' +
                        $t('addLeadDialog.optional') +
                        ')'
                      "
                      maxlength="100"
                      :hint="$t('settingsDialog.companyUrlHint')"
                      persistent-hint
                      outlined
                      autocomplete="url"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field
                      v-model="getUser.linkedin"
                      :label="
                        $t('settingsDialog.linkedin') +
                        ' (' +
                        $t('addLeadDialog.optional') +
                        ')'
                      "
                      maxlength="100"
                      :hint="$t('settingsDialog.linkedinHint')"
                      persistent-hint
                      outlined
                    ></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="getUser.summary"
                      :label="$t('settingsDialog.bio')"
                      :hint="$t('settingsDialog.bioHint')"
                      persistent-hint
                      outlined
                      counter
                      maxlength="1000"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.email"
                      :rules="emailRules"
                      :label="
                        $t('settingsDialog.email') +
                        ' (' +
                        $t('settingsDialog.onlyForYou') +
                        ')'
                      "
                      required
                      outlined
                      :append-icon="
                        getUser.is_verified ? 'mdi-check-circle-outline' : ''
                      "
                      autocomplete="email"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.phone"
                      :rules="[rules.required]"
                      :label="
                        $t('settingsDialog.phone') +
                        ' (' +
                        $t('settingsDialog.onlyForYou') +
                        ')'
                      "
                      :hint="$t('settingsDialog.phoneHint')"
                      persistent-hint
                      maxlength="20"
                      required
                      outlined
                      :append-icon="
                        getUser.phone_verified ? 'mdi-check-circle-outline' : ''
                      "
                      autocomplete="tel-national"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="getUser.personal_identity_number"
                      :disabled="true"
                      :label="
                        $t('settingsDialog.personal_identity_number') +
                        ' (' +
                        $t('settingsDialog.onlyForYou') +
                        ')'
                      "
                      required
                      outlined
                      :hint="$t('settingsDialog.personal_identity_numberHint')"
                      persistent-hint
                      :append-icon="
                        getUser.personal_identity_number_verified
                          ? 'mdi-check-circle-outline'
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>Uttag</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Bankuppgifter</v-list-item-title>
              <v-list-item-subtitle
                >Bankkoto kopplat till uttag</v-list-item-subtitle
              >
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="banks"
                  v-model="getUser.bank"
                  name="Bank"
                  item-text="name"
                  label="Bank"
                  :hint="'Bankens namn, ex. Swedbank, Handelsbanken, SEB etc.'"
                  persistent-hint
                  outlined
                ></v-select
              ></v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="getUser.bank_clearing"
                  :label="'Clearingnummer'"
                  outlined
                  counter
                  :hint="
                    getUser.bank
                      ? 'Ditt clearingnummer i intervallet ' +
                        this.possibleClearingRange
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="getUser.bank_account"
                  :label="'Bankkontonummer'"
                  :disabled="getUser.bank === ''"
                  outlined
                  counter
                  @change="bankAccountHasChanged()"
                  :hint="
                    getUser.bank
                      ? 'Ditt kontonummer, ' +
                        this.accountNumberLength +
                        ' siffor.'
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="d-flex justify-end">
                <!-- <v-btn
                  v-if="bankAccountHasChanged()"
                  color="success"
                  :loading="bankAccountLoading"
                  @click="handleSaveWithBankID"
                >
                  Spara uttagskonto
                </v-btn> -->

                <v-alert text type="warning" v-if="bankAccountHasChanged()"
                  >Observera att du kommer behöva verifiera dig med BankID på
                  nytt efter att du sparat ditt nya kontonummer. Detta för att
                  vi vill säkerställa att det är just du som ändrat dessa
                  uppgifter.</v-alert
                >
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>{{ $t("settingsDialog.security") }}</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("settingsDialog.changePassword")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("settingsDialog.changePasswordSub")
              }}</v-list-item-subtitle>

              <v-form ref="" v-model="valid" lazy-validation>
                <v-row class="mt-4">
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="oldPassword"
                      :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showOldPassword ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$t('settingsDialog.oldPassword')"
                      outlined
                      @click:append="showOldPassword = !showOldPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="password"
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.password]"
                      :type="showNewPassword ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$t('settingsDialog.password')"
                      hint="At least 8 characters"
                      counter
                      outlined
                      @click:append="showNewPassword = !showNewPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      block
                      v-model="verify"
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.password, passwordMatch]"
                      :type="showNewPassword ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$t('settingsDialog.confirmPassword')"
                      counter
                      outlined
                      @click:append="showNewPassword = !showNewPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="d-flex justify-end">
                    <v-btn
                      :loading="passwordLoading"
                      :disabled="passwordLoading || passwordSaveSuccess"
                      @click="handlePasswordChange"
                      color="success"
                      >{{
                        passwordSaveSuccess
                          ? $t("settingsDialog.saved")
                          : $t("settingsDialog.applyChangedPassword")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>{{ $t("settingsDialog.notifications") }}</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="importantInfoMail" disabled></v-switch>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Viktig information</v-list-item-title>
              <v-list-item-subtitle
                >Denna typ av avisering går inte att stänga av eftersom vi måste
                kunna nå dig med viktig information via
                mail</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="getUser.email_notifications"></v-switch>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("settingsDialog.notificationMail")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("settingsDialog.notificationMailDescription")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="getUser.email_newsletter"></v-switch>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("settingsDialog.news")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("settingsDialog.newsDescription")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>{{ $t("settingsDialog.appTheme") }}</v-subheader>

          <v-list-item>
            <v-list-item-action>
              <v-radio-group v-model="getUser.app_theme" row>
                <v-radio
                  label="Ljust"
                  value="light"
                  @click="darkMode"
                ></v-radio>
                <v-radio label="Mörkt" value="dark" @click="darkMode"></v-radio>
                <v-radio
                  :label="'Synkronisera med systemet (' + systemDarkMode + ')'"
                  value="system"
                ></v-radio>
              </v-radio-group>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-subheader
            >{{ $t("settingsDialog.language") }} (kommer snart)
          </v-subheader>
          <v-list-item>
            <v-list-item-action>
              <div class="locale-changer">
                <v-select
                  v-model="$i18n.locale"
                  :items="languages"
                  :label="$t('settingsDialog.language')"
                  outlined
                  disabled
                  :rules="[rules.required]"
                  @change="appLanguageSet"
                >
                  <option
                    v-for="(lang, i) in languages"
                    :key="`Lang${i}`"
                    :value="lang"
                  >
                    {{ lang.text }}
                  </option>
                </v-select>
              </div>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      
          <v-divider></v-divider>
          <v-subheader> Radera hela mitt konto på Zwop</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <DeleteAccountDialog />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClose">
          {{ $t("cancel") }}
        </v-btn>

        <v-btn color="success" :disabled="!valid" @click="handleSave">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import { mapGetters, mapActions, mapState } from "vuex";
import VerifyPhone from "./VerifyPhone";
import LinkedinDialog from "./LinkedinDialog";
import DeleteAccountDialog from "./DeleteAccountDialog";
import BankIDVerifyDialog from "../leadcard/BankIDVerifyDialog";
export default {
  components: {
    VerifyPhone,
    BankIDVerifyDialog,
    LinkedinDialog,
    DeleteAccountDialog,
  },

  methods: {
    ...mapActions([
      "fetchUser",
      "editUser",
      "saveAvatar",
      "responseStatus",
      "loginBankID",
    ]),

    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.avatar = this.filelist[0];
      this.avatarUrl = URL.createObjectURL(this.avatar);
      console.log(this.filelist);
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.avatar = null;
      this.avatarUrl = null;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    testErrorEmailing() {
      axiosInstance
        .get(`${API_URL}api/users/error_tester/`)
        .then((response) => {
          console.log("Error tester success", response);
        })
        .catch((error) => {
          console.log("Error tester error", error);
        });
    },
    handlePasswordChange(event) {
      event.preventDefault();
      var payload = {
        old_password: this.oldPassword,
        new_password: this.password,
      };
      this.passwordLoading = true;
      axiosInstance
        .patch(`${API_URL}api/users/password-change/`, payload)
        .then((response) => {
          this.passwordLoading = false;
          this.passwordSaveSuccess = true;
          this.password = "";
          this.verify = "";
          this.oldPssword = "";
          this.responseStatus({
            code: response.status,
            text: response.statusText,
            message: response.data?.message,
          });
          return response;
        })
        .catch((error) => {
          this.passwordLoading = false;
        });
    },

    onClose() {
      this.fetchUser();
      this.dialog = !this.dialog;
    },

    handleSaveWithBankID() {
      // TODO Fix logic for saving new bank details
      // if (
      //   !this.getUser.bank &&
      //   !this.getUser.bank_clearing &&
      //   this.getUser.bank_account.length !== this.accountNumberLength
      // )
      //   return;

      this.bankAccountLoading = true;

      const payload = {
        bank: this.getUser.bank,
        bank_clearing: this.getUser.bank_clearing,
        bank_account: this.getUser.bank_account,
      };

      this.loginBankID(this.getUser.personal_identity_number).then(() => {
        this.bankAccountLoading = false;
        this.handleSave();
      });
    },

    handleSave(event) {
      event.preventDefault();
      const payload = {
        first_name: this.getUser.first_name,
        last_name: this.getUser.last_name,
        email: this.getUser.email,
        role: this.getUser.role,
        company: this.getUser.company,
        company_url: this.getUser.company_url,
        linkedin: this.getUser.linkedin,
        phone: this.getUser.phone,
        personal_identity_number: this.getUser.personal_identity_number,
        bank: this.getUser.bank,
        bank_clearing: this.getUser.bank_clearing,
        bank_account: this.getUser.bank_account,
        summary: this.getUser.summary,
        email_notifications: this.getUser.email_notifications,
        email_newsletter: this.getUser.email_newsletter,
        app_theme: this.$vuetify.theme.dark ? "dark" : "light",
        language: this.$i18n.locale,
      };
      // TODO Not working . Password is reset if avatar i uploaded
      if (this.avatar) {
        const formData = new FormData();
        formData.append("avatar", this.avatar);
        formData.append("email", this.getUser.email);

        axiosInstance
          .patch("api/users/upload_avatar/", formData)
          .then(function (response) {
            console.log(response);
            // this.responseStatus({
            //   code: response.status,
            //   text: response.statusText,
            //   message: response.data?.message,
            // });
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      this.editUser(payload);
      this.fetchUser();
      this.dialog = !this.dialog;
    },

    onCopy: function (e) {
      this.inviteCopyLabel = "Link copied!";
      this.inviteCopyColor = "success";
      setTimeout(() => {
        this.inviteCopyColor = "primary";
        this.inviteCopyLabel = "Invite code";
      }, 500);
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      localStorage.setItem(
        "theme",
        this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
    appLanguageSet() {
      localStorage.setItem("language", this.$i18n.locale);
      console.log("LOCALE:", this.$i18n.locale);
    },
    companyDateRangeEmploment(item) {
      if (!item.dateRange.start) return;
      if (item.dateRange.start && item.dateRange.end) {
        return `${item.dateRange.start.year} - ${item.dateRange.end.year}`;
      } else {
        return `${item.dateRange.start.year} - nu`;
      }
    },
    bankAccountHasChanged() {
      return this.initialBankAccount !== this.getUser.bank_account;
    },
  },
  computed: {
    ...mapGetters(["getUser"]),

    systemDarkMode() {
      let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;

      return dark ? "Dark" : "Light";
    },

    linkedinCompanies() {
      const parsed_array = JSON.parse(
        this.getUser.linkedin_profile.companies
      ).sort((a, b) =>
        a.dateRange.start.year < b.dateRange.start.year
          ? 1
          : b.dateRange.start.year < a.dateRange.start.year
          ? -1
          : 0
      );

      return parsed_array;
    },
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
    missingUserSettings() {
      if (
        !this.getUser.first_name ||
        !this.getUser.last_name ||
        !this.getUser.role ||
        !this.getUser.company
      ) {
        return true;
      } else {
        return false;
      }
    },

    possibleClearingRange() {
      const selectedBank = this.banks.filter(
        (bank) => bank.name === this.getUser.bank
      )[0];

      if (selectedBank) {
        return selectedBank.clearingRange.toString();
      }
      return "";
    },
    accountNumberLength() {
      const selectedBank = this.banks.filter(
        (bank) => bank.name === this.getUser.bank
      )[0];

      if (selectedBank) {
        return parseInt(selectedBank.maxNumAccountDigits, 10);
      }

      return "";
    },

    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  mounted() {
    this.fetchUser();
    this.initialBankAccount = this.getUser.bank_account;
  },
  data() {
    return {
      imgPrefix: process.env.VUE_APP_IMG_PREFIX,
      filelist: [], // Store our uploaded files
      initialBankAccount: "",

      dialog: false,
      importantInfoMail: true,
      themes: ["Light", "Dark"],
      languages: [
        { value: "sv", text: "Svenska" },

        { value: "en", text: "English" },
      ],
      valid: true,
      appTheme: "light",
      oldPassword: "",
      password: "",
      verify: "",
      passwordSaveSuccess: false,
      passwordLoading: false,
      bankAccountLoading: false,
      avatar: null,
      avatarUrl: null,
      emailRules: [
        (v) => !!v || "Obligatorisk",
        (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
      ],
      bankAccountRules: [
        (v) =>
          (v && v >= this.accountNumberLength) ||
          `Kontonumrets längd ska vara ${this.accountNumberLength} siffror`,
      ],
      banks: [
        {
          name: "Amfa Bank",
          clearingRange: ["9660", "9669"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Avanza Bank",
          clearingRange: ["9550", "9569"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Bluestep Finans",
          clearingRange: ["9680", "9689"],
          maxNumAccountDigits: "7",
        },
        {
          name: "BNP Paribas Fortis",
          clearingRange: ["9470", "9479"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Citibank International Plc, Sweden Branch",
          clearingRange: ["9040", "9049"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Danske Bank A/S, Sverige Filial",
          clearingRange: ["2400", "2499"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Danske Bolån",
          clearingRange: ["1200", "1399"],
          maxNumAccountDigits: "7",
        },
        {
          name: "DNB Bank ASA, filial Sverige",
          clearingRange: ["9190", "9199"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Forex Bank AB",
          clearingRange: ["9400", "9449"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Handelsbanken",
          clearingRange: ["6000", "6999"],
          maxNumAccountDigits: "9",
        },
        {
          name: "ICA Banken",
          clearingRange: ["9270", "9279"],
          maxNumAccountDigits: "7",
        },
        {
          name: "IKANO Banken",
          clearingRange: ["9170", "9179"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Länsförsäkringar Bank (clearing 9020-9029)",
          clearingRange: ["9020", "9029"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Länsförsäkringar Bank (clearing 3400-3409)",
          clearingRange: ["3400", "3409"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Länsförsäkringar Bank (clearing 9060-9069)",
          clearingRange: ["9060", "9069"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Lån og Spar Bank Sverige",
          clearingRange: ["9630", "9639"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Marginalen Bank",
          clearingRange: ["9230", "9239"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Nordax Bank AB",
          clearingRange: ["9640", "9649"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Nordea",
          clearingRange: [
            "1100",
            "1199",
            "1401",
            "2099",
            "3000",
            "3299",
            "3301",
            "3399",
            "3410",
            "4999",
          ],
          maxNumAccountDigits: "7",
        },
        {
          name: "Nordea - Personkonto",
          clearingRange: ["3300", "3300"],
          maxNumAccountDigits: "10",
        },
        {
          name: "Nordea - Personkonto (11 siffror)",
          clearingRange: ["3300", "3300"],
          maxNumAccountDigits: "11",
        },
        {
          name: "Nordea - PlusGirot",
          clearingRange: ["9500", "9549", "9960", "9969"],
          maxNumAccountDigits: "10",
        },
        {
          name: "Nordnet Bank",
          clearingRange: ["9100", "9109"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Resurs Bank AB",
          clearingRange: ["9280", "9289"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Royal Bank of Scotland",
          clearingRange: ["9090", "9099"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Santander Consumer Bank AS",
          clearingRange: ["9460", "9469"],
          maxNumAccountDigits: "7",
        },
        {
          name: "SBAB",
          clearingRange: ["9250", "9259"],
          maxNumAccountDigits: "7",
        },
        {
          name: "SEB",
          clearingRange: ["5000", "5999", "9120", "9124", "9130", "9149"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Skandiabanken AB",
          clearingRange: ["9150", "9169"],
          maxNumAccountDigits: "7",
        },
        {
          name: "Sparbanken Syd",
          clearingRange: ["9570", "9579"],
          maxNumAccountDigits: "10",
        },
        {
          name: "Sparbanken Öresund",
          clearingRange: ["9300", "9349"],
          maxNumAccountDigits: "10",
        },
        {
          name: "Swedbank",
          clearingRange: ["7000", "7999", "8000", "8999"],
          maxNumAccountDigits: "10",
        },
        {
          name: "Ålandsbanken Abp (Finland), svensk filial",
          clearingRange: ["2300", "2399"],
          maxNumAccountDigits: "7",
        },
        {
          name: ">Landshypotek Bank",
          clearingRange: ["9390", "9399"],
          maxNumAccountDigits: "7",
        },
      ],
      webpageRules: [
        (v) =>
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v
          ) || "Hemsideadressen måste vara giltig",
      ],

      showNewPassword: false,
      showOldPassword: false,
      rules: {
        notBlank: (value) => value === "" || "Obligatorisk",
        required: (value) => !!value || "Obligatorisk",
        min: (v) => (v && v.length >= 5) || "Min. 5 tecken",
        maxFileSize: (value) =>
          !value ||
          value.size < 5000000 ||
          "Avatarstorleken måste vara mindre än 5 MB!",
        password: (v) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/.test(
            v
          ) ||
          "Lösenordet måste innehålla minst en liten bokstav, en stor bokstav, en siffra och ett specialtecken",
      },
    };
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
.opacity-0 {
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.bg-green-300 {
  background-color: #cef0d1cb;
}

.bg-gray-100 {
}
.border {
  border: 1px solid #a8a8a8;
  border-radius: 4px;
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
