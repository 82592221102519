<template>
  <div :id="item.reference_no" class="pa-4">
    <table width="100%" cellpadding="0" cellspacing="0" role="presentation">
      <tr>
        <td width="80%">
          <h1
            class="sm-text-lg all-font-ubuntu"
            style="
              font-weight: 700;
              line-height: 100%;
              margin: 0;
              margin-bottom: 4px;
              font-size: 24px;
            "
          >
            Transaktion
          </h1>
          <p
            class="sm-text-xs"
            style="margin: 0; color: #a0aec0; font-size: 14px"
          >
            {{ new Date(item.timestamp) | date("yyyy-MM-dd HH:mm") }}
          </p>
        </td>
        <td style="text-align: right" width="20%" align="right">
          <v-btn class="ma-1" @click="print(item.reference_no)">
            <v-icon>mdi-printer-outline</v-icon>
            {{ $t("print") }}</v-btn
          >
          <v-btn disabled class="ma-1" @click="downloadPdf(item)">
            <v-icon>mdi-download-outline</v-icon>
            PDF</v-btn
          >
          <v-btn
            v-if="item.payment"
            class="ma-1"
            @click="goToStripeReceipt(item.payment.receipt_url)"
          >
            <v-icon>mdi-receipt-outline</v-icon>
            Stripe</v-btn
          >
        </td>
      </tr>
    </table>
    <div style="line-height: 32px">&zwnj;</div>
    <table
      class="sm-leading-32"
      style="line-height: 28px; font-size: 14px"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tr>
        <td class="sm-inline-block" style="" width="50%">Transaktionstyp</td>
        <td
          class="sm-inline-block"
          style="font-weight: 600; text-align: right"
          width="50%"
          align="right"
        >
          {{ item.transaction_type }} {{ thirdPartySolution(item) }}
        </td>
      </tr>
      <tr>
        <td class="sm-inline-block" style="" width="50%">Referensnummer</td>
        <td
          class="sm-inline-block"
          style="font-weight: 600; text-align: right"
          width="50%"
          align="right"
        >
          {{ item.reference_no }}
        </td>
      </tr>

      <tr v-if="item.lead">
        <td class="sm-w-1-4 sm-inline-block" style="" width="50%">
          Förmedlingsavgift inkl. moms
        </td>
        <td
          class="sm-w-3-4 sm-inline-block"
          style="font-weight: 600; text-align: right"
          width="50%"
          align="right"
        >
          {{ fee(item) }} kr
        </td>
      </tr>
      <tr v-if="item.lead">
        <td class="sm-w-1-4 sm-inline-block" style="" width="50%">Moms</td>
        <td
          class="sm-w-3-4 sm-inline-block"
          style="font-weight: 600; text-align: right"
          width="50%"
          align="right"
        >
          {{ parseFloat(fee(item) * 0.25).toFixed(2) }} kr
        </td>
      </tr>
      <tr v-if="item.lead">
        <td class="sm-w-1-4 sm-inline-block" style="" width="50%">Leadkort</td>
        <td
          class="sm-w-3-4 sm-inline-block"
          style="font-weight: 600; text-align: right"
          width="50%"
          align="right"
        >
          <a :href="`/leadcard/${item.lead.slug}`">{{ item.lead.title }}</a>
        </td>
      </tr>
    </table>
    <table width="100%" cellpadding="0" cellspacing="0" role="presentation">
      <tr>
        <td style="padding-top: 24px; padding-bottom: 24px">
          <div style="background-color: #edf2f7; height: 2px; line-height: 2px">
            &zwnj;
          </div>
        </td>
      </tr>
    </table>

    <table
      style="line-height: 28px; font-size: 14px"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tr>
        <td style="font-weight: 600; text-align: left" width="50%" align="left">
          {{ getUser.first_name }} {{ getUser.last_name }}
        </td>
      </tr>
      <tr>
        <td style="font-weight: 600; text-align: left" width="50%" align="left">
          {{ getUser.email }}
        </td>
      </tr>
      <tr>
        <td style="font-weight: 600; text-align: left" width="50%" align="left">
          {{ getUser.phone }}
        </td>
      </tr>
      <tr>
        <td style="font-weight: 600; text-align: left" width="50%" align="left">
          {{ getUser.id }}
        </td>
      </tr>
    </table>
    <table
      style="line-height: 28px; font-size: 14px"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tr>
        <td
          style="
            font-weight: 600;
            padding-top: 32px;

            font-size: 20px;
          "
          width="50%"
        >
          Totalt belopp
        </td>
        <td
          style="
            font-weight: 600;
            padding-top: 32px;
            text-align: right;

            font-size: 20px;
          "
          width="50%"
          align="right"
        >
          {{ amountInOrOut(item) }} kr
        </td>
      </tr>
    </table>
    <div style="text-align: center">
      <br />
      <img
        style="max-width: 150px"
        src="@/assets/Zwop_Logo_Color.png"
        alt="Zwop"
      /><br />
      Zwop Technology AB - Ranhammarsvägen 20, 168 67 Bromma - zwop.io -
      hello@zwop.io
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateFilter, format } from "vue-date-fns";
import { jsPDF } from "jspdf";
export default {
  name: "Receipt",
  props: ["item"],
  components: {},
  filters: {
    date: dateFilter,
  },
  methods: {
    ...mapActions([]),
    amountInOrOut(transaction) {
      let amount = 0.0;
      if (transaction.account_sender === this.getUserAccount.id) {
        const fee = transaction.fee_sender
          ? parseFloat(transaction.fee_sender.amount)
          : 0.0;
        amount = (parseFloat(transaction.amount) + fee) * -1.0;
      } else {
        const fee = transaction.fee_receiver
          ? parseFloat(transaction.fee_receiver.amount)
          : 0.0;

        amount = (parseFloat(transaction.amount) - fee) * +1.0;
      }

      return parseFloat(amount).toFixed(2);
    },

    fee(transaction) {
      let fee = 0.0;
      if (transaction.account_sender === this.getUserAccount.id) {
        fee = transaction.fee_sender
          ? parseFloat(transaction.fee_sender.amount)
          : 0.0;
      } else {
        fee = transaction.fee_receiver
          ? parseFloat(transaction.fee_receiver.amount)
          : 0.0;
      }
      return parseFloat(fee).toFixed(2);
    },

    thirdPartySolution(transaction) {
      if (transaction.payment !== null) {
        return "(Stripe)";
      }
      if (transaction.coinbase_charge !== null) {
        return "(Coinbase)";
      }
      if (transaction.zimpler_bank_transaction !== null) {
        return "(Zimpler)";
      }
      if (transaction.swish_transaction !== null) {
        return "(Swish)";
      }

      return "";
    },

    goToStripeReceipt(link) {
      window.open(link, "_blank");
    },
    // TODO Implementera för utskrift av kvitton
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(id);
    },

    async downloadPdf(item) {
      console.log(item);
      let doc = new jsPDF();

      doc.text(`Transaktion`, 20, 20);
      doc.text(`${item.timestamp}`, 20, 30);
      doc.text(`${item.transaction_type}`, 150, 20);
      doc.text(`Referensnummer: ${item.reference_no}`, 20, 40);
      doc.text(`Förmedlingsavgift inkl. moms: ${this.fee(item)}`, 20, 60);
      doc.text(`Moms: ${this.fee(item) * 0.25}`, 20, 70);
      doc.text(`${this.getUser.first_name} ${this.getUser.last_name}`, 20, 90);
      doc.text(`${this.getUser.id}`, 20, 100);
      doc.text(`${this.getUser.phone}`, 20, 110);
      doc.text(`${this.getUser.email}`, 20, 120);
      doc.text(`Totalt belopp: ${this.amountInOrOut(item)}`, 20, 170);
      doc.text(`Zwop Technology AB`, 20, 200);
      doc.text(`Ranhammarsvägen 20, 168 67 Bromma`, 20, 210);
      doc.text(`www.zwop.io - hello@zwop.io`, 20, 220);

      doc.save(`${item.reference_no}.pdf`);
    },
  },
  computed: {
    ...mapGetters(["getUserAccount", "getUser"]),
  },
};
</script>

<style></style>
