<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="600px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="linkOnly">
          <a class="mr-2 text-decoration-underline" v-bind="attrs" v-on="on"
            >Villkor</a
          >
          <a
            class="mr-2"
            href="mailto:help@zwop.io"
            target="_blank"
            rel="noopener noreferrer"
            >Hjälp</a
          >
          <a
            class="mr-2"
            href="https://zwop.io/"
            target="_blank"
            rel="noopener noreferrer"
            >zwop.io</a
          >
        </div>
        <div v-if="!linkOnly">
          <v-btn
            :disabled="disabled"
            color=""
            v-bind="attrs"
            v-on="on"
            class="text-lowercase"
          >
            <v-checkbox
              v-model="acceptTerms"
              :label="$t('acceptTerms')"
            ></v-checkbox>
            <span class="text-decoration-underline"> </span>
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("termsOfServiceHeadline") }} </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-8">
          <vue-markdown>{{ terms }}</vue-markdown>
          <vue-markdown>{{ copyrightPolicy }}</vue-markdown>
          <vue-markdown>{{ privacyPolicy }}</vue-markdown>
          <vue-markdown>{{ personalDataPolicy }}</vue-markdown>
          <vue-markdown>{{ cookiePolicy }}</vue-markdown>
          <a
            class="mr-2"
            href="https://zwop.io/terms"
            target="_blank"
            rel="noopener noreferrer"
            >Användarvillkor</a
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!linkOnly">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="disagree">
            {{ $t("disagree") }}
          </v-btn>
          <v-btn color="success" @click="agree">
            {{ $t("agree") }}
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="linkOnly">
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialog = false"> Stäng </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import VueMarkdown from "vue-markdown";

export default {
  components: { VueMarkdown },
  props: ["disabled", "linkOnly"],
  methods: {
    agree() {
      this.dialog = false;
      this.acceptTerms = true;
      this.$emit("clicked", "acceptedTerms");
    },
    disagree() {
      this.dialog = false;
      this.acceptTerms = false;
      this.$emit("clicked", "declinedTerms");
    },
  },

  mounted() {},
  created() {
    axios
      .get(`${process.env.VUE_APP_STRAPI_URL}api/terms`, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
        },
      })
      .then((response) => {
        this.terms = response.data.data.attributes.text;
      })
      .catch((e) => console.log(e));
    axios
      .get(`${process.env.VUE_APP_STRAPI_URL}api/privacy-policy`, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
        },
      })
      .then((response) => {
        this.privacyPolicy = response.data.data.attributes.text;
      });
    axios
      .get(`${process.env.VUE_APP_STRAPI_URL}api/copyright-policy`, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
        },
      })
      .then((response) => {
        this.copyrightPolicy = response.data.data.attributes.text;
      });
    axios
      .get(`${process.env.VUE_APP_STRAPI_URL}api/personal-data-policy`, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
        },
      })
      .then((response) => {
        this.personalDataPolicy = response.data.data.attributes.text;
      });
    axios
      .get(`${process.env.VUE_APP_STRAPI_URL}api/cookies`, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
        },
      })
      .then((response) => {
        this.cookiePolicy = response.data.data.attributes.text;
      });
  },

  data() {
    return {
      dialog: false,
      acceptTerms: false,
      terms: "",
      cookiePolicy: "",
      privacyPolicy: "",
      personalDataPolicy: "",
      copyrightPolicy: "",
    };
  },
};
</script>
