<template>
  <div id="app" class="w-screen h-screen">
    {{ getError }}
    <v-offline
      @detected-condition="amIOnline"
      online-class="online"
      offline-class="offline"
    >
      <template v-if="online"> </template>
      <template v-if="!online">
        <v-alert dense outlined text type="error" icon="mdi-wifi-alert">
          {{ $t("offlineInfo") }}
        </v-alert>
      </template>
    </v-offline>
    <div v-if="isServerDown">
      <v-alert dense outlined text type="error" icon="mdi-server-off">
        {{ $t("serverDown") }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { VOffline } from "v-offline";
import { mapGetters } from "vuex";
export default {
  components: {
    VOffline,
  },
  data() {
    return {
      online: true,
    };
  },
  methods: {
    amIOnline(e) {
      this.online = e;
    },
  },
  computed: {
    ...mapGetters(["getError"]),
    isServerDown() {
      console.log(this.getError);
      if (this.getError === null) return;
      if (this.getError) {
        return getError.code == 503 ? true : false;
      }
      return false;
    },
  },
};
</script>
<style lang="scss"></style>
