var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 d-flex flex-column",attrs:{"hover":true,"color":_vm.purchaseIndicator}},[_c('div',{staticClass:"caption grey-text d-flex justify-end"},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(_vm.lead.created_at),"dd MMMM yyyy"))+" ")]),_c('div',{staticClass:"d-flex justify-space-between pl-4"},[_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return _vm._l((_vm.lead.tags),function(tag){return _c('v-chip',_vm._g(_vm._b({key:tag.slug,staticClass:"mr-1 mb-1",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickOnTag(tag.name)}}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" mdi-tag-outline ")]),_vm._v(_vm._s(tag.name))],1)})}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.tags")))])])],1),(_vm.lead.price && !_vm.lead.owner)?_c('div',{staticClass:"d-flex flex-column justify-content-space lead-price"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"success","label":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.leadTotalPrice)+" kr ")],1)]}}],null,false,3670601524)},[_c('div',{staticClass:"text-center"},[_c('v-chip',{attrs:{"dark":"","color":"","label":"","outlined":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-cut")]),_vm._v(" "+_vm._s(_vm.leadFee)+" kr ")],1),_c('br'),_c('span',[_vm._v(" Inklusive betald förmedlingsavgift")]),_vm._v(" "),_c('br'),(!_vm.getUser.is_premium)?_c('span',[_vm._v("Skaffa "),_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-license ")]),_vm._v("Premium för att sänka avgiften")],1):_vm._e()],1)])],1):_vm._e(),(!_vm.lead.price)?_c('div',{staticClass:"lead-price"},[_c('v-chip',{attrs:{"color":"warning","label":""}},[_vm._v(" "+_vm._s(_vm.$t("zwopOnly")))])],1):_vm._e()]),_c('div',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.openDetail(_vm.lead)}}},[_c('v-card-title',{staticClass:"d-flex justify-space-between pb-1 pt-1",staticStyle:{"word-break":"break-word"}},[_c('h3',[_vm._v(_vm._s(_vm.lead.title))])]),_c('v-card-text',{staticClass:"pb-1"},[_c('div',{staticClass:"lead-card-text-area mb-1",domProps:{"innerHTML":_vm._s(_vm.lead.body)}}),_vm._l((_vm.lead.contact_person.company.sni.slice(0, 3)),function(sni){return _c('div',{key:sni.code},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","disabled":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mb-1",attrs:{"color":"","outlined":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" "+_vm._s(sni.description)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(sni.description))])])],1)})],2)],1),_c('v-spacer'),(!_vm.lead.hidden_creator)?_c('v-card-actions',{staticClass:"pl-4 pb-0"},[_c('v-menu',{attrs:{"close-on-content-click":false,"open-on-hover":"","rounded":"","top":"","offset-y":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","value":_vm.lead.created_by.is_premium,"offset-x":"25","offset-y":"15","icon":"mdi-license","color":"warning"}},[(_vm.lead.created_by.avatar)?_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"secondary"}},[(_vm.lead.created_by)?_c('v-img',{staticClass:"elevation-6",attrs:{"alt":"","src":_vm.imgPrefix + _vm.lead.created_by.avatar}}):_vm._e()],1):_vm._e(),(!_vm.lead.created_by.avatar)?_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"secondary"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.lead.created_by.initials))])]):_vm._e()],1),(_vm.lead.created_by)?_c('div',[(_vm.lead.user_reference)?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-bullhorn-outline")]):_vm._e(),_vm._v(_vm._s(_vm.lead.created_by.first_name)+" "+_vm._s(_vm.lead.created_by.last_name)+" ")],1):_vm._e()],1)]}}],null,false,891357372)},[(!_vm.lead.hidden_creator)?_c('ProfileCard',{attrs:{"profile":_vm.lead.created_by}}):_vm._e()],1)],1):_vm._e(),(_vm.lead.hidden_creator)?_c('v-card-actions',{staticClass:"pl-4 pb-0"},[_c('v-menu',{attrs:{"close-on-content-click":false,"open-on-hover":"","rounded":"","top":"","offset-y":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","value":_vm.lead.created_by.is_premium,"offset-x":"25","offset-y":"15","icon":"mdi-license","color":"warning"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"grey"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.lead.created_by.initials))])])],1),(_vm.lead.created_by)?_c('div',[_vm._v("Dold profil")]):_vm._e()],1)]}}],null,false,2699033680)},[(_vm.lead.hidden_creator)?_c('HiddenProfileCard',{attrs:{"profile":_vm.lead.created_by}}):_vm._e()],1)],1):_vm._e(),_c('v-spacer'),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"mt-2 d-flex flex-wrap justify-end"},[(_vm.showLikeButton(_vm.lead))?_c('LikeLead',{attrs:{"item":_vm.lead}}):_vm._e(),_c('ShareLead',{attrs:{"item":_vm.lead}}),(_vm.showEditButton(_vm.lead))?_c('edit-dialog',{attrs:{"item":_vm.lead}}):_vm._e(),(_vm.showDeleteButton(_vm.lead))?_c('delete-dialog',{attrs:{"item":_vm.lead}}):_vm._e(),(_vm.showArchiveButton(_vm.lead))?_c('ArchiveLead',{attrs:{"item":_vm.lead}}):_vm._e(),(_vm.showHandleLeadButtons(_vm.lead))?_c('HandleLead',{attrs:{"item":_vm.extractLeadOffering(_vm.lead)}}):_vm._e(),(_vm.showLikeButton(_vm.lead))?_c('BuyLead',{attrs:{"item":_vm.lead},on:{"completedPurchase":_vm.filterOutCard}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }