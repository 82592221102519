<template>
  <v-card v-blur="false" id="sidebar-card" class=" mb-4">
    <v-img height="250" :src="imageUrl"></v-img>
    <v-card-title> {{ title }}</v-card-title>
    <v-card-text>
      <div>
        {{ text }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text>
        Read More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["sticky", "text", "title", "imageUrl"],
  methods: {
    stickySidebar() {
      if (this.sticky) {
        var element = document.getElementById("sidebar-card");
        element.classList.add("sticky");
      }
    },
  },
  mounted() {
    this.stickySidebar();
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 70px;
}
</style>
