<template>
  <v-app class="background-img">
    <div class="d-flex justify-center align-center">
      <v-card max-width="600px">
        <v-tabs
          v-model="tab"
          show-arrows
          background-color="primary"
          icons-and-text
          dark
          grow
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="i in tabs" :key="i.id">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1" v-text="$t(i.name)"></div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="bankidLoginForm" v-model="valid" lazy-validation>
                  <v-row class="">
                    <v-col cols="12">
                      <!-- <v-card dark color="error" class="pa-4" v-if="getError">
                        <v-layout align-center pr-4>
                          <v-icon class="pr-3" dark large
                            >mdi-alert-outline</v-icon
                          >
                          <v-layout column>
                            <div>{{ getError }}</div>
                          </v-layout>
                        </v-layout>
                      </v-card> -->
                      <v-card dark color="info" class="pa-4" v-if="getInfo">
                        <v-layout align-center pr-4>
                          <v-icon class="pr-3" dark large
                            >mdi-information-outline</v-icon
                          >

                          <v-layout column>
                            <div>
                              <strong>{{ getInfo.text }}</strong>
                            </div>

                            <div>{{ getInfo.message }}</div>
                            <div>
                              <a class="white-link-text" :href="getInfo.link">{{
                                getInfo.linkText
                              }}</a>
                            </div>
                          </v-layout>
                        </v-layout>
                      </v-card>
                    </v-col>

                    <v-col cols="12 ">
                      <v-text-field
                        v-model="loginPersonalNumber"
                        :rules="loginPersonalNumberRules"
                        :label="$t('personalNumber')"
                        autocomplete="on"
                        placeholder="ÅÅÅÅMMDDNNNN"
                        hint="Ange personnummer med 12 siffror. Ex. 190102030123"
                        persistent-hint
                        :append-icon="
                          show_personalnumber ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="show_personalnumber ? 'text' : 'password'"
                        @click:append="
                          show_personalnumber = !show_personalnumber
                        "
                        id="personalNumberInput"
                        required
                        outlined
                      ></v-text-field>
                      <!-- <span>
                        {{ $t("noAccountYet") }}
                        <a href="/signup"> {{ $t("signUpNow") }} </a>
                      </span> -->
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col
                      class="d-flex flex-row-reverse"
                      cols="12"
                      sm="12"
                      xsm="12"
                      justify-end
                    >
                      <v-btn
                        block
                        :loading="loading"
                        color="success"
                        @click="handleBankIDLogin"
                        type="submit"
                      >
                        <v-img
                          class="mr-2"
                          max-height="20px"
                          max-width="20px"
                          src="@/assets/logo-bank-id.svg"
                        ></v-img>
                        {{ $t("loginWithBankID") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <div class="mt-4">
                  <v-btn block outlined class="mt-4" :to="'/signup'">
                    Skapa konto
                  </v-btn>
                </div>
                <div class="mt-8">
                  <v-divider></v-divider>

                  <div class="mt-4">
                    <div class="mt-4">
                      <TermsDialog
                        :linkOnly="true"
                        @clicked="false"
                        :disabled="false"
                      />
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" v-model="valid" lazy-validation>
                  <v-col cols="12" v-if="!this.invitedByFirstName"> </v-col>
                  <v-row class="">
                    <v-col cols="12">
                      <!-- <v-card dark color="error" class="pa-4" v-if="getError">
                        <v-layout align-center pr-4>
                          <v-icon class="pr-3" dark large
                            >mdi-alert-outline</v-icon
                          >
                          <v-layout column>
                            <div>{{ getError }}</div>
                          </v-layout>
                        </v-layout>
                      </v-card> -->
                      <v-card dark color="info" class="pa-4" v-if="getInfo">
                        <v-layout align-center pr-4>
                          <v-icon class="pr-3" dark large
                            >mdi-information-outline</v-icon
                          >

                          <v-layout column>
                            <div>
                              <strong>{{ getInfo.text }}</strong>
                            </div>

                            <div>{{ getInfo.message }}</div>
                          </v-layout>
                        </v-layout>
                      </v-card>
                    </v-col>
                    <v-col cols="12 ">
                      <v-text-field
                        v-model="user.email"
                        :label="$t('email')"
                        required
                        outlined
                        autocomplete="on"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="user.password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('password')"
                        outlined
                        @click:append="show_password = !show_password"
                      ></v-text-field>

                      <div class="">
                        <v-switch
                          :label="$t('saveLoginEmail')"
                          v-model="saveLoginEmail"
                        ></v-switch
                        ><PasswordResetDialog />
                      </div>

                      <div>
                        <!-- <span>
                          {{ $t("noAccountYet") }}
                          <a href="/signup"> {{ $t("signUpNow") }} </a>
                        </span> -->
                      </div>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col
                      class="d-flex flex-row-reverse"
                      cols="12"
                      sm="12"
                      xsm="12"
                      justify-end
                    >
                      <v-btn
                        block
                        :loading="loading"
                        color="success"
                        @click="handleLogin"
                        type="submit"
                      >
                        {{ $t("login") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <div class="mt-4">
                  <v-btn block outlined class="mt-4" :to="'/signup'">
                    Skapa konto
                  </v-btn>
                </div>
                <div class="mt-8">
                  <v-divider></v-divider>

                  <div class="mt-4">
                    <TermsDialog
                      :linkOnly="true"
                      @clicked="false"
                      :disabled="false"
                    />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import User from "../models/user";
import PasswordResetDialog from "@/components/PasswordResetDialog";
import TermsDialog from "@/components/registration/TermsDialog";
import { mapGetters, mapActions, mapState } from "vuex";
import axiosInstance from "../services/axiosAPI";
import axios from "axios";
const REDIS_URL = process.env.VUE_APP_REDIS_URL;
const API_URL = process.env.VUE_APP_API_URL;
const BASE_URL = process.env.VUE_APP_BASE_URL;
export default {
  name: "Login",
  components: { PasswordResetDialog, TermsDialog },

  computed: {
    ...mapGetters(["isLoading", "getError", "getInfo"]),
    ...mapState({
      isLoggedIn: (state) => state.auth.status.loggedIn,
    }),
  },

  mounted() {
    if (this.isLoggedIn === true) {
      window.location.href = BASE_URL;
    }
    // this.connectPublicWS();
  },
  methods: {
    ...mapActions(["login", "register", "logout", "loginBankID"]),

    // connectPublicWS() {
    //   const token = localStorage.getItem("access_token");
    //   const endpoint = `${REDIS_URL}ws/public_notifications_routing/`;

    //   const socket = new WebSocket(endpoint + "?token=" + token);

    //   console.log("ws: ", socket);

    //   // Set the badge of app icon

    //   const that = this;
    //   socket.onmessage = function (e) {
    //     console.log("ws onmessage", e.data);

    //     that.fetchNotifications();
    //     // that.addDotFavicon();
    //     that.reloadBalance();
    //     that.responseWebSocketNotification({
    //       text: "Avisering",
    //       message: "Du har en ny avisering",
    //     });

    //     that.setBadge(that.getNotifications.length);
    //   };

    //   // Socket Connect Functionality
    //   socket.onopen = function (e) {
    //     console.log("ws open", e);
    //   };

    //   // Socket Error Functionality
    //   socket.onerror = function (e) {
    //     console.log("ws error", e);
    //   };

    //   // Socket close Functionality
    //   socket.onclose = function (e) {
    //     console.log(
    //       "ws is closed. Reconnect will be attempted in 10 seconds.",
    //       e.reason
    //     );

    //     setTimeout(function () {
    //       this.connectPublicWS();
    //     }, 10000);
    //   };
    // },

    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    // TODO - Fix saving email
    handleLocalStorage() {
      if (this.saveLoginEmail) {
        localStorage.setItem("saveLoginEmail", true);
        localStorage.setItem("login_email", this.user.email);
      } else {
        localStorage.setItem("saveLoginEmail", false);
        localStorage.setItem("login_email", "");
      }
      localStorage.setItem("last_login_selection", this.tabs[this.tab]?.name);
    },

    handleLogin(e) {
      e.preventDefault();
      if (this.user.email && this.user.password) {
        this.loading = true;
        this.login({
          email: this.user.email.toLowerCase(),
          password: this.user.password,
        });
        this.loading = false;
        this.handleLocalStorage();
      }
    },

    handleBankIDLogin(e) {
      e.preventDefault();
      if (
        this.loginPersonalNumber.length === 12 ||
        this.loginPersonalNumber.length === 13
      ) {
        this.loading = true;
        this.loginBankID(this.loginPersonalNumber.replace("-", ""));
      }
      this.loading = false;
      this.handleLocalStorage();
    },
  },
  data: () => ({
    invitedByFirstName: "",
    user: new User(localStorage.getItem("login_email") || "", ""),
    acceptTerms: false,
    loading: false,
    message: "",
    dialog: true,
    tab:
      localStorage.getItem("last_login_selection") === "loginWithEmail" ? 1 : 0,
    tabs: [
      { id: 1, name: "BankID", icon: "mdi-cellphone-key" },
      { id: 2, name: "loginWithEmail", icon: "mdi-at" },
    ],
    valid: true,

    loginPassword: "",
    loginEmail: "",
    loginPersonalNumber: "",
    saveLoginEmail:
      localStorage.getItem("saveLoginEmail") == "true" ? true : false,
    lastLoginSelection: localStorage.getItem("last_login_selection"),
    loginEmailRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /.+@.+\..+/.test(v) || "Detta verkar inte vara en email",
    ],
    loginPersonalNumberRules: [
      (v) =>
        /^19[0-9]{6}-?[0-9]{4}$|20[0-9]{6}-?[0-9]{4}$/.test(v) ||
        "Personnumret måste vara i formatet ÅÅÅÅMMDD-NNNN (12 siffror) för att vara giltigt",
    ],
    emailRules: [
      (v) => !!v || "Obligatorisk",
      (v) => /.+@.+\..+/.test(v) || "Email måste vara giltig",
    ],

    show_password: false,
    show_personalnumber: true,
    rules: {
      required: (value) => !!value || "Obligatorisk",
      min: (v) => (v && v.length >= 8) || "Min 8 characters",
      password: (v) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/.test(
          v
        ) ||
        "Lösenordet måste innehålla minst en liten bokstav, en stor bokstav, en siffra och ett specialtecken",
    },
    phoneRules: [
      (v) => !!v || "Obligatorisk",
      (v) =>
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(v) ||
        "Telefonnumret måste vara korrekt",
    ],
  }),
};
</script>

<style scoped>
.background-img {
  background-image: url("~@/assets/teamwork_bg.jpg") !important;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background-size: cover;
}
</style>
<style scoped>
.white-link-text {
  color: lightgray;
  font-weight: bold;
}
</style>
