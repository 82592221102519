<template>
  <div>
    <div class="">
      <AdRow />
    </div>
    <div class="mb-2">
      <FilterRow @refreshMarketFetch="refreshMarketFetch" />
    </div>
    <div>
      <LargeLoader v-if="isLoading && leadsInfiniteArray.length === 0" />
    </div>
    <div
      v-masonry
      item-selector=".item"
      fit-width="true"
      class="masonry-container"
    >
      <div
        v-masonry-tile
        class="item"
        v-for="(item, index) in leadsInfiniteArray"
        :key="index"
      >
        <LeadCard :lead="item" />
        <InfinityLoader v-if="nextIsLoading" />
      </div>
    </div>
    <div v-if="!next" class="d-flex flex-column justify-center">
      <p class="ma-4 text-center" v-if="!next">
        <v-icon x-large>mdi-filter-outline</v-icon><br />
        <span v-if="noFilterApplied"
          >Det finns inga fler tillgänliga leadkort just nu.
        </span>
        <span v-if="!noFilterApplied"
          >Inga fler leadkort matchar din filtrering</span
        ><v-alert class="mt-4" text dense color="info">
          Tänk på att det är <strong>först till kvarn</strong> som
          gäller.</v-alert
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosInstance from "../../services/axiosAPI";
const API_URL = process.env.VUE_APP_API_URL;
import MenuBar from "@/components/navigation/MenuBar";
import LeadCard from "@/components/leadcard/LeadCard";
import AddLead from "@/components/leadcard/AddLead";
import FilterBox from "@/components/FilterBox";
import FilterRow from "@/components/FilterRow";
import AdRow from "./AdRow";

import InfinityLoader from "@/components/InfinityLoader";
import LargeLoader from "@/components/LargeLoader";

export default {
  name: "Market",
  components: {
    MenuBar,
    LeadCard,
    InfinityLoader,
    AddLead,
    FilterBox,
    FilterRow,
    LargeLoader,
    AdRow,
  },
  methods: {
    ...mapActions([
      "fetchTags",
      "globalFilter",
      "clearFilter",
      "removeTag",
      "fetchMyLikes",
      "getFilterSetting",
    ]),
    handleScroll() {
      // let scrollHeight = window.scrollY;
      // let maxHeight =
      //   window.document.body.scrollHeight -
      //   window.document.documentElement.clientHeight;

      // if (scrollHeight >= maxHeight && this.hasNext) {
      //   this.currentPage += 1;
      //   this.getLeads();
      // }

      // TODO - Find better solution for infinite scroll

      // let zoom = (window.outerHeight - 10) / window.innerHeight;

      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      // let bottomOfWindow =
      //   (window.innerHeight + window.scrollY)*(zoom>1?zoom:1+(1-zoom)) >= document.body.scrollHeight;
      // let bottomOfWindow =
      //   window.scrollY + window.innerHeight + 10 >=
      //   document.documentElement.scrollHeight;

      // console.log(
      //   document.documentElement.scrollTop,
      //   window.innerHeight,
      //   document.documentElement.scrollTop + window.innerHeight,
      //   document.documentElement.offsetHeight,
      //   bottomOfWindow,
      //   zoom
      // );
      if (bottomOfWindow && this.next !== null) {
        this.currentPage += 1;
        this.getLeads();
      }
      if (bottomOfWindow && this.next === null) {
        console.log("Market: No more leads to load");
      }
    },

    refreshMarketFetch() {
      this.leadsInfiniteArray = [];
      this.currentPage = 1;

      this.next = null;
      this.getLeads();
    },

    // TODO Live fetch when entering text in input field filter
    // textFilterFetch() {
    //   this.leadsInfiniteArray = [];
    //   this.currentPage = 1;
    //   this.hasNext = true;
    //   let filterObj = {
    //     searchOnText: this.getFilter.searchOnText,
    //     filterTags: this.getFilter.filterTags,
    //   };
    //   this.globalFilter(filterObj);

    //   this.getLeads();
    // },
    getLeads() {
      this.nextIsLoading = true;

      let tagQuery = "";
      if (this.getFilter.filterTags) {
        this.getFilter.filterTags.map((tag) => {
          tagQuery += `&tags=${tag}`;
        });
      }
      let sniQuery = "";
      if (this.getFilter.filterSnis) {
        this.getFilter.filterSnis.map((sni) => {
          sniQuery += `&snis=${sni}`;
        });
      }
      let dateRangeQuery = "";
      if (this.getFilter.dateRange) {
        const sortedRange = this.getFilter.dateRange.sort();
        sortedRange.map((date) => {
          dateRangeQuery += `&dates=${date}`;
        });
      }

      const params = new URLSearchParams({
        text:
          this.getFilter.searchOnText === null
            ? ""
            : this.getFilter.searchOnText,
        price_min:
          this.getFilter.priceMin === null ? "" : this.getFilter.priceMin,
        price_max:
          this.getFilter.priceMax === null ? "" : this.getFilter.priceMax,
        rating_min:
          this.getFilter.ratingMin === null ? "" : this.getFilter.ratingMin,
        rating_max:
          this.getFilter.ratingMax === null ? "" : this.getFilter.ratingMax,
      });

      let urlAPI = "";
      if (this.currentPage === 1) {
        urlAPI = `${API_URL}api/leads/?page=${
          this.currentPage
        }&${params.toString()}${tagQuery}${sniQuery}${dateRangeQuery}`;
      } else {
        // console.log("RESP URL");
        urlAPI = this.next;
      }

      axiosInstance
        .get(urlAPI)
        .then((response) => {
          const data = response.data;
          this.next = data.next;

          data.results.map((result) => this.leadsInfiniteArray.push(result));
          this.nextIsLoading = false;

          return response;
        })

        .catch((error) => {
          // console.log(error);

          this.next = null;

          this.nextIsLoading = false;
        });
    },
    remove(item) {
      this.filterTags.splice(this.filterTags.indexOf(item), 1);
      this.filterTags = [...this.filterTags];
    },
    clickedOnTag(tag) {
      this.getFilter.filterTags = tag;
    },
    openDetail(item) {
      this.$router.push("/leadcard/" + item.slug).catch(() => {});
    },
    saveFilter() {
      let filterObj = {
        searchOnText: this.getFilter.searchOnText,
        filterTags: this.getFilter.filterTags,
      };
      this.globalFilter(filterObj);
      localStorage.setItem("filterSettings", JSON.stringify(filterObj));
      this.refreshFetch();
    },
    clearFilters(event) {
      event.preventDefault();
      this.clearFilter();
      // TODO Add daterange filter
      // this.dates = [];
      localStorage.removeItem("filterSettings");
      this.leadsInfiniteArray = [];
      this.currentPage = 1;

      this.next = null;
      this.getLeads();
    },
  },
  computed: {
    ...mapGetters(["isLoading", "allTags", "getUser", "getFilter"]),
    dateRangeText() {
      return this.dates.join(" -> ");
    },

    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
    filterTags() {
      return this.getFilter.filterTags;
    },

    availableTags() {
      let tagsList = [];
      this.allTags.forEach((element) => {
        tagsList.push(element.name);
      });
      return tagsList;
    },

    noFilterApplied() {
      if (
        this.getFilter &&
        this.getFilter.searchOnText === "" &&
        this.getFilter.filterTags.length === 0 &&
        this.getFilter.filterSnis.length === 0
      ) {
        return true;
      }
      return false;
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.fetchTags();
    this.fetchMyLikes();
  },
  mounted() {
    this.getLeads();
  },
  destroyed() {
    this.leadsInfiniteArray = [];
    this.currentPage = 1;

    this.next = null;
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {},
  data: () => ({
    leadsInfiniteArray: [],
    currentPage: 1,

    nextIsLoading: false,
    next: null,
    moreItems: [],
    imgPrefix: process.env.VUE_APP_IMG_PREFIX,

    dates: [],
    date: new Date().toISOString().substr(0, 10),

    datePickerModal: false,

    useSavedFilter: true,
    attrs: {
      boilerplate: true,
    },
    routes: [
      {
        route: "/",
        icon: "mdi-view-list-outline",
      },
    ],
  }),
};
</script>

<style scoped>
@media screen and (max-device-width: 360px) {
  .item {
    width: 320px;
    margin: 4px;
  }
}
@media screen and (min-device-width: 361px) {
  .item {
    width: 340px;
    margin: 4px;
  }
}

.masonry-container {
  width: 100%;
  margin: 0 auto;
}
</style>
