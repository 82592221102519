<template>
  <v-app>
    <Snackbar />

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- TODO Fix this -->
    <IOSPopup />
    <NewVersionRefresh />
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Snackbar from "@/components/Snackbar";
import IOSPopup from "@/components/IOSPopup";
import NewVersionRefresh from "@/components/NewVersionRefresh";

export default {
  name: "App",
  components: {
    Snackbar,
    IOSPopup,
    NewVersionRefresh,
  },
  methods: {
    ...mapActions(["fetchUser"]),
  },
  computed: {},
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || "Zwop";
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchUser();
  },
};
</script>

<style>
/* @import "./assets/styles/vue-tour.css"; */
div > .v-application--wrap {
  /* min-height: 100%; */
}
html {
  /* background-color: #f2f2f2; */
  /* min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left); */
}
</style>
