<template>
  <v-card class="pa-4">
    <v-list class="text-center pa-0">
      <v-list-item class="d-flex justify-center">
        <v-badge
          overlap
          :value="profile.subscription"
          offset-x="25"
          offset-y="25"
          icon="mdi-license"
          color="warning"
        >
          <v-avatar color="grey" size="102">
            <span class="text-h2">{{ profile.initials }}</span>
          </v-avatar>
        </v-badge>
      </v-list-item>
      <v-list-item-title
        class="title d-flex justify-center ma-2"
        v-if="profile"
      >
        Dold profil
      </v-list-item-title>

      <v-card-actions class="d-flex justify-center">
        <ReviewsDialog :profile="profile" />
      </v-card-actions>

      <v-card class="pa-2" outlined>
        <span class="text-caption">Dold bio</span>
      </v-card>
      <ProfileStatistics :profile="profile" />
      <v-spacer></v-spacer>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const API_URL = process.env.VUE_APP_API_URL;
import ReviewsDialog from "./ReviewsDialog";
import ProfileStatistics from "@/components/profile/ProfileStatistics";
export default {
  name: "ProfileCard",
  props: ["profile"],
  components: {
    ReviewsDialog,

    ProfileStatistics,
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  mounted() {},
  beforeDestroy() {},
  data() {
    return {};
  },
  watch: {},
};
</script>

<style></style>
