<template>
  <div>
    <v-btn class="ma-1" @click.stop="handleRequest(item, 'accept')">
      <v-icon color="success">mdi-check</v-icon>
    </v-btn>

    <v-btn class="ma-1" @click.stop="handleRequest(item, 'reject')">
      <v-icon color="error">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HandleProfile",
  props: ["item"],
  components: {},
  methods: {
    ...mapActions(["handleFollowingRequest", "fetchFollowerRequests"]),
    handleRequest(item, action) {
      var payload = {
        id: item.id,
        action: action,
      };
      this.handleFollowingRequest(payload);
    },
  },
  computed: {
    ...mapGetters([]),
  },

  created() {},
  mounted() {},

  data: () => ({}),
};
</script>

<style></style>
