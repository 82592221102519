<template>
  <v-card outlined :color="bgColor">
    <v-card-title>{{ title }}</v-card-title>

    <v-card-text>
      <draggable v-model="draggables" group="default">
        <div v-for="item in items" :key="item.id">
          <item :item="item"></item>
        </div>
      </draggable>
    </v-card-text>
    <v-card-actions>
      {{ itemCount }}
    </v-card-actions>
  </v-card>
</template>

<script>
import Draggable from "vuedraggable";
import TaskLaneItem from "./TaskLaneItem";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TaskLane",
  props: ["items", "title", "id", "bgColor"],
  components: {
    item: TaskLaneItem,
    draggable: Draggable,
  },
  methods: {
    ...mapActions(["leadUpdateStatus"]),
  },
  computed: {
    itemCount() {
      if (!this.items) return "";
      if (this.items.length === 1) return "1 lead";
      return `${this.items.length} leads`;
    },
    draggables: {
      get() {
        return this.items;
      },
      set(items) {
        var payload = {
          items,
          id: this.id,
        };
        console.log(payload);
        this.leadUpdateStatus(payload);
        // this.$store.commit('updateItems', {
        //   items,
        //   id: this.id
        // });
      },
    },
  },
};
</script>
