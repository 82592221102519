<template>
  <div class="d-flex flex-wrap justify-space-around">
    <p class="ma-1">
      <a href="#">{{ $t("info") }}</a>
    </p>
    <p class="ma-1">
      <a href="#">{{ $t("helpcenter") }}</a>
    </p>
    <p class="ma-1">
      <a href="#">{{ $t("privacyTerms") }}</a>
    </p>

    <p class="ma-1">Zwop Technology AB © {{ year }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
